import * as yup from 'yup'
import Lazy from 'yup/lib/Lazy'
import { isEmpty } from 'lodash'
type Schema = Lazy<any, unknown> | any

/**
 * A set of common validations.
 * Don't add to this class unless a given validation is needed more than once.
 * For one-time validations use the "custom" method of ValidationSchemaBuilder
 */
export const registeredBusinessStructure = {
  noBusinessOptionId: '926845ea-b6c7-4bc0-b32d-2c85a0e8e36j',
  tellMeMoreOptionId: 'c7986148-1f62-465d-967b-636fd6053subQ31',
}

export const isValid = (registerBusinessStructureOptionId, value) => {
  if (
    !isEmpty(registerBusinessStructureOptionId) &&
    registerBusinessStructureOptionId !== registeredBusinessStructure.noBusinessOptionId &&
    registerBusinessStructureOptionId !== registeredBusinessStructure.tellMeMoreOptionId
  ) {
    return !value ? false : true
  } else {
    return true
  }
}

export abstract class CommonValidations {
  abstract firstName()
  abstract lastName()
  abstract email()
  abstract mobileContactNumber()
  abstract ein()
  abstract yearsInBusiness()
  abstract businessName()
}

/** Responsible for defining validation rules with the "yup" library */
export class ValidationsProvider {
  firstName() {
    return yup.string().trim().required('Please enter first name')
  }

  lastName() {
    return yup.string().trim().required('Please enter last name')
  }

  email() {
    return yup
      .string()
      .email('Please enter a valid email address')
      .required('Please enter a valid email address')
  }
  isSmsOptedIn() {
    return yup.boolean().oneOf([true], 'Agreement is required to subscribe.')
  }
  password() {
    return yup.string().required('Please enter a password')
  }

  businessEmail() {
    return yup
      .string()
      .email('Please enter a valid Business Email')
      .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .nullable()
  }
  domainName() {
    return yup
      .string()
      .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .matches(
        /^(https?:\/\/)?([\w\d-]+\.)+[\w-]+(\/[\w- ;,./?%&=]*)?$/i,
        'Please enter a valid Business Website'
      )
      .nullable()
  }
  month() {
    return yup
      .string()
      .test('checkYearMonthError', 'Enter month ', function (value) {
        const { registerBusinessStructureOptionId } = this.parent

        return isValid(registerBusinessStructureOptionId, value)
      })
      .nullable()
  }
  year() {
    return yup
      .string()
      .test('checkYearMonthError', 'Enter year ', function (value) {
        const { registerBusinessStructureOptionId } = this.parent
        return isValid(registerBusinessStructureOptionId, value)
      })
      .nullable()
  }
  mobileContactNumber() {
    return yup
      .string()
      .required('Phone number is required')
      .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .test('contactNumberLength', 'All 10 numbers required', (value) => {
        if (!value || value.length > 10) {
          return true
        }
        return false
      })
  }

  ein() {
    return yup
      .string()
      .matches(/^[0-9]{2}-[0-9]{7}$/i, 'Please enter a valid EIN number')
      .required('Please enter a valid ein number')
  }
  naicsCode() {
    return yup
      .string()
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Please enter a valid NAICS number')
      .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .nullable()
  }

  yearsInBusiness() {
    return yup
      .string()
      .matches(/^(?!00)[0-9]{1,4}$/i, 'Year must be between 0 to 1000')
      .required('You must enter year')
  }

  einCode() {
    return yup
      .string()
      .matches(/^[0-9]{2}-[0-9]{7}$/i, 'Please enter a valid EIN number')
      .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .nullable()
  }

  businessName() {
    return yup.string().nullable()
  }
  businessStage() {
    return yup.string().trim().required('Select business stage')
  }
  registeredBusinessStructure() {
    return yup.string().trim().required('Select registered business')
  }
  clientDetailsPartTimeEmployees() {
    return yup
      .string()
      .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
      .nullable()
  }
  clientDetailsFullTimeEmployees() {
    return yup
      .string()
      .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
      .nullable()
  }

  totalEmployees() {
    return yup
      .string()
      .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
      .nullable()
  }

  clientDetailsContractors() {
    return yup
      .string()
      .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
      .nullable()
  }
  clientDetailsVolunteers() {
    return yup
      .string()
      .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
      .nullable()
  }
  fullTimeEmployees() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'must be a number')
  }
  partTimeEmployees() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'must be a number')
  }
  contractors() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'must be a number')
  }
  volunteers() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'must be a number')
  }
  jobsCreated() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
  }
  revenue() {
    return yup
      .string()
      .required('Please enter 0 or dollars and cents (eg. $15.50)')
      .matches(
        /^([-]?(?!00)[0-9*$,+]*(\.[0-9*$,+]+)?)$/i,
        'Please enter 0 or dollars and cents (eg. $15.50)'
      )
  }
  wages() {
    return yup
      .string()
      .required('Please enter 0 or dollars and cents (eg. $15.50)')
      .matches(
        /^(?!00)[0-9*$,+]*(\.[0-9*$,+]+)?$/i,
        'Please enter 0 or dollars and cents (eg. $15.50)'
      )
  }
  debtFinancing() {
    return yup
      .string()
      .required('Please enter 0 or dollars and cents (eg. $15.50)')
      .matches(
        /^(?!00)[0-9*$,+]*(\.[0-9*$,+]+)?$/i,
        'Please enter 0 or dollars and cents (eg. $15.50)'
      )
  }
  businessBankAccount() {
    return yup
      .string()
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
      .required('Must be a number')
  }
  newCustomer() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
  }
  productSale() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
  }
  serviceSale() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
  }
  targetAudience() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
  }
  physicalLocation() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
  }
  financialGrowth() {
    return yup.string().required('Choose profit or loss for last month').nullable()
  }
  businessAddress() {
    return yup.string().trim().required('Business address is required')
  }
  url() {
    return yup.string().url('Please enter a valid URL').required('URL is required')
  }
}

/** Builder class for creating yup schemas */
class ValidationSchemaBuilder extends CommonValidations {
  provider = new ValidationsProvider()
  validations: { [key: string]: any } = {}
  _schema: Schema | null = null

  updateSchema() {
    this._schema = yup.object(this.validations) as Schema
  }

  /** Returns the finished schema and clears itself to make another schema */
  getSchema() {
    const schema = this._schema
    this.validations = {}
    this._schema = null
    return schema as Schema
  }

  /** Add custom, one-off properties to the schema */
  custom(obj: { [key: string]: any }) {
    Object.assign(this.validations, obj)
    this.updateSchema()
    return this
  }

  firstName() {
    this.validations.firstName = this.provider.firstName()
    this.updateSchema()
    return this
  }

  lastName() {
    this.validations.lastName = this.provider.lastName()
    this.updateSchema()
    return this
  }

  password() {
    this.validations.password = this.provider.password()
    this.updateSchema()
    return this
  }

  email() {
    this.validations.email = this.provider.email()
    this.updateSchema()
    return this
  }
  mobileContactNumber() {
    this.validations.mobileContactNumber = this.provider.mobileContactNumber()
    this.updateSchema()
    return this
  }
  isSmsOptedIn() {
    this.validations.isSmsOptedIn = this.provider.isSmsOptedIn()
    this.updateSchema()
    return this
  }

  ein() {
    this.validations.EIN = this.provider.einCode()
    this.updateSchema()
    return this
  }
  naicsCode() {
    this.validations.naicsCode = this.provider.naicsCode()
    this.updateSchema()
    return this
  }

  yearsInBusiness() {
    this.validations.yearsInBusiness = this.provider.yearsInBusiness()
    this.updateSchema()
    return this
  }

  businessName() {
    this.validations.businessName = this.provider.businessName()
    this.updateSchema()
    return this
  }
  businessStage() {
    this.validations.businessStageOptionId = this.provider.businessStage()
    this.updateSchema()
    return this
  }
  registeredBusinessStructure() {
    this.validations.registerBusinessStructureOptionId = this.provider.registeredBusinessStructure()
    this.updateSchema()
    return this
  }

  fullTimeEmployees() {
    this.validations.fullTimeEmployees = this.provider.fullTimeEmployees()
    this.updateSchema()
    return this
  }
  partTimeEmployees() {
    this.validations.partTimeEmployees = this.provider.partTimeEmployees()
    this.updateSchema()
    return this
  }
  contractors() {
    this.validations.contractors = this.provider.contractors()
    this.updateSchema()
    return this
  }
  volunteers() {
    this.validations.volunteers = this.provider.volunteers()
    this.updateSchema()
    return this
  }

  businessEmail() {
    this.validations.businessEmail = this.provider.businessEmail()
    this.updateSchema()
    return this
  }
  businessWebsite() {
    this.validations.domainName = this.provider.domainName()
    this.updateSchema()
    return this
  }

  clientDetailsPartTimeEmployees() {
    this.validations.partTimeEmployees = this.provider.clientDetailsPartTimeEmployees()
    this.updateSchema()
    return this
  }
  clientDetailsFullTimeEmployees() {
    this.validations.fullTimeEmployees = this.provider.clientDetailsFullTimeEmployees()
    this.updateSchema()
    return this
  }
  totalEmployees() {
    this.validations.totalEmployees = this.provider.totalEmployees()
    this.updateSchema()
    return this
  }
  clientDetailsContractors() {
    this.validations.contractors = this.provider.clientDetailsContractors()
    this.updateSchema()
    return this
  }
  clientDetailsVolunteers() {
    this.validations.volunteers = this.provider.clientDetailsVolunteers()
    this.updateSchema()
    return this
  }

  month() {
    this.validations.month = this.provider.month()
    this.updateSchema()
    return this
  }
  year() {
    this.validations.year = this.provider.year()
    this.updateSchema()
    return this
  }
  jobsCreated() {
    this.validations.jobsCreated = this.provider.jobsCreated()
    this.updateSchema()
    return this
  }
  revenue() {
    this.validations.revenue = this.provider.revenue()
    this.updateSchema()
    return this
  }
  wages() {
    this.validations.wages = this.provider.wages()
    this.updateSchema()
    return this
  }
  debtFinancing() {
    this.validations.debtFinancing = this.provider.debtFinancing()
    this.updateSchema()
    return this
  }
  businessBankAccount() {
    this.validations.businessBankAccount = this.provider.businessBankAccount()
    this.updateSchema()
    return this
  }
  newCustomer() {
    this.validations.newCustomer = this.provider.newCustomer()
    this.updateSchema()
    return this
  }
  productSale() {
    this.validations.productSale = this.provider.productSale()
    this.updateSchema()
    return this
  }
  serviceSale() {
    this.validations.serviceSale = this.provider.serviceSale()
    this.updateSchema()
    return this
  }
  targetAudience() {
    this.validations.targetAudience = this.provider.targetAudience()
    this.updateSchema()
    return this
  }
  physicalLocation() {
    this.validations.physicalLocation = this.provider.physicalLocation()
    this.updateSchema()
    return this
  }
  financialGrowth() {
    this.validations.financialGrowth = this.provider.financialGrowth()
    this.updateSchema()
    return this
  }
  businessAddress() {
    this.validations.businessAddress = this.provider.businessAddress()
    this.updateSchema()
    return this
  }

  url() {
    this.validations.url = this.provider.url()
    this.updateSchema()
    return this
  }
}

export default ValidationSchemaBuilder
