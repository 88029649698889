import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from 'react-query'
import { fetcher } from '../lib/api-client'
export type Maybe<T> = T | null | undefined
export type InputMaybe<T> = T | null | undefined
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  AWSDate: string
  AWSDateTime: string
  AWSEmail: string
  AWSIPAddress: string
  AWSJSON: string
  AWSPhone: string
  AWSTime: string
  AWSTimestamp: number
  AWSURL: string
}

export type AccountingChartResponse = {
  __typename?: 'AccountingChartResponse'
  data: Array<AccountingReportOutput>
  status: ChartStatus
}

export enum AccountingCharts {
  BalanceSheet = 'balanceSheet',
  CashBalance = 'cashBalance',
  CashBalanceByMonth = 'cashBalanceByMonth',
  CashFlow = 'cashFlow',
  LiquidCash = 'liquidCash',
  Payments = 'payments',
  ProfitAndLoss = 'profitAndLoss',
}

export type AccountingReport = {
  dateRange?: Maybe<DateRanges>
  endDate?: Maybe<Scalars['String']>
  growth?: Maybe<Scalars['Float']>
  source?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type AccountingReportOutput =
  | CashBalanceByMonthReport
  | CashBalanceReport
  | LiquidCashReport
  | ProfitAndLossReport

export type AccountingToolEntry = {
  __typename?: 'AccountingToolEntry'
  amount?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
}

export type ActivityLogInput = {
  accessToken?: InputMaybe<Scalars['String']>
  action: Scalars['String']
  logStatus?: InputMaybe<Scalars['String']>
  showClientActivity?: InputMaybe<Scalars['Boolean']>
  tenantId: Scalars['String']
  type?: InputMaybe<Scalars['String']>
}

export type AddUserNotificationInput = {
  meta?: InputMaybe<Scalars['AWSJSON']>
  notificationType: Scalars['String']
  senderId: Scalars['String']
  status?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  userId: Scalars['String']
}

export type AddUserToGroupInput = {
  remove?: InputMaybe<Scalars['Boolean']>
  role: Scalars['String']
  tenantId: Scalars['String']
  userName: Scalars['String']
}

export type Address = {
  __typename?: 'Address'
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type AddressInput = {
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  street?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export type AdvisorProfile = {
  __typename?: 'AdvisorProfile'
  defaultVideoLinkDescription?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
}

export type Advisors = {
  __typename?: 'Advisors'
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName?: Maybe<Scalars['String']>
  schedulingUrl?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
}

export type ApiResponse = {
  __typename?: 'ApiResponse'
  data?: Maybe<Scalars['AWSJSON']>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type Assessment = {
  __typename?: 'Assessment'
  assessmentType?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['AWSJSON']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  id: Scalars['ID']
  isVisible?: Maybe<Scalars['Boolean']>
  meta?: Maybe<Scalars['AWSJSON']>
  optionType?: Maybe<Scalars['String']>
  options?: Maybe<Scalars['AWSJSON']>
  prerequisites?: Maybe<Scalars['AWSJSON']>
  previous?: Maybe<Scalars['AWSJSON']>
  tenantId?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type AssessmentResponse = {
  __typename?: 'AssessmentResponse'
  assessmentResponse?: Maybe<Array<AssessmentResponseOutput>>
  completedAt?: Maybe<Scalars['AWSDateTime']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  currentStage?: Maybe<Scalars['ID']>
  id?: Maybe<Scalars['ID']>
  playStage?: Maybe<Scalars['AWSJSON']>
  totalAttemptedItems?: Maybe<Scalars['Int']>
  totalItems?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
  userId?: Maybe<Scalars['ID']>
}

export type AssessmentResponseInput = {
  EIN?: InputMaybe<Scalars['Boolean']>
  accountingMethod?: InputMaybe<Scalars['Boolean']>
  cageCode?: InputMaybe<Scalars['Boolean']>
  codeCageAndUniqueId?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['String']>
  industryIcon?: InputMaybe<Scalars['Boolean']>
  isActiveGoal?: InputMaybe<Scalars['Boolean']>
  isBusinessEmail?: InputMaybe<Scalars['Boolean']>
  isCreateAliasesGmail?: InputMaybe<Scalars['Boolean']>
  isCreateBusinessPlan?: InputMaybe<Scalars['Boolean']>
  isCreateLogo?: InputMaybe<Scalars['Boolean']>
  isDevelopBudget?: InputMaybe<Scalars['Boolean']>
  isGoogleDomain?: InputMaybe<Scalars['Boolean']>
  isGoogleProfileImage?: InputMaybe<Scalars['Boolean']>
  isGoogleVoice?: InputMaybe<Scalars['Boolean']>
  isIndustry?: InputMaybe<Scalars['Boolean']>
  isMatrix?: InputMaybe<Scalars['Boolean']>
  isSetupFacebook?: InputMaybe<Scalars['Boolean']>
  isSetupInstagram?: InputMaybe<Scalars['Boolean']>
  isSetupLinkedin?: InputMaybe<Scalars['Boolean']>
  isSubIndustry?: InputMaybe<Scalars['Boolean']>
  isTopGoal?: InputMaybe<Scalars['Boolean']>
  label?: InputMaybe<Scalars['AWSJSON']>
  month?: InputMaybe<Scalars['Boolean']>
  naicsCode?: InputMaybe<Scalars['Boolean']>
  next?: InputMaybe<Scalars['String']>
  nigpCode?: InputMaybe<Scalars['Boolean']>
  optionType?: InputMaybe<Scalars['String']>
  playResponse?: InputMaybe<Scalars['AWSJSON']>
  previous?: InputMaybe<Array<Scalars['String']>>
  productCode?: InputMaybe<Scalars['Boolean']>
  questionLabel?: InputMaybe<Scalars['String']>
  recommendations?: InputMaybe<RecommendationsInput>
  registerBusinessStructure?: InputMaybe<Scalars['Boolean']>
  registrationYear?: InputMaybe<Scalars['Boolean']>
  samRegistration?: InputMaybe<Scalars['Boolean']>
  serviceCode?: InputMaybe<Scalars['Boolean']>
  showInReview?: InputMaybe<Scalars['Boolean']>
  submittedAt?: InputMaybe<Scalars['AWSDateTime']>
  uniqueEntityId?: InputMaybe<Scalars['Boolean']>
  uniqueId?: InputMaybe<Scalars['Boolean']>
  value?: InputMaybe<Scalars['AWSJSON']>
  year?: InputMaybe<Scalars['Boolean']>
}

export type AssessmentResponseOutput = {
  __typename?: 'AssessmentResponseOutput'
  EIN?: Maybe<Scalars['Boolean']>
  accountingMethod?: Maybe<Scalars['Boolean']>
  cageCode?: Maybe<Scalars['Boolean']>
  codeCageAndUniqueId?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['String']>
  industryIcon?: Maybe<Scalars['Boolean']>
  isActiveGoal?: Maybe<Scalars['Boolean']>
  isBusinessEmail?: Maybe<Scalars['Boolean']>
  isCreateAliasesGmail?: Maybe<Scalars['Boolean']>
  isCreateBusinessPlan?: Maybe<Scalars['Boolean']>
  isCreateLogo?: Maybe<Scalars['Boolean']>
  isDevelopBudget?: Maybe<Scalars['Boolean']>
  isGoogleDomain?: Maybe<Scalars['Boolean']>
  isGoogleProfileImage?: Maybe<Scalars['Boolean']>
  isGoogleVoice?: Maybe<Scalars['Boolean']>
  isIndustry?: Maybe<Scalars['Boolean']>
  isMatrix?: Maybe<Scalars['Boolean']>
  isSetupFacebook?: Maybe<Scalars['Boolean']>
  isSetupInstagram?: Maybe<Scalars['Boolean']>
  isSetupLinkedin?: Maybe<Scalars['Boolean']>
  isSubIndustry?: Maybe<Scalars['Boolean']>
  isTopGoal?: Maybe<Scalars['Boolean']>
  label?: Maybe<Scalars['AWSJSON']>
  month?: Maybe<Scalars['Boolean']>
  naicsCode?: Maybe<Scalars['Boolean']>
  next?: Maybe<Scalars['String']>
  nigpCode?: Maybe<Scalars['Boolean']>
  optionType?: Maybe<Scalars['String']>
  playResponse?: Maybe<Scalars['AWSJSON']>
  previous?: Maybe<Array<Scalars['String']>>
  productCode?: Maybe<Scalars['Boolean']>
  questionLabel?: Maybe<Scalars['String']>
  recommendations?: Maybe<Recommendations>
  registerBusinessStructure?: Maybe<Scalars['Boolean']>
  samRegistration?: Maybe<Scalars['Boolean']>
  serviceCode?: Maybe<Scalars['Boolean']>
  showInReview?: Maybe<Scalars['Boolean']>
  submittedAt?: Maybe<Scalars['AWSDateTime']>
  uniqueEntityId?: Maybe<Scalars['Boolean']>
  uniqueId?: Maybe<Scalars['Boolean']>
  value?: Maybe<Scalars['AWSJSON']>
  year?: Maybe<Scalars['Boolean']>
}

export type AttendeesType = {
  __typename?: 'AttendeesType'
  email?: Maybe<Scalars['String']>
  organizer?: Maybe<Scalars['Boolean']>
  responseStatus?: Maybe<Scalars['String']>
  self?: Maybe<Scalars['Boolean']>
}

export type Attestation = {
  __typename?: 'Attestation'
  businessBankAccount?: Maybe<Scalars['String']>
  businessName?: Maybe<Scalars['String']>
  contractors?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  debtFinancing?: Maybe<Scalars['String']>
  documentId?: Maybe<Scalars['String']>
  downloadUrl?: Maybe<Scalars['String']>
  education?: Maybe<Scalars['String']>
  error?: Maybe<Scalars['String']>
  fileName?: Maybe<Scalars['String']>
  financialGrowth?: Maybe<Scalars['String']>
  fullTimeEmployees?: Maybe<Scalars['String']>
  gender?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  jobsCreated?: Maybe<Scalars['String']>
  month?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  newCustomer?: Maybe<Scalars['String']>
  partTimeEmployees?: Maybe<Scalars['String']>
  physicalLocation?: Maybe<Scalars['String']>
  pk?: Maybe<Scalars['String']>
  productSale?: Maybe<Scalars['String']>
  properName?: Maybe<Scalars['String']>
  race?: Maybe<Scalars['String']>
  revenue?: Maybe<Scalars['String']>
  serviceSale?: Maybe<Scalars['String']>
  signedCount?: Maybe<Scalars['String']>
  signedDocumentUrl?: Maybe<Scalars['String']>
  sk?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  targetAudience?: Maybe<Scalars['String']>
  tenantAdvisorStatus?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
  tenantStatus?: Maybe<Scalars['String']>
  unsignedCount?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  username?: Maybe<Scalars['String']>
  volunteers?: Maybe<Scalars['String']>
  wages?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['String']>
}

export type AttestationInput = {
  additionalInfo?: InputMaybe<Scalars['String']>
  businessBankAccount?: InputMaybe<Scalars['String']>
  businessName?: InputMaybe<Scalars['String']>
  contractors?: InputMaybe<Scalars['String']>
  debtFinancing?: InputMaybe<Scalars['String']>
  education?: InputMaybe<Scalars['String']>
  financialGrowth?: InputMaybe<Scalars['String']>
  fullTimeEmployees?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Scalars['String']>
  generateDocuSignUrl?: InputMaybe<Scalars['Boolean']>
  jobsCreated?: InputMaybe<Scalars['String']>
  month?: InputMaybe<Scalars['String']>
  newCustomer?: InputMaybe<Scalars['String']>
  partTimeEmployees?: InputMaybe<Scalars['String']>
  physicalLocation?: InputMaybe<Scalars['String']>
  productSale?: InputMaybe<Scalars['String']>
  race?: InputMaybe<Scalars['String']>
  redirectUrl: Scalars['String']
  registerBusinessStructureOptionId?: InputMaybe<Scalars['String']>
  revenue?: InputMaybe<Scalars['String']>
  serviceSale?: InputMaybe<Scalars['String']>
  targetAudience?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  volunteers?: InputMaybe<Scalars['String']>
  wages?: InputMaybe<Scalars['String']>
  year?: InputMaybe<Scalars['String']>
}

export type AttestationResponse = {
  __typename?: 'AttestationResponse'
  data?: Maybe<Attestation>
  error?: Maybe<Scalars['String']>
}

export enum AttestationStatus {
  Signed = 'SIGNED',
  Suspended = 'SUSPENDED',
  Unsigned = 'UNSIGNED',
}

export type AttestationSummary = {
  __typename?: 'AttestationSummary'
  aggregationReportHistory?: Maybe<Array<Maybe<Attestation>>>
  newAggregatedReport?: Maybe<Attestation>
  signedCount?: Maybe<Scalars['Int']>
  submissionEndDate?: Maybe<Scalars['AWSDateTime']>
  submissionReminderDate?: Maybe<Scalars['AWSDateTime']>
  submissionReportDate?: Maybe<Scalars['AWSDateTime']>
  submissionStartDate?: Maybe<Scalars['AWSDateTime']>
  tenantId?: Maybe<Scalars['ID']>
  unsignedCount?: Maybe<Scalars['Int']>
}

export type AuthValue = {
  __typename?: 'AuthValue'
  AccessToken?: Maybe<Scalars['String']>
  Expires?: Maybe<Scalars['String']>
  RefreshToken?: Maybe<Scalars['String']>
}

export type AvailabilityHoursInput = {
  duration?: InputMaybe<Array<Scalars['String']>>
  timeSlots?: InputMaybe<Array<InputMaybe<TimeSlotsInput>>>
}

export type AvailabilityHoursObj = {
  __typename?: 'AvailabilityHoursObj'
  duration?: Maybe<Array<Scalars['String']>>
  timeSlots?: Maybe<Array<Maybe<TimeSlotsObj>>>
  timezone?: Maybe<Scalars['String']>
}

export type BoAdvisorsResponse = {
  __typename?: 'BOAdvisorsResponse'
  data?: Maybe<Array<Maybe<Advisors>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type BalanceSheet = {
  __typename?: 'BalanceSheet'
  assets?: Maybe<Array<Maybe<AccountingToolEntry>>>
  endDate?: Maybe<Scalars['String']>
  equity?: Maybe<Array<Maybe<AccountingToolEntry>>>
  liabilities?: Maybe<Array<Maybe<AccountingToolEntry>>>
  startDate?: Maybe<Scalars['String']>
}

export type BookkeepingInfo = {
  __typename?: 'BookkeepingInfo'
  balanceSheet?: Maybe<BalanceSheet>
  cashFlow?: Maybe<CashFlow>
  payments?: Maybe<Array<Maybe<Payment>>>
  profitAndLoss?: Maybe<ProfitAndLoss>
}

export type BookmarkToolInput = {
  isBookmarked: Scalars['Boolean']
  toolId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type BookmarkToolResponse = {
  __typename?: 'BookmarkToolResponse'
  data?: Maybe<Tool>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type BusinessAddress = {
  __typename?: 'BusinessAddress'
  addressLine1?: Maybe<Scalars['String']>
  addressLine2?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
}

export type BusinessAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']>
  addressLine2?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  postalCode?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  zipCode?: InputMaybe<Scalars['String']>
}

export type BusinessGoal = {
  __typename?: 'BusinessGoal'
  businessGoalDescription?: Maybe<Scalars['String']>
  category?: Maybe<BusinessGoalCategory>
  description?: Maybe<Scalars['String']>
  expectedEndDate?: Maybe<Scalars['String']>
  filter?: Maybe<BusinessGoalDateFilter>
  goalId?: Maybe<Scalars['ID']>
  goalPriority?: Maybe<BusinessGoalPriority>
  goalTimeFrame?: Maybe<BusinessGoalTimeFrame>
  logo?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  pauseDate?: Maybe<Scalars['String']>
  plan?: Maybe<BusinessGoalPlan>
  progress?: Maybe<Scalars['Int']>
  startDate?: Maybe<Scalars['String']>
  status?: Maybe<UserTaskItemStatus>
  tenantId?: Maybe<Scalars['ID']>
  userId?: Maybe<Scalars['ID']>
}

export enum BusinessGoalCategory {
  Communications = 'COMMUNICATIONS',
  CustomerService = 'CUSTOMER_SERVICE',
  Finance = 'FINANCE',
  Hr = 'HR',
  InformationTechnology = 'INFORMATION_TECHNOLOGY',
  Legal = 'LEGAL',
  Marketing = 'MARKETING',
  Operations = 'OPERATIONS',
  Production = 'PRODUCTION',
  ResearchAndDevelopment = 'RESEARCH_AND_DEVELOPMENT',
  Sales = 'SALES',
  Strategy = 'STRATEGY',
}

export enum BusinessGoalDateFilter {
  AllTime = 'ALL_TIME',
  ThisMonth = 'THIS_MONTH',
  ThisWeek = 'THIS_WEEK',
  ThisYear = 'THIS_YEAR',
  Today = 'TODAY',
  Tomorrow = 'TOMORROW',
}

export type BusinessGoalMilestone = {
  __typename?: 'BusinessGoalMilestone'
  descriptions?: Maybe<Scalars['String']>
  expectedEndDate?: Maybe<Scalars['String']>
  filter?: Maybe<BusinessGoalDateFilter>
  milestoneId?: Maybe<Scalars['ID']>
  milestoneSpan?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['ID']>
  riskAndChallenges?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  status?: Maybe<UserTaskItemStatus>
  tasks?: Maybe<Array<UserTask>>
  timeline?: Maybe<Scalars['String']>
}

export type BusinessGoalMilestoneInput = {
  descriptions?: InputMaybe<Scalars['String']>
  expectedEndDate?: InputMaybe<Scalars['String']>
  milestoneId: Scalars['ID']
  name: Scalars['String']
  planId?: InputMaybe<Scalars['ID']>
  riskAndChallenges?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  status?: InputMaybe<UserTaskItemStatus>
  tasks?: InputMaybe<Array<UserTaskInput>>
  timeline?: InputMaybe<Scalars['String']>
}

export type BusinessGoalPlan = {
  __typename?: 'BusinessGoalPlan'
  expectedEndDate?: Maybe<Scalars['String']>
  filter?: Maybe<BusinessGoalDateFilter>
  goalId?: Maybe<Scalars['ID']>
  milestones?: Maybe<Array<BusinessGoalMilestone>>
  name?: Maybe<Scalars['String']>
  pauseDate?: Maybe<Scalars['String']>
  pauseDuration?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['ID']>
  progress?: Maybe<Scalars['Int']>
  startDate?: Maybe<Scalars['String']>
  state?: Maybe<BusinessGoalPlanState>
  tasks?: Maybe<Array<Maybe<UserTask>>>
  timeline?: Maybe<Scalars['String']>
}

export type BusinessGoalPlanInput = {
  category?: InputMaybe<Scalars['String']>
  expectedEndDate?: InputMaybe<Scalars['String']>
  goalId?: InputMaybe<Scalars['ID']>
  milestones?: InputMaybe<Array<BusinessGoalMilestoneInput>>
  name: Scalars['String']
  pauseDate?: InputMaybe<Scalars['String']>
  pauseDuration?: InputMaybe<Scalars['Int']>
  planId: Scalars['ID']
  progress?: InputMaybe<Scalars['Int']>
  startDate?: InputMaybe<Scalars['String']>
  state?: InputMaybe<BusinessGoalPlanState>
  timeline?: InputMaybe<Scalars['String']>
}

export enum BusinessGoalPlanState {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
}

export enum BusinessGoalPriority {
  HighPriority = 'HIGH_PRIORITY',
  LowPriority = 'LOW_PRIORITY',
  MediumPriority = 'MEDIUM_PRIORITY',
}

export enum BusinessGoalTimeFrame {
  LongTerm = 'LONG_TERM',
  MediumTerm = 'Medium_TERM',
  ShortTerm = 'SHORT_TERM',
}

export type BusinessGoalsFilterData = {
  categories?: InputMaybe<Array<BusinessGoalCategory>>
  isArchive?: InputMaybe<Scalars['Boolean']>
  priorities?: InputMaybe<Array<BusinessGoalPriority>>
  statuses?: InputMaybe<Array<UserTaskItemStatus>>
  timeFrames?: InputMaybe<Array<BusinessGoalTimeFrame>>
}

export enum BusinessIndustries {
  ArtsEntertainment = 'ArtsEntertainment',
  EducationSocialServices = 'EducationSocialServices',
  FoodAndRestaurantBar = 'FoodAndRestaurantBar',
  Other = 'Other',
  PersonalServices = 'PersonalServices',
  ProfessionalServices = 'ProfessionalServices',
  Retail = 'Retail',
}

export type BusinessPhoneNumber = {
  __typename?: 'BusinessPhoneNumber'
  countryCode?: Maybe<Scalars['Int']>
  phoneNo?: Maybe<Scalars['Int']>
}

export type BusinessProfile = {
  __typename?: 'BusinessProfile'
  EIN?: Maybe<Scalars['String']>
  POS?: Maybe<Scalars['String']>
  POSOptionId?: Maybe<Scalars['String']>
  accountingMethod?: Maybe<Scalars['String']>
  accountingMethodOptionId?: Maybe<Scalars['String']>
  additionalContext?: Maybe<Scalars['AWSJSON']>
  additionalInfo?: Maybe<Scalars['String']>
  address?: Maybe<Address>
  avatar?: Maybe<Scalars['String']>
  bookKeepingTool?: Maybe<Scalars['String']>
  bookKeepingToolOptionId?: Maybe<Scalars['String']>
  bookkeepingInfo?: Maybe<BookkeepingInfo>
  budget?: Maybe<Scalars['String']>
  businessAddress?: Maybe<BusinessAddress>
  businessBankAccount?: Maybe<Scalars['Boolean']>
  businessClassification?: Maybe<Scalars['String']>
  businessClassificationOptionId?: Maybe<Scalars['String']>
  businessDescription?: Maybe<Scalars['String']>
  businessEmail?: Maybe<Scalars['String']>
  businessLocations?: Maybe<Array<Scalars['String']>>
  businessLocationsOptionIds?: Maybe<Array<Scalars['String']>>
  businessName?: Maybe<Scalars['String']>
  businessNature?: Maybe<Scalars['String']>
  businessNatureOptionId?: Maybe<Scalars['String']>
  businessPhoneNumber?: Maybe<BusinessPhoneNumber>
  businessPlan?: Maybe<UserFile>
  businessStage?: Maybe<Scalars['String']>
  businessStageOptionId?: Maybe<Scalars['String']>
  businessVision?: Maybe<Scalars['String']>
  challenges?: Maybe<Scalars['String']>
  companyEmployees?: Maybe<Scalars['String']>
  contractors?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  dba?: Maybe<Scalars['String']>
  dbaNames?: Maybe<Array<Scalars['String']>>
  debtFinancing?: Maybe<Scalars['String']>
  domainName?: Maybe<Scalars['String']>
  education?: Maybe<Scalars['String']>
  employeeBenefit?: Maybe<Scalars['String']>
  exitPlan?: Maybe<Scalars['String']>
  exitPlanOptionId?: Maybe<Scalars['String']>
  expenses?: Maybe<Scalars['Float']>
  financialGrowth?: Maybe<Scalars['String']>
  finicityBusinessId?: Maybe<Scalars['String']>
  finicityConsumerId?: Maybe<Scalars['String']>
  finicityCustomerId?: Maybe<Scalars['String']>
  foodSubIndustry?: Maybe<Array<Scalars['String']>>
  foodSubIndustryOptionIds?: Maybe<Array<Scalars['String']>>
  fullTimeEmployees?: Maybe<Scalars['String']>
  gender?: Maybe<Scalars['String']>
  googleBusinessProfilePlays?: Maybe<Array<Scalars['String']>>
  googleBusinessProfilePlaysOptionIds?: Maybe<Array<Scalars['String']>>
  googleWorkspacePlays?: Maybe<Array<Scalars['String']>>
  googleWorkspacePlaysOptionIds?: Maybe<Array<Scalars['String']>>
  grossRevenueGoalForThisYear?: Maybe<Scalars['String']>
  hasAvatar?: Maybe<Scalars['Boolean']>
  hasBookKeepingTool?: Maybe<Scalars['String']>
  hasBookKeepingToolOptionId?: Maybe<Scalars['String']>
  hasBudget?: Maybe<Scalars['String']>
  hasBudgetOptionId?: Maybe<Scalars['String']>
  hasBusinessEmail?: Maybe<Scalars['String']>
  hasBusinessEmailOptionId?: Maybe<Scalars['String']>
  hasBusinessEmployees?: Maybe<Scalars['String']>
  hasBusinessEmployeesOptionId?: Maybe<Scalars['String']>
  hasBusinessPhoneNumber?: Maybe<Scalars['String']>
  hasBusinessPhoneNumberOptionId?: Maybe<Scalars['String']>
  hasDomainName?: Maybe<Scalars['String']>
  hasDomainNameOptionId?: Maybe<Scalars['String']>
  hasEsignatureTool?: Maybe<Scalars['String']>
  hasEsignatureToolOptionId?: Maybe<Scalars['String']>
  hasGoogleBusinessProfile?: Maybe<Scalars['String']>
  hasGoogleBusinessProfileOptionId?: Maybe<Scalars['String']>
  hasGoogleChrome?: Maybe<Scalars['String']>
  hasGoogleChromeOptionId?: Maybe<Scalars['String']>
  hasGoogleWorkspace?: Maybe<Scalars['String']>
  hasGoogleWorkspaceOptionId?: Maybe<Scalars['String']>
  hasLogo?: Maybe<Scalars['String']>
  hasLogoOptionId?: Maybe<Scalars['String']>
  hasLogoTrademarked?: Maybe<Scalars['String']>
  hasLogoTrademarkedOptionId?: Maybe<Scalars['String']>
  hasMoreLocations?: Maybe<Scalars['String']>
  hasMoreLocationsOptionId?: Maybe<Scalars['String']>
  hasPOS?: Maybe<Scalars['String']>
  hasPOSOptionId?: Maybe<Scalars['String']>
  hasPaymentProcessor?: Maybe<Scalars['String']>
  hasPaymentProcessorOptionId?: Maybe<Scalars['String']>
  hasPricingStrategies?: Maybe<Scalars['String']>
  hasPricingStrategiesOptionId?: Maybe<Scalars['String']>
  hasQuickBooks?: Maybe<Scalars['String']>
  hasQuickBooksOptionId?: Maybe<Scalars['String']>
  haveStartedGeneratingSales?: Maybe<Scalars['Boolean']>
  hoursSpent?: Maybe<Scalars['String']>
  hoursSpentOptionId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  jobsCreated?: Maybe<Scalars['String']>
  lastUpdatedBusinessPlan?: Maybe<Scalars['AWSDateTime']>
  lastYearRevenue?: Maybe<Scalars['String']>
  launchDate?: Maybe<Scalars['AWSDateTime']>
  learnPricingStrategies?: Maybe<Scalars['String']>
  learnPricingStrategiesOptionId?: Maybe<Scalars['String']>
  loginFrequency?: Maybe<Scalars['String']>
  logo?: Maybe<UserFile>
  metricTimeSeries?: Maybe<MetricTimeSeries>
  metricValue?: Maybe<MetricValue>
  minorityOwned?: Maybe<Scalars['String']>
  month?: Maybe<Scalars['String']>
  monthlyImpactReports?: Maybe<Array<UserFile>>
  motivation?: Maybe<Scalars['String']>
  naicsCode?: Maybe<Scalars['String']>
  newCustomer?: Maybe<Scalars['String']>
  noOfBusinessStarted?: Maybe<Scalars['String']>
  numberOfBusinessBankAccounts?: Maybe<Scalars['String']>
  onboardedAt?: Maybe<Scalars['String']>
  otherExitPlan?: Maybe<Scalars['String']>
  otherGoals?: Maybe<Array<Scalars['String']>>
  otherGoalsOptionIds?: Maybe<Array<Scalars['String']>>
  otherRegisteredBusinessStructure?: Maybe<Scalars['String']>
  otherWhereDoYouDoBusiness?: Maybe<Scalars['String']>
  partTimeEmployees?: Maybe<Scalars['String']>
  paymentAcceptance?: Maybe<Scalars['String']>
  paymentAcceptanceOptionId?: Maybe<Scalars['String']>
  paymentAcceptancePlan?: Maybe<Scalars['String']>
  paymentAcceptancePlanOptionId?: Maybe<Scalars['String']>
  paymentApps?: Maybe<Array<Scalars['String']>>
  paymentAppsOptionIds?: Maybe<Array<Scalars['String']>>
  paymentProcessor?: Maybe<Scalars['String']>
  paymentProcessorOptionId?: Maybe<Scalars['String']>
  paymentTypes?: Maybe<Array<Scalars['String']>>
  paymentTypesOptionIds?: Maybe<Array<Scalars['String']>>
  personalGoals?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  physicalLocation?: Maybe<Scalars['String']>
  pricingStrategies?: Maybe<Array<Scalars['String']>>
  pricingStrategiesOptionIds?: Maybe<Array<Scalars['String']>>
  productSale?: Maybe<Scalars['String']>
  productsAndServices?: Maybe<Array<Scalars['String']>>
  productsAndServicesOptionIds?: Maybe<Array<Scalars['String']>>
  quickBookPlays?: Maybe<Array<Scalars['String']>>
  quickBookPlaysOptionIds?: Maybe<Array<Scalars['String']>>
  race?: Maybe<Scalars['String']>
  registerBusinessStructure?: Maybe<Scalars['String']>
  registerBusinessStructureOptionId?: Maybe<Scalars['String']>
  revenue?: Maybe<Scalars['String']>
  revenueStatus?: Maybe<Scalars['String']>
  revenueStatusOptionId?: Maybe<Scalars['String']>
  saleGoal?: Maybe<Scalars['String']>
  sbaCertified?: Maybe<Scalars['String']>
  seasonalityDetails?: Maybe<Scalars['String']>
  serviceSale?: Maybe<Scalars['String']>
  servicesSubIndustry?: Maybe<Scalars['String']>
  servicesSubIndustryOptionId?: Maybe<Scalars['String']>
  squarePlays?: Maybe<Array<Scalars['String']>>
  squarePlaysOptionIds?: Maybe<Array<Scalars['String']>>
  stripePlays?: Maybe<Array<Scalars['String']>>
  stripePlaysOptionIds?: Maybe<Array<Scalars['String']>>
  strongBusinessAreas?: Maybe<Array<Scalars['String']>>
  strongBusinessAreasOptionIds?: Maybe<Array<Scalars['String']>>
  supportAndHelp?: Maybe<Array<Scalars['String']>>
  supportAndHelpOptionIds?: Maybe<Array<Scalars['String']>>
  targetAudience?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
  thisYearRevenue?: Maybe<Scalars['String']>
  topGoal?: Maybe<Scalars['String']>
  topGoalOptionId?: Maybe<Scalars['String']>
  totalEmployees?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
  usePOS?: Maybe<Scalars['String']>
  usePOSOptionId?: Maybe<Scalars['String']>
  usePaymentProcessor?: Maybe<Scalars['String']>
  usePaymentProcessorOptionId?: Maybe<Scalars['String']>
  userName?: Maybe<Scalars['String']>
  veteranOwned?: Maybe<Scalars['String']>
  volunteers?: Maybe<Scalars['String']>
  wages?: Maybe<Scalars['String']>
  whereDoYouDoBusiness?: Maybe<Array<Scalars['String']>>
  whereDoYouDoBusinessOptionIds?: Maybe<Array<Scalars['String']>>
  year?: Maybe<Scalars['String']>
  yearEstablished?: Maybe<Scalars['String']>
  yearsInBusiness?: Maybe<Scalars['String']>
}

export type BusinessProfileMetricTimeSeriesArgs = {
  input: MetricTimeSeriesInput
}

export type BusinessProfileMetricValueArgs = {
  input: MetricValueInput
}

export type BusinessProfileFields = {
  __typename?: 'BusinessProfileFields'
  logo?: Maybe<Scalars['String']>
}

export type BusinessSummaryData = {
  __typename?: 'BusinessSummaryData'
  optionId?: Maybe<Scalars['String']>
  optionName?: Maybe<Scalars['String']>
  optionType?: Maybe<Scalars['String']>
  summary?: Maybe<Scalars['Int']>
  tenantId?: Maybe<Scalars['String']>
}

export type BusinessSummaryItem = {
  __typename?: 'BusinessSummaryItem'
  data: Array<BusinessSummaryData>
  optionType?: Maybe<Scalars['String']>
}

export type BusinessSummaryResponse = {
  __typename?: 'BusinessSummaryResponse'
  data: Array<BusinessSummaryItem>
  status: ChartStatus
}

export type BusinessSupportOrganization = {
  __typename?: 'BusinessSupportOrganization'
  adminCount?: Maybe<Scalars['Int']>
  adminId: Scalars['String']
  advisorCount?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  licenseCount?: Maybe<Scalars['Int']>
  licensesUsed?: Maybe<Scalars['Int']>
  logoUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
  status?: Maybe<OrganizationStatus>
  terminology?: Maybe<Array<Term>>
}

export enum Calendar {
  GoogleCalendar = 'GOOGLE_CALENDAR',
  Internal = 'INTERNAL',
  MicrosoftOffice_365 = 'MICROSOFT_OFFICE_365',
}

export type CalendarEvent = {
  __typename?: 'CalendarEvent'
  advisorId?: Maybe<Scalars['String']>
  advisorName?: Maybe<Scalars['String']>
  advisorProfiles?: Maybe<Scalars['AWSJSON']>
  attendees?: Maybe<Array<CalendarEventAttendee>>
  availability?: Maybe<Scalars['String']>
  comment?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  end?: Maybe<CalendarEventDate>
  eventId?: Maybe<Scalars['String']>
  eventTime?: Maybe<Scalars['AWSDateTime']>
  eventType?: Maybe<Scalars['String']>
  externalReferenceIds?: Maybe<Array<CalendarEventExternalReferenceId>>
  id?: Maybe<Scalars['String']>
  inviteeId?: Maybe<Scalars['String']>
  isAllDay?: Maybe<Scalars['Boolean']>
  isBusy?: Maybe<Scalars['Boolean']>
  location?: Maybe<Scalars['String']>
  meetingDetailsHTML?: Maybe<Scalars['String']>
  meta?: Maybe<Scalars['AWSJSON']>
  organizerFirstName?: Maybe<Scalars['String']>
  organizerLastName?: Maybe<Scalars['String']>
  organizerName?: Maybe<Scalars['String']>
  originator?: Maybe<Scalars['String']>
  ownerId?: Maybe<Scalars['String']>
  participantFirstName?: Maybe<Scalars['String']>
  participantLastName?: Maybe<Scalars['String']>
  participantName?: Maybe<Scalars['String']>
  profile?: Maybe<Scalars['AWSJSON']>
  start?: Maybe<CalendarEventDate>
  status?: Maybe<Scalars['String']>
  summary?: Maybe<Scalars['String']>
  timezone?: Maybe<Scalars['String']>
  videoLinkDescription?: Maybe<Scalars['String']>
}

export type CalendarEventAttendee = {
  __typename?: 'CalendarEventAttendee'
  email: Scalars['String']
  optional?: Maybe<Scalars['Boolean']>
  responseStatus?: Maybe<Scalars['String']>
}

export type CalendarEventDate = {
  __typename?: 'CalendarEventDate'
  date?: Maybe<Scalars['String']>
  dateTime?: Maybe<Scalars['String']>
  timeZone?: Maybe<Scalars['String']>
}

export type CalendarEventExternalReferenceId = {
  __typename?: 'CalendarEventExternalReferenceId'
  calendarId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  eventId: Scalars['String']
  externalEventId?: Maybe<Scalars['String']>
  meetingLink?: Maybe<Scalars['String']>
  meta?: Maybe<Scalars['AWSJSON']>
  pk?: Maybe<Scalars['String']>
  sk?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  type: Scalars['String']
  userId: Scalars['String']
}

export type CalendarEventResponse = {
  __typename?: 'CalendarEventResponse'
  data: Array<CalendarEvent>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export enum CalendarEventViewPreference {
  All = 'ALL',
  Internal = 'INTERNAL',
}

export type CalendlyUser = {
  __typename?: 'CalendlyUser'
  calendlyEventsUrl?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  schedulingUrl?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type CalendlyUserResponse = {
  __typename?: 'CalendlyUserResponse'
  data?: Maybe<CalendlyUser>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type CancelUserCalendarEvent = {
  __typename?: 'CancelUserCalendarEvent'
  eventId?: Maybe<Scalars['String']>
  eventType?: Maybe<Scalars['String']>
  tackleId?: Maybe<Scalars['String']>
}

export type CancelUserCalendarEventInput = {
  eventId?: InputMaybe<Scalars['String']>
  eventType?: InputMaybe<Scalars['String']>
  tackleId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}

export type CancelUserCalendarEventResponse = {
  __typename?: 'CancelUserCalendarEventResponse'
  data?: Maybe<CancelUserCalendarEvent>
  error?: Maybe<Scalars['String']>
}

export type CashBalanceByMonthReport = {
  __typename?: 'CashBalanceByMonthReport'
  cashBalance?: Maybe<Scalars['Float']>
  endDate?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
}

export type CashBalanceReport = AccountingReport & {
  __typename?: 'CashBalanceReport'
  cashBalance?: Maybe<Scalars['Float']>
  dateRange?: Maybe<DateRanges>
  endDate?: Maybe<Scalars['String']>
  growth?: Maybe<Scalars['Float']>
  source?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type CashFlow = {
  __typename?: 'CashFlow'
  activities?: Maybe<Array<Maybe<AccountingToolEntry>>>
  endDate?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
}

export enum ChartStatus {
  DisconnectedSource = 'DisconnectedSource',
  Error = 'Error',
  Processing = 'Processing',
  Success = 'Success',
}

export type ChatBotMessage = {
  __typename?: 'ChatBotMessage'
  content: Scalars['String']
  createdAt: Scalars['String']
  messageType: Scalars['String']
  role: Scalars['String']
}

export enum ChatBotMessageTypes {
  Form = 'Form',
  FormResponse = 'FormResponse',
  Text = 'Text',
  ToolConnected = 'ToolConnected',
  Tools = 'Tools',
}

export type ChatSession = {
  __typename?: 'ChatSession'
  streamUrl: Scalars['String']
}

export type CognitoUser = {
  __typename?: 'CognitoUser'
  email: Scalars['String']
  enabled?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  userName: Scalars['String']
  userStatus?: Maybe<Scalars['String']>
}

export type CounterResponse = {
  __typename?: 'CounterResponse'
  value: Scalars['Float']
}

export type CreateChatSessionInput = {
  assistantId?: InputMaybe<Scalars['String']>
  onboardingFlowType?: InputMaybe<OnboardingFlowType>
  targetOrigin: Scalars['String']
  threadId?: InputMaybe<Scalars['String']>
}

export type CreateFlowChatSessionInput = {
  flow: Scalars['String']
}

export type CreateToolInput = {
  description?: InputMaybe<Scalars['String']>
  featured?: InputMaybe<Scalars['Boolean']>
  imageFile?: InputMaybe<ToolImageFileInput>
  imageUrl?: InputMaybe<Scalars['String']>
  keyWords?: InputMaybe<Array<Scalars['String']>>
  longDescription?: InputMaybe<Scalars['String']>
  pricingPageUrl?: InputMaybe<Scalars['String']>
  supportLevel?: InputMaybe<ToolSupportLevel>
  tags?: InputMaybe<Array<Scalars['String']>>
  toolDisplayName?: InputMaybe<Scalars['String']>
  toolHelpText?: InputMaybe<Scalars['String']>
  toolName: Scalars['String']
  toolType?: InputMaybe<ToolType>
  toolUrl: Scalars['String']
  userToolsImageUrl: Scalars['String']
  visible?: InputMaybe<Scalars['Boolean']>
}

export type CreateUserInput = {
  advisors?: InputMaybe<Scalars['AWSJSON']>
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  mobileContactNumber: Scalars['AWSPhone']
  owners?: InputMaybe<Scalars['AWSJSON']>
  role: Scalars['String']
  suppressEmail?: InputMaybe<Scalars['Boolean']>
  tenantId?: InputMaybe<Scalars['String']>
}

export type CreateUserTaskInput = {
  assigneeId?: InputMaybe<Scalars['ID']>
  category?: InputMaybe<BusinessGoalCategory>
  estimationHour?: InputMaybe<Scalars['Int']>
  expectedEndDate?: InputMaybe<Scalars['String']>
  howToDo?: InputMaybe<Scalars['String']>
  howToLinks?: InputMaybe<Array<InputMaybe<UserTaskHowToInput>>>
  instructionForTask?: InputMaybe<Scalars['String']>
  kpis?: InputMaybe<Scalars['String']>
  milestoneId?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  order?: InputMaybe<Scalars['Int']>
  planId?: InputMaybe<Scalars['ID']>
  resources?: InputMaybe<Scalars['String']>
  role?: InputMaybe<Scalars['String']>
  source: UserTaskSource
  startDate?: InputMaybe<Scalars['String']>
  status?: InputMaybe<UserTaskItemStatus>
  taskPriority?: InputMaybe<BusinessGoalPriority>
  tenantId: Scalars['ID']
  timeline?: InputMaybe<Scalars['String']>
  tool?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['ID']>
  why?: InputMaybe<Scalars['String']>
}

export type CyclrConnector = {
  __typename?: 'CyclrConnector'
  authValue?: Maybe<AuthValue>
  authenticated?: Maybe<Scalars['Boolean']>
  connectorId?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type CyclrConnectorResponse = {
  __typename?: 'CyclrConnectorResponse'
  data?: Maybe<Array<CyclrConnector>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export enum DateRanges {
  Last12Months = 'last12Months',
  LastMonth = 'lastMonth',
  LastQuarter = 'lastQuarter',
  LastYear = 'lastYear',
  LastYearToDate = 'lastYearToDate',
  ThisMonth = 'thisMonth',
  ThisQuarter = 'thisQuarter',
  ThisWeek = 'thisWeek',
  ThisYear = 'thisYear',
  ThisYearToDate = 'thisYearToDate',
}

export type DeauthenticateConnectorInput = {
  connectorName: Scalars['String']
  tenantId: Scalars['String']
}

export type DeleteCalendarEventInput = {
  meta?: InputMaybe<Scalars['AWSJSON']>
}

export type DeleteCalendarEventResponse = {
  __typename?: 'DeleteCalendarEventResponse'
  data?: Maybe<Scalars['AWSJSON']>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type DeleteUserFilesInput = {
  recordIds: Array<Scalars['String']>
}

export type DeleteUserNotificationsInput = {
  deleteAll?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}

export type DeleteUserTaskInput = {
  taskId: Scalars['ID']
  tenantId: Scalars['ID']
  userId?: InputMaybe<Scalars['ID']>
}

export enum ErrorType {
  DisconnectedToolError = 'DisconnectedToolError',
  FailedMeetingEmailInvites = 'FailedMeetingEmailInvites',
  ForceLogoutError = 'ForceLogoutError',
  LicenseLimitReached = 'LicenseLimitReached',
  ValidationError = 'ValidationError',
}

export type FileDetails = {
  id: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  size?: InputMaybe<Scalars['String']>
  streamMessageId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  type?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type FileStructure = {
  __typename?: 'FileStructure'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type FileStructureInput = {
  id?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  size?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type GetAdvisorProfileInput = {
  advisorId: Scalars['String']
  tenantId?: InputMaybe<Scalars['String']>
}

export type GetAdvisorsInput = {
  advisorId?: InputMaybe<Scalars['String']>
  isPaginated?: InputMaybe<Scalars['Boolean']>
  lastEvaluatedKey?: InputMaybe<Scalars['String']>
  pageSize?: InputMaybe<Scalars['String']>
  searchQuery?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}

export type GetAllToolCategoriesResponse = {
  __typename?: 'GetAllToolCategoriesResponse'
  categories: Array<GetAllToolCategoriesResponseItem>
  subCategories: Array<GetAllToolCategoriesResponseItem>
}

export type GetAllToolCategoriesResponseItem = {
  __typename?: 'GetAllToolCategoriesResponseItem'
  description?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type GetAllToolsGroupedResponse = {
  __typename?: 'GetAllToolsGroupedResponse'
  data?: Maybe<Array<Maybe<GroupedTools>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type GetAllToolsResponse = {
  __typename?: 'GetAllToolsResponse'
  data?: Maybe<Array<Maybe<Tool>>>
  error?: Maybe<Scalars['String']>
  groupedTools?: Maybe<GroupedTools>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type GetAttestationReportsInput = {
  filter: AttestationStatus
  lastEvaluatedKey?: InputMaybe<Scalars['String']>
  month?: InputMaybe<Scalars['String']>
  page: Scalars['Int']
  pageSize: Scalars['Int']
  paginationDirection: PaginationDirection
  sort?: InputMaybe<SortOrder>
  tenantId: Scalars['String']
  year?: InputMaybe<Scalars['String']>
}

export type GetAttestationReportsResponse = {
  __typename?: 'GetAttestationReportsResponse'
  data?: Maybe<GetAttestationReportsResponseObj>
  error?: Maybe<Scalars['String']>
}

export type GetAttestationReportsResponseObj = {
  __typename?: 'GetAttestationReportsResponseObj'
  currentPage?: Maybe<Scalars['Int']>
  items?: Maybe<Array<Maybe<Attestation>>>
  lastEvaluatedKey?: Maybe<Scalars['String']>
  pageSize?: Maybe<Scalars['Int']>
  prevEvaluatedKey?: Maybe<Scalars['String']>
  totalPages?: Maybe<Scalars['Int']>
  totalRecords?: Maybe<Scalars['Int']>
}

export type GetAttestationSummaryInput = {
  advisorId: Scalars['ID']
  includeAggregationDetails?: InputMaybe<Scalars['Boolean']>
  tenantId: Scalars['ID']
  timePeriod: TimePeriod
}

export type GetBusinessGoalInput = {
  filter?: InputMaybe<BusinessGoalDateFilter>
  goalId: Scalars['ID']
  tenantId: Scalars['ID']
  userId?: InputMaybe<Scalars['ID']>
}

export type GetBusinessGoalsInput = {
  filter?: InputMaybe<BusinessGoalsFilterData>
  tenantId: Scalars['ID']
  userId?: InputMaybe<Scalars['ID']>
}

export type GetBusinessIndustryDetailsInput = {
  foodSubIndustryOptionIds?: InputMaybe<Array<Scalars['String']>>
  userId?: InputMaybe<Scalars['String']>
}

export type GetBusinessIndustryDetailsResponse = {
  __typename?: 'GetBusinessIndustryDetailsResponse'
  isSubIndustryUpdated?: Maybe<Scalars['Boolean']>
}

export type GetBusinessSummaryInput = {
  advisorId?: InputMaybe<Scalars['String']>
  optionType?: InputMaybe<OptionTypes>
  tenantId?: InputMaybe<Scalars['String']>
}

export type GetBusinessSupportOrganizationInput = {
  tenantId: Scalars['String']
}

export type GetBusinessesInput = {
  advisor?: InputMaybe<Scalars['String']>
  allStatuses: Scalars['Boolean']
  isPaginated?: InputMaybe<Scalars['Boolean']>
  lastEvaluatedKey?: InputMaybe<Scalars['String']>
  pageSize?: InputMaybe<Scalars['String']>
  searchQuery?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}

export type GetChartDataInput = {
  businessId?: InputMaybe<Scalars['String']>
  chartName: Scalars['String']
  endDate?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type GetClientsInput = {
  advisorId?: InputMaybe<Scalars['String']>
  includeInvitedClients?: InputMaybe<Scalars['Boolean']>
  tenantId?: InputMaybe<Scalars['String']>
}

export type GetClientsResponse = {
  __typename?: 'GetClientsResponse'
  data?: Maybe<Array<Maybe<GetClientsResponseData>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type GetClientsResponseData = {
  __typename?: 'GetClientsResponseData'
  bio?: Maybe<Scalars['String']>
  businessName?: Maybe<Scalars['String']>
  calendlyEventsUrl?: Maybe<Scalars['String']>
  companyAdvisor?: Maybe<Scalars['String']>
  companyEmployees?: Maybe<Scalars['Int']>
  createdAt: Scalars['AWSDateTime']
  email: Scalars['AWSEmail']
  firstName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isNewUser?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  mobileContactNumber?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['AWSPhone']>
  roles?: Maybe<Array<Maybe<Scalars['String']>>>
  schedulingUrl?: Maybe<Scalars['String']>
  updatedAt: Scalars['AWSDateTime']
  websiteURL?: Maybe<Scalars['String']>
  yearsInBusiness?: Maybe<Scalars['String']>
}

export type GetMeetingTypeResponse = {
  __typename?: 'GetMeetingTypeResponse'
  data?: Maybe<Array<Maybe<MeetingTypes>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type GetOAuthUrlInput = {
  authType?: InputMaybe<ToolAuthType>
  connectorName: Scalars['String']
  oAuthConnectionProperties?: InputMaybe<Array<OAuthConnectionProperty>>
  targetOrigin: Scalars['String']
}

export type GetOnboardingFlowStateInput = {
  flowType: OnboardingFlowType
  sessionId: Scalars['String']
}

export type GetOptionsResponse = {
  __typename?: 'GetOptionsResponse'
  data?: Maybe<Array<Maybe<Options>>>
  error?: Maybe<Scalars['String']>
}

export type GetPlayResponseInput = {
  playId?: InputMaybe<Scalars['String']>
  tabId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type GetPlaysInput = {
  lastEvaluatedKey?: InputMaybe<Scalars['AWSJSON']>
  pageSize?: InputMaybe<Scalars['Int']>
}

export type GetPlaysResponse = {
  __typename?: 'GetPlaysResponse'
  data?: Maybe<Array<Maybe<Play>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type GetTackleMeetingResponse = {
  __typename?: 'GetTackleMeetingResponse'
  data?: Maybe<Array<Maybe<CalendarEvent>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  pagination?: Maybe<Scalars['AWSJSON']>
  success: Scalars['Boolean']
}

export type GetTackleMeetingsInput = {
  filter?: InputMaybe<Scalars['String']>
  isPaginated?: InputMaybe<Scalars['Boolean']>
  lastEvaluatedKey?: InputMaybe<Scalars['AWSJSON']>
  ownerId: Scalars['String']
  pageSize?: InputMaybe<Scalars['Int']>
  tenantId: Scalars['String']
}

export type GetTenantsResponse = {
  __typename?: 'GetTenantsResponse'
  data?: Maybe<Array<Maybe<Tenant>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type GetToolByDataTypesInput = {
  dataTypes: Array<ToolDataTypes>
  getOpenTools?: InputMaybe<Scalars['Boolean']>
  onboardingFlowType?: InputMaybe<OnboardingFlowType>
  targetOrigin: Scalars['String']
  userId: Scalars['String']
}

export type GetToolsByTypeInput = {
  tenantId: Scalars['String']
  toolType: ToolType
}

export type GetToolsByTypeResponse = {
  __typename?: 'GetToolsByTypeResponse'
  data?: Maybe<Array<Maybe<Tool>>>
  error?: Maybe<Scalars['String']>
}

export type GetToolsResponse = {
  __typename?: 'GetToolsResponse'
  data?: Maybe<Array<Maybe<Tool>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type GetTopActiveClientsResponse = {
  __typename?: 'GetTopActiveClientsResponse'
  data?: Maybe<Array<Maybe<TopActiveClient>>>
  error?: Maybe<Scalars['String']>
}

export type GetUserAvailability = {
  __typename?: 'GetUserAvailability'
  availability: Array<UserAvailability>
  duration: Scalars['String']
}

export type GetUserAvailabilityHoursResponse = {
  __typename?: 'GetUserAvailabilityHoursResponse'
  data?: Maybe<SetUserAvailabilityObj>
  error?: Maybe<Scalars['String']>
}

export type GetUserAvailabilityInput = {
  duration: Scalars['Int']
  endTime?: InputMaybe<Scalars['String']>
  ignoreConflicts?: InputMaybe<Scalars['Boolean']>
  participantId?: InputMaybe<Scalars['String']>
  role?: InputMaybe<Array<Scalars['String']>>
  startTime?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
  timeZone?: InputMaybe<Scalars['String']>
}

export type GetUserAvailabilityResponse = {
  __typename?: 'GetUserAvailabilityResponse'
  data?: Maybe<GetUserAvailability>
  error?: Maybe<Scalars['String']>
  meetingLocation?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type GetUserMeetingByIdInput = {
  id: Scalars['String']
  tenantId: Scalars['String']
}

export type GetUserMeetingByIdResponse = {
  __typename?: 'GetUserMeetingByIdResponse'
  data?: Maybe<CalendarEvent>
  error?: Maybe<Scalars['String']>
}

export type GetUserNotificationsInput = {
  countOnly?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['String']>
  isPaginated?: InputMaybe<Scalars['Boolean']>
  lastEvaluatedKey?: InputMaybe<Scalars['String']>
  pageSize?: InputMaybe<Scalars['Int']>
  tenantId: Scalars['String']
}

export type GetUserNotificationsResponse = {
  __typename?: 'GetUserNotificationsResponse'
  data?: Maybe<UserNotificationData>
  error?: Maybe<Scalars['String']>
}

export type GetUserRecommendationsInput = {
  assessmentType?: InputMaybe<Scalars['String']>
  businessId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}

export type GetUserTaskInput = {
  taskId: Scalars['ID']
  tenantId: Scalars['ID']
  userId?: InputMaybe<Scalars['ID']>
}

export type GetUserTasksInput = {
  filter?: InputMaybe<UserTasksFilterInput>
  tenantId: Scalars['ID']
  userId?: InputMaybe<Scalars['ID']>
}

export type GetUserToolsResponse = {
  __typename?: 'GetUserToolsResponse'
  data?: Maybe<Array<Maybe<Tool>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type Goal = {
  __typename?: 'Goal'
  assessments?: Maybe<Scalars['AWSJSON']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isActiveGoal?: Maybe<Scalars['Boolean']>
  isTopGoal?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  orderOfSequence?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type GoogleCalendarWebhookMetaData = {
  __typename?: 'GoogleCalendarWebhookMetaData'
  expiration?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  kind?: Maybe<Scalars['String']>
  resourceId?: Maybe<Scalars['String']>
  resourceUri?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}

export type GroupedTools = {
  __typename?: 'GroupedTools'
  category?: Maybe<Scalars['String']>
  tools?: Maybe<Array<Maybe<Tool>>>
}

export type IndustryReport = {
  __typename?: 'IndustryReport'
  results?: Maybe<Array<Maybe<IndustryReportResult>>>
  searchedKeyword?: Maybe<Scalars['String']>
  summaryText?: Maybe<Scalars['String']>
}

export type IndustryReportResult = {
  __typename?: 'IndustryReportResult'
  docTitle?: Maybe<Scalars['String']>
  docUrl?: Maybe<Scalars['String']>
  pageNumber?: Maybe<Scalars['Int']>
  summary?: Maybe<Scalars['String']>
}

export type JobDto = {
  __typename?: 'JobDto'
  createdAt?: Maybe<Scalars['String']>
  id: Scalars['String']
  payload?: Maybe<Scalars['AWSJSON']>
  status: JobStatus
  type: JobTypes
  updatedAt?: Maybe<Scalars['String']>
}

export enum JobStatus {
  Done = 'Done',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
}

export enum JobTypes {
  ToolDataProcessing = 'ToolDataProcessing',
}

export type KeyValuePair = {
  __typename?: 'KeyValuePair'
  key: Scalars['String']
  value: Scalars['String']
}

export type KeyValuePairInput = {
  key: Scalars['String']
  value: Scalars['String']
}

export type LineItem = {
  __typename?: 'LineItem'
  amount?: Maybe<Scalars['Float']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
}

export type LiquidCashReport = AccountingReport & {
  __typename?: 'LiquidCashReport'
  dateRange?: Maybe<DateRanges>
  endDate?: Maybe<Scalars['String']>
  growth?: Maybe<Scalars['Float']>
  liquidCash?: Maybe<Scalars['Float']>
  source?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type ListAssessment = {
  __typename?: 'ListAssessment'
  completedAt?: Maybe<Scalars['AWSDateTime']>
  inProgress?: Maybe<Scalars['Boolean']>
  isCompleted?: Maybe<Scalars['Boolean']>
  isLocked?: Maybe<Scalars['Boolean']>
  order?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type ListAssessmentResponse = {
  __typename?: 'ListAssessmentResponse'
  data?: Maybe<Array<Maybe<ListAssessment>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type ListCognitoUsersInput = {
  role: Scalars['String']
}

export type MakeAnnouncementInput = {
  receiverIds: Array<Scalars['String']>
  tenantId: Scalars['String']
  text: Scalars['String']
}

export enum MeetingLocation {
  Custom = 'CUSTOM',
  GoogleMeet = 'GOOGLE_MEET',
  MicrosoftTeams = 'MICROSOFT_TEAMS',
  Zoom = 'ZOOM',
}

export type MeetingTypes = {
  __typename?: 'MeetingTypes'
  duration?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  label?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
}

export type MessageChatSession = {
  __typename?: 'MessageChatSession'
  createdAt?: Maybe<Scalars['String']>
  flow?: Maybe<Scalars['String']>
  id: Scalars['String']
  title?: Maybe<Scalars['String']>
}

export type MessageToBotResponse = {
  __typename?: 'MessageToBotResponse'
  hasMore: Scalars['Boolean']
  messages?: Maybe<Array<ChatBotMessage>>
  nextCursor?: Maybe<Scalars['String']>
}

export enum MetricGranularity {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Year = 'YEAR',
}

export type MetricTimeSeries = {
  __typename?: 'MetricTimeSeries'
  timeSeries?: Maybe<TimeSeriesResponse>
}

export type MetricTimeSeriesInput = {
  granularity: MetricGranularity
  metricCode: Scalars['ID']
  timeRange: TimeRangeInput
}

export type MetricValue = {
  __typename?: 'MetricValue'
  counter?: Maybe<CounterResponse>
}

export type MetricValueInput = {
  metricCode: Scalars['ID']
  timeRange: TimeRangeValueInput
}

export type Mutation = {
  __typename?: 'Mutation'
  addUserNotification?: Maybe<ApiResponse>
  addUserToGroup: Scalars['Boolean']
  authorizeConnector?: Maybe<ApiResponse>
  bookmarkTool?: Maybe<BookmarkToolResponse>
  cancelUserCalendarEvent?: Maybe<CancelUserCalendarEventResponse>
  connectFinicity: Scalars['String']
  createChatSession?: Maybe<ChatSession>
  createFlowChatSession: MessageChatSession
  createTool?: Maybe<Tool>
  createUser?: Maybe<UserProfile>
  createUserTask?: Maybe<UserTask>
  deauthenticateConnector?: Maybe<ApiResponse>
  deleteCalendarEvent?: Maybe<DeleteCalendarEventResponse>
  deleteUserFiles?: Maybe<Scalars['Boolean']>
  deleteUserNotifications?: Maybe<ApiResponse>
  deleteUserTask?: Maybe<Scalars['String']>
  listCognitoUsers: Array<Maybe<CognitoUser>>
  logActivity?: Maybe<ApiResponse>
  makeAnnouncement?: Maybe<Scalars['Boolean']>
  processToolBusinessData: Scalars['String']
  resendUserInvite?: Maybe<Scalars['Boolean']>
  saveAssessmentResponse?: Maybe<AssessmentResponse>
  saveAttestation?: Maybe<AttestationResponse>
  saveBusinessGoal?: Maybe<Scalars['String']>
  savePlayResponse?: Maybe<PlayResponse>
  saveUserFile?: Maybe<ApiResponse>
  searchHowToDoTask: UserTaskHowToResponse
  sendMessageToBot: SendMessageToBotResponse
  setUserAvailability?: Maybe<SetUserAvailabilityResponse>
  setUserMeeting?: Maybe<UserMeetingData>
  setupFinicityUser?: Maybe<Scalars['Boolean']>
  subscribeGoogleCalendarWebhook?: Maybe<SubscribeGoogleCalendarWebhookResponse>
  summarySessionTitle: MessageChatSession
  toolConnectionSuccess?: Maybe<ApiResponse>
  updateBusinessGoal?: Maybe<Scalars['String']>
  updateBusinessInformation?: Maybe<BusinessProfile>
  updateBusinessProfile?: Maybe<BusinessProfile>
  updateMyProfile?: Maybe<UserProfile>
  updateOwner?: Maybe<ApiResponse>
  updateTaskProgress?: Maybe<TaskResult>
  updateTool?: Maybe<Tool>
  updateUserAvailability?: Maybe<UpdateUserAvailabilityResponse>
  updateUserCalendarEvent?: Maybe<UpdateUserCalendarEventResponse>
  updateUserNotifications?: Maybe<ApiResponse>
  updateUserPassword?: Maybe<Scalars['Boolean']>
  updateUserTask?: Maybe<UserTask>
}

export type MutationAddUserNotificationArgs = {
  input?: InputMaybe<AddUserNotificationInput>
}

export type MutationAddUserToGroupArgs = {
  input: AddUserToGroupInput
}

export type MutationAuthorizeConnectorArgs = {
  authValue: Scalars['String']
}

export type MutationBookmarkToolArgs = {
  input: BookmarkToolInput
}

export type MutationCancelUserCalendarEventArgs = {
  input: CancelUserCalendarEventInput
}

export type MutationConnectFinicityArgs = {
  institutionId?: InputMaybe<Scalars['Int']>
  redirectUrl: Scalars['String']
  tenantId: Scalars['String']
}

export type MutationCreateChatSessionArgs = {
  input: CreateChatSessionInput
}

export type MutationCreateFlowChatSessionArgs = {
  input: CreateFlowChatSessionInput
}

export type MutationCreateToolArgs = {
  input: CreateToolInput
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationCreateUserTaskArgs = {
  input: CreateUserTaskInput
}

export type MutationDeauthenticateConnectorArgs = {
  input?: InputMaybe<DeauthenticateConnectorInput>
}

export type MutationDeleteCalendarEventArgs = {
  input: DeleteCalendarEventInput
}

export type MutationDeleteUserFilesArgs = {
  input: DeleteUserFilesInput
}

export type MutationDeleteUserNotificationsArgs = {
  input?: InputMaybe<DeleteUserNotificationsInput>
}

export type MutationDeleteUserTaskArgs = {
  input: DeleteUserTaskInput
}

export type MutationListCognitoUsersArgs = {
  input: ListCognitoUsersInput
}

export type MutationLogActivityArgs = {
  activityLogInput: ActivityLogInput
}

export type MutationMakeAnnouncementArgs = {
  input: MakeAnnouncementInput
}

export type MutationProcessToolBusinessDataArgs = {
  input: ProcessToolBusinessDataInput
}

export type MutationResendUserInviteArgs = {
  input: ResendUserInviteInput
}

export type MutationSaveAssessmentResponseArgs = {
  input: SaveAssessmentResponseInput
}

export type MutationSaveAttestationArgs = {
  input: AttestationInput
}

export type MutationSaveBusinessGoalArgs = {
  input: SaveBusinessGoalInput
}

export type MutationSavePlayResponseArgs = {
  input: SavePlayResponseInput
}

export type MutationSaveUserFileArgs = {
  input: SaveUserFileInput
}

export type MutationSearchHowToDoTaskArgs = {
  input: SearchHowToDoTaskInput
}

export type MutationSendMessageToBotArgs = {
  input: SendMessageToBotInput
}

export type MutationSetUserAvailabilityArgs = {
  input?: InputMaybe<SetUserAvailabilityInput>
}

export type MutationSetUserMeetingArgs = {
  input: SetUserMeetingInput
}

export type MutationSubscribeGoogleCalendarWebhookArgs = {
  tenantId?: InputMaybe<Scalars['String']>
}

export type MutationSummarySessionTitleArgs = {
  id: Scalars['String']
}

export type MutationToolConnectionSuccessArgs = {
  input?: InputMaybe<ToolConnectionSuccessInput>
}

export type MutationUpdateBusinessGoalArgs = {
  input: UpdateBusinessGoalInput
}

export type MutationUpdateBusinessInformationArgs = {
  profile: UpdateBusinessProfileInput
}

export type MutationUpdateBusinessProfileArgs = {
  input: UpdateBusinessProfileInput
}

export type MutationUpdateMyProfileArgs = {
  input: UpdateMyProfileInput
}

export type MutationUpdateOwnerArgs = {
  input?: InputMaybe<UpdateOwnerInput>
}

export type MutationUpdateTaskProgressArgs = {
  id: Scalars['ID']
  output?: InputMaybe<Scalars['AWSJSON']>
  status?: InputMaybe<TaskResultStatus>
}

export type MutationUpdateToolArgs = {
  input: UpdateToolInput
}

export type MutationUpdateUserAvailabilityArgs = {
  input?: InputMaybe<UpdateUserAvailabilityInput>
}

export type MutationUpdateUserCalendarEventArgs = {
  input: UpdateUserCalendarEventInput
}

export type MutationUpdateUserNotificationsArgs = {
  input?: InputMaybe<UpdateUserNotificationsInput>
}

export type MutationUpdateUserPasswordArgs = {
  input: UpdateUserPasswordInput
}

export type MutationUpdateUserTaskArgs = {
  input: UpdateUserTaskInput
}

export type OAuthConnectionProperty = {
  name: Scalars['String']
  value: Scalars['String']
}

export type OAuthUrlResponse = {
  __typename?: 'OAuthUrlResponse'
  data?: Maybe<Scalars['String']>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type OnboardingFlowState = {
  __typename?: 'OnboardingFlowState'
  currentStepType: OnboardingFlowStepTypes
  flowType?: Maybe<OnboardingFlowType>
  metadata: Scalars['AWSJSON']
  sessionId: Scalars['String']
  status: OnboardingFlowStepStatus
  step: Scalars['String']
  tenantId?: Maybe<Scalars['String']>
  toolProcessingData?: Maybe<Array<Scalars['AWSJSON']>>
  userId: Scalars['String']
}

export enum OnboardingFlowStepStatus {
  Done = 'Done',
  InProgress = 'InProgress',
  Pending = 'Pending',
}

export enum OnboardingFlowStepTypes {
  End = 'End',
  Form = 'Form',
  Message = 'Message',
  Question = 'Question',
  Review = 'Review',
  ToolConnection = 'ToolConnection',
}

export enum OnboardingFlowType {
  AddGoal = 'AddGoal',
  GetToKnowYou = 'GetToKnowYou',
  GoalSetting = 'GoalSetting',
}

export enum OnboardingStatus {
  Complete = 'COMPLETE',
  Intake = 'INTAKE',
  Onboarding = 'ONBOARDING',
}

export enum OptionTypes {
  BusinessClassification = 'businessClassification',
  StrongBusinessAreas = 'strongBusinessAreas',
  SupportAndHelp = 'supportAndHelp',
  TopGoal = 'topGoal',
}

export type Options = {
  __typename?: 'Options'
  dependencies?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  infoText?: Maybe<Scalars['String']>
  label?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['String']>
  toolTipText?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

export enum OrganizationStatus {
  Live = 'LIVE',
  Test = 'TEST',
}

export type OwnerActivitySummary = {
  __typename?: 'OwnerActivitySummary'
  avgMinsPerSession?: Maybe<Scalars['Float']>
  chats?: Maybe<Scalars['Int']>
  meetingsReScheduled?: Maybe<Scalars['Int']>
  meetingsScheduled?: Maybe<Scalars['Int']>
  signInPerWeek?: Maybe<Scalars['Float']>
  timeOnPlatform?: Maybe<Scalars['String']>
  timesSignedIn?: Maybe<Scalars['Int']>
  toolsIntegrated?: Maybe<Scalars['Int']>
}

export enum PaginationDirection {
  Backward = 'BACKWARD',
  Forward = 'FORWARD',
}

export type Payment = {
  __typename?: 'Payment'
  customerId?: Maybe<Scalars['ID']>
  id?: Maybe<Scalars['ID']>
  lineItems?: Maybe<Array<Maybe<LineItem>>>
  totalAmt?: Maybe<Scalars['Float']>
  txnDate?: Maybe<Scalars['String']>
}

export type Play = {
  __typename?: 'Play'
  content?: Maybe<Scalars['AWSJSON']>
  description?: Maybe<Scalars['String']>
  guide?: Maybe<Scalars['AWSJSON']>
  guide1?: Maybe<Scalars['AWSJSON']>
  id: Scalars['ID']
  meta?: Maybe<Scalars['AWSJSON']>
  playTabs?: Maybe<Scalars['AWSJSON']>
  recommendedBy?: Maybe<Scalars['String']>
  setup?: Maybe<Scalars['AWSJSON']>
  title?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  use?: Maybe<Scalars['AWSJSON']>
}

export type PlayBusinessProfileFields = {
  logo?: InputMaybe<Scalars['String']>
}

export type PlayResponse = {
  __typename?: 'PlayResponse'
  businessProfileFields?: Maybe<BusinessProfileFields>
  completedAt?: Maybe<Scalars['AWSDateTime']>
  completedBy?: Maybe<Scalars['String']>
  isCompleted?: Maybe<Scalars['Boolean']>
  playId?: Maybe<Scalars['String']>
  playTabResponse?: Maybe<Array<TabResponse>>
  playType?: Maybe<Scalars['String']>
  recommendations?: Maybe<Array<Scalars['String']>>
  tenantId?: Maybe<Scalars['String']>
}

export type ProcessToolBusinessDataInput = {
  dataType: ToolDataTypes
  toolId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type ProfitAndLoss = {
  __typename?: 'ProfitAndLoss'
  endDate?: Maybe<Scalars['String']>
  expenses?: Maybe<Array<Maybe<AccountingToolEntry>>>
  income?: Maybe<Array<Maybe<AccountingToolEntry>>>
  profit?: Maybe<Array<Maybe<AccountingToolEntry>>>
  startDate?: Maybe<Scalars['String']>
}

export type ProfitAndLossReport = AccountingReport & {
  __typename?: 'ProfitAndLossReport'
  dateRange?: Maybe<DateRanges>
  endDate?: Maybe<Scalars['String']>
  growth?: Maybe<Scalars['Float']>
  profitAndLossNetIncome?: Maybe<Scalars['Float']>
  source?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  cancelOutlookEvent?: Maybe<ApiResponse>
  deleteGoogleCalendarEvent?: Maybe<ApiResponse>
  getAccountingCharts?: Maybe<AccountingChartResponse>
  getActivityLogs?: Maybe<ApiResponse>
  getAdvisorDashboard?: Maybe<ApiResponse>
  getAdvisorProfile?: Maybe<AdvisorProfile>
  getAdvisors?: Maybe<ApiResponse>
  getAllToolCategories: GetAllToolCategoriesResponse
  getAllTools?: Maybe<GetAllToolsResponse>
  getAssessmentPlayListing?: Maybe<ApiResponse>
  getAssessmentResponseByQuestion?: Maybe<UserResponse>
  getAssessments?: Maybe<Array<Maybe<Assessment>>>
  getAttestationReports?: Maybe<GetAttestationReportsResponse>
  getAttestationSummary?: Maybe<AttestationSummary>
  getBoAdvisors?: Maybe<BoAdvisorsResponse>
  getBusinessGoal?: Maybe<BusinessGoal>
  getBusinessGoals?: Maybe<Array<BusinessGoal>>
  getBusinessIndustryDetails?: Maybe<GetBusinessIndustryDetailsResponse>
  getBusinessProfile?: Maybe<BusinessProfile>
  getBusinessSummary?: Maybe<BusinessSummaryResponse>
  getBusinessSupportOrganization?: Maybe<BusinessSupportOrganization>
  getBusinesses?: Maybe<ApiResponse>
  getCalendlyUser?: Maybe<CalendlyUserResponse>
  getChartData?: Maybe<ApiResponse>
  getChatSessions?: Maybe<Array<MessageChatSession>>
  getClients?: Maybe<GetClientsResponse>
  getClientsSummary?: Maybe<ApiResponse>
  getDashboardListing?: Maybe<ApiResponse>
  getFile?: Maybe<ApiResponse>
  getGoalDetails?: Maybe<Goal>
  getMeetingTypes?: Maybe<GetMeetingTypeResponse>
  getMessagesFromBot: MessageToBotResponse
  getMyProfile: UserProfile
  getOAuthUrl?: Maybe<OAuthUrlResponse>
  getOnboardingFlowState?: Maybe<OnboardingFlowState>
  getOptions?: Maybe<GetOptionsResponse>
  getOwnerActivityDetails?: Maybe<OwnerActivitySummary>
  getOwnerBusinessData?: Maybe<ApiResponse>
  getPendingJobs: Array<JobDto>
  getPlay?: Maybe<ApiResponse>
  getPlayResponse?: Maybe<Array<PlayResponse>>
  getPlays?: Maybe<GetPlaysResponse>
  getSignedUrl?: Maybe<SignedUrlResponse>
  getTackleMeetings?: Maybe<GetTackleMeetingResponse>
  getTenants?: Maybe<GetTenantsResponse>
  getToolById?: Maybe<Tool>
  getTools?: Maybe<GetToolsResponse>
  getToolsByDataTypes: Array<Tool>
  getToolsByType?: Maybe<GetToolsByTypeResponse>
  getTopActiveClients?: Maybe<GetTopActiveClientsResponse>
  getUserAssessmentResponse?: Maybe<UserAssessmentResponse>
  getUserAvailability?: Maybe<GetUserAvailabilityResponse>
  getUserAvailabilityHours?: Maybe<GetUserAvailabilityHoursResponse>
  getUserCalendarEvents?: Maybe<CalendarEventResponse>
  getUserConnectors?: Maybe<CyclrConnectorResponse>
  getUserFiles?: Maybe<ApiResponse>
  getUserGoals?: Maybe<Array<Maybe<Goal>>>
  getUserIntegrations?: Maybe<ApiResponse>
  getUserMeeting?: Maybe<ApiResponse>
  getUserMeetingById?: Maybe<GetUserMeetingByIdResponse>
  getUserNotifications?: Maybe<GetUserNotificationsResponse>
  getUserRecommendations?: Maybe<UserRecommendationsResponse>
  getUserTask?: Maybe<UserTask>
  getUserTasks?: Maybe<Array<UserTask>>
  getUserTools?: Maybe<GetUserToolsResponse>
  healthCheck?: Maybe<Scalars['String']>
  listAssessments?: Maybe<ListAssessmentResponse>
  retrieveBusinessInformation?: Maybe<BusinessProfile>
  searchBusinessInformation?: Maybe<BusinessProfile>
  searchIndustryReport?: Maybe<IndustryReport>
  searchTools?: Maybe<SearchToolsResponse>
  subscribeCalendlyWebhook?: Maybe<ApiResponse>
  updateGoogleCalendarEvent?: Maybe<ApiResponse>
}

export type QueryCancelOutlookEventArgs = {
  input?: InputMaybe<CancelOutlookEventInput>
}

export type QueryDeleteGoogleCalendarEventArgs = {
  input?: InputMaybe<DeleteGoogleCalendarEventInput>
}

export type QueryGetAccountingChartsArgs = {
  input: QueryAccountingReportInput
}

export type QueryGetActivityLogsArgs = {
  tenantId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetAdvisorDashboardArgs = {
  filterType?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetAdvisorProfileArgs = {
  input: GetAdvisorProfileInput
}

export type QueryGetAdvisorsArgs = {
  getAdvisorsInput?: InputMaybe<GetAdvisorsInput>
}

export type QueryGetAllToolsArgs = {
  tenantId?: InputMaybe<Scalars['String']>
}

export type QueryGetAssessmentPlayListingArgs = {
  tenantId: Scalars['String']
}

export type QueryGetAssessmentResponseByQuestionArgs = {
  question?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetAssessmentsArgs = {
  type?: InputMaybe<Scalars['String']>
}

export type QueryGetAttestationReportsArgs = {
  input: GetAttestationReportsInput
}

export type QueryGetAttestationSummaryArgs = {
  input?: InputMaybe<GetAttestationSummaryInput>
}

export type QueryGetBoAdvisorsArgs = {
  tenantId?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetBusinessGoalArgs = {
  input: GetBusinessGoalInput
}

export type QueryGetBusinessGoalsArgs = {
  input: GetBusinessGoalsInput
}

export type QueryGetBusinessIndustryDetailsArgs = {
  input: GetBusinessIndustryDetailsInput
}

export type QueryGetBusinessProfileArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryGetBusinessSummaryArgs = {
  input: GetBusinessSummaryInput
}

export type QueryGetBusinessSupportOrganizationArgs = {
  input: GetBusinessSupportOrganizationInput
}

export type QueryGetBusinessesArgs = {
  getBusinessesInput: GetBusinessesInput
}

export type QueryGetChartDataArgs = {
  input: GetChartDataInput
}

export type QueryGetClientsArgs = {
  input?: InputMaybe<GetClientsInput>
}

export type QueryGetClientsSummaryArgs = {
  tenantId?: InputMaybe<Scalars['String']>
}

export type QueryGetDashboardListingArgs = {
  category?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}

export type QueryGetFileArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type QueryGetGoalDetailsArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type QueryGetMeetingTypesArgs = {
  tenantId: Scalars['String']
}

export type QueryGetMessagesFromBotArgs = {
  cursor?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  sessionId: Scalars['String']
}

export type QueryGetMyProfileArgs = {
  tenantId?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetOAuthUrlArgs = {
  input: GetOAuthUrlInput
}

export type QueryGetOnboardingFlowStateArgs = {
  input: GetOnboardingFlowStateInput
}

export type QueryGetOptionsArgs = {
  optionTypes: Array<Scalars['String']>
}

export type QueryGetOwnerActivityDetailsArgs = {
  ownerId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}

export type QueryGetOwnerBusinessDataArgs = {
  input?: InputMaybe<GetOwnerBusinessDataInput>
}

export type QueryGetPlayArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type QueryGetPlayResponseArgs = {
  input: GetPlayResponseInput
}

export type QueryGetPlaysArgs = {
  input?: InputMaybe<GetPlaysInput>
}

export type QueryGetSignedUrlArgs = {
  contentType?: InputMaybe<Scalars['String']>
  fileName?: InputMaybe<Scalars['String']>
}

export type QueryGetTackleMeetingsArgs = {
  input?: InputMaybe<GetTackleMeetingsInput>
}

export type QueryGetToolByIdArgs = {
  toolId: Scalars['String']
}

export type QueryGetToolsByDataTypesArgs = {
  input: GetToolByDataTypesInput
}

export type QueryGetToolsByTypeArgs = {
  input: GetToolsByTypeInput
}

export type QueryGetTopActiveClientsArgs = {
  tenantId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserAssessmentResponseArgs = {
  type?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserAvailabilityArgs = {
  input: GetUserAvailabilityInput
}

export type QueryGetUserAvailabilityHoursArgs = {
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserCalendarEventsArgs = {
  calendarName?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
  timeMax?: InputMaybe<Scalars['String']>
  timeMin?: InputMaybe<Scalars['String']>
}

export type QueryGetUserConnectorsArgs = {
  connectorName?: InputMaybe<Scalars['String']>
}

export type QueryGetUserFilesArgs = {
  tenantId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserGoalsArgs = {
  tenantId?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserIntegrationsArgs = {
  tenantId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserMeetingArgs = {
  advisorId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserMeetingByIdArgs = {
  input: GetUserMeetingByIdInput
}

export type QueryGetUserNotificationsArgs = {
  input?: InputMaybe<GetUserNotificationsInput>
}

export type QueryGetUserRecommendationsArgs = {
  input: GetUserRecommendationsInput
}

export type QueryGetUserTaskArgs = {
  input: GetUserTaskInput
}

export type QueryGetUserTasksArgs = {
  input: GetUserTasksInput
}

export type QueryGetUserToolsArgs = {
  tenantId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type QueryListAssessmentsArgs = {
  tenantId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type QueryRetrieveBusinessInformationArgs = {
  businessId: Scalars['String']
}

export type QuerySearchBusinessInformationArgs = {
  businessId: Scalars['String']
}

export type QuerySearchIndustryReportArgs = {
  input: SearchIndustryReportInput
}

export type QuerySearchToolsArgs = {
  input: SearchToolsInput
}

export type QuerySubscribeCalendlyWebhookArgs = {
  email?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}

export type QueryUpdateGoogleCalendarEventArgs = {
  input?: InputMaybe<UpdateGoogleCalendarEventInput>
}

export type QueryAccountingReportInput = {
  chartType: AccountingCharts
  dateRange?: InputMaybe<DateRanges>
  endDate?: InputMaybe<Scalars['String']>
  source?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
  year?: InputMaybe<Scalars['Int']>
}

export type Recommendations = {
  __typename?: 'Recommendations'
  assessmentRecommendedAt?: Maybe<Scalars['AWSDateTime']>
  assessments?: Maybe<Array<Scalars['String']>>
  playRecommendedAt?: Maybe<Scalars['AWSDateTime']>
  plays?: Maybe<Array<Scalars['String']>>
}

export type RecommendationsInput = {
  assessmentRecommendedAt?: InputMaybe<Scalars['AWSDateTime']>
  assessments?: InputMaybe<Array<Scalars['String']>>
  playRecommendedAt?: InputMaybe<Scalars['AWSDateTime']>
  plays?: InputMaybe<Array<Scalars['String']>>
}

export enum RelativeTimeRange {
  LastNDays = 'LAST_N_DAYS',
  LastNHours = 'LAST_N_HOURS',
  LastNMonths = 'LAST_N_MONTHS',
  LastNYears = 'LAST_N_YEARS',
}

export type Report = {
  __typename?: 'Report'
  columns?: Maybe<Array<Maybe<ReportColumn>>>
  endDate?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  rows?: Maybe<Array<Maybe<ReportRow>>>
  startDate?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type ReportCell = {
  __typename?: 'ReportCell'
  id?: Maybe<Scalars['ID']>
  value?: Maybe<Scalars['String']>
}

export type ReportColumn = {
  __typename?: 'ReportColumn'
  id?: Maybe<Scalars['ID']>
  label?: Maybe<Scalars['String']>
}

export type ReportRow = {
  __typename?: 'ReportRow'
  cells?: Maybe<Array<Maybe<ReportCell>>>
  id?: Maybe<Scalars['ID']>
}

export type ResendUserInviteInput = {
  email: Scalars['String']
  userId: Scalars['String']
}

export type SaveAssessmentResponseInput = {
  assessmentResponse: AssessmentResponseInput
  category?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['AWSDateTime']>
  currentStage?: InputMaybe<Scalars['ID']>
  isCompleted?: InputMaybe<Scalars['Boolean']>
  playStage?: InputMaybe<Scalars['AWSJSON']>
  tenantId: Scalars['String']
  type: Scalars['String']
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>
  userId: Scalars['ID']
}

export type SaveBusinessGoalInput = {
  category?: InputMaybe<BusinessGoalCategory>
  description?: InputMaybe<Scalars['String']>
  expectedEndDate?: InputMaybe<Scalars['String']>
  goalId: Scalars['ID']
  goalPriority?: InputMaybe<BusinessGoalPriority>
  goalTimeFrame?: InputMaybe<BusinessGoalTimeFrame>
  logo?: InputMaybe<Scalars['String']>
  metrics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name: Scalars['String']
  plan?: InputMaybe<BusinessGoalPlanInput>
  progress?: InputMaybe<Scalars['Int']>
  startDate?: InputMaybe<Scalars['String']>
  status?: InputMaybe<UserTaskItemStatus>
  tenantId: Scalars['ID']
  userId: Scalars['ID']
}

export type SavePlayResponseInput = {
  businessProfileFields?: InputMaybe<PlayBusinessProfileFields>
  isCompleted?: InputMaybe<Scalars['Boolean']>
  playId: Scalars['String']
  playType?: InputMaybe<Scalars['String']>
  recommendations?: InputMaybe<Array<Scalars['String']>>
  stepData?: InputMaybe<StepDataInput>
  stepDataIndex: Scalars['Int']
  stepIndex: Scalars['Int']
  tabId: Scalars['String']
  tenantId: Scalars['String']
}

export type SaveUserFileInput = {
  files: Array<FileDetails>
  sendNotification?: InputMaybe<Scalars['Boolean']>
}

export type SearchBusinessInformationInput = {
  EIN?: InputMaybe<Scalars['String']>
  businessId?: InputMaybe<Scalars['String']>
  businessName: Scalars['String']
  city: Scalars['String']
  state: Scalars['String']
}

export type SearchHowToDoTaskInput = {
  description?: InputMaybe<Scalars['String']>
  resources?: InputMaybe<Scalars['String']>
  taskName: Scalars['ID']
  tool?: InputMaybe<Scalars['String']>
}

export type SearchIndustryReportInput = {
  keyword: Scalars['String']
}

export type SearchToolsInput = {
  categories?: InputMaybe<Array<Scalars['String']>>
  featured?: InputMaybe<Scalars['Boolean']>
  freeFormSearchQuery?: InputMaybe<Scalars['String']>
  inUserToolKit?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
}

export type SearchToolsResponse = {
  __typename?: 'SearchToolsResponse'
  data: Array<Tool>
  numberOfPages?: Maybe<Scalars['Int']>
}

export type SendMessageToBotInput = {
  sessionId?: InputMaybe<Scalars['String']>
  userMessage: Scalars['String']
  userMessageType?: InputMaybe<ChatBotMessageTypes>
}

export type SendMessageToBotResponse = {
  __typename?: 'SendMessageToBotResponse'
  flowType?: Maybe<Scalars['String']>
  isFinished?: Maybe<Scalars['Boolean']>
  nextFlow?: Maybe<Scalars['String']>
  response: Scalars['String']
}

export type SetUserAvailabilityInput = {
  availabilityHours?: InputMaybe<AvailabilityHoursInput>
  tenantId?: InputMaybe<Scalars['String']>
}

export type SetUserAvailabilityObj = {
  __typename?: 'SetUserAvailabilityObj'
  availabilityHours?: Maybe<AvailabilityHoursObj>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type SetUserAvailabilityResponse = {
  __typename?: 'SetUserAvailabilityResponse'
  data?: Maybe<SetUserAvailabilityObj>
  error?: Maybe<Scalars['String']>
}

export type SetUserMeetingInput = {
  advisorName?: InputMaybe<Scalars['String']>
  attendees?: InputMaybe<Array<Scalars['String']>>
  description?: InputMaybe<Scalars['String']>
  endTime: Scalars['String']
  ignoreConflicts?: InputMaybe<Scalars['Boolean']>
  location?: InputMaybe<Scalars['String']>
  meetingLocationTool: Scalars['String']
  organizerName?: InputMaybe<Scalars['String']>
  participantId: Scalars['String']
  participantName?: InputMaybe<Scalars['String']>
  role: Array<Scalars['String']>
  startTime: Scalars['String']
  tenantId: Scalars['String']
  timezone: Scalars['String']
  title: Scalars['String']
  videoLinkDescription?: InputMaybe<Scalars['String']>
}

export type SignedUrlResponse = {
  __typename?: 'SignedUrlResponse'
  data?: Maybe<Scalars['AWSJSON']>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type SlotInput = {
  endTime?: InputMaybe<Scalars['String']>
  startTime?: InputMaybe<Scalars['String']>
}

export type SlotObj = {
  __typename?: 'SlotObj'
  isAvailable?: Maybe<Scalars['Boolean']>
  slotEndTime?: Maybe<Scalars['String']>
  slotStartTime?: Maybe<Scalars['String']>
}

export type SlotOutput = {
  __typename?: 'SlotOutput'
  endTime?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
}

export enum SmsSubscriptionStatus {
  Closed = 'CLOSED',
  Subscribed = 'SUBSCRIBED',
  UnSubscribed = 'UN_SUBSCRIBED',
}

export enum SortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export type StepBusinessProfileFields = {
  __typename?: 'StepBusinessProfileFields'
  logo?: Maybe<FileStructure>
}

export type StepBusinessProfileFieldsInput = {
  logo?: InputMaybe<FileStructureInput>
}

export type StepData = {
  __typename?: 'StepData'
  businessProfileFields?: Maybe<StepBusinessProfileFields>
  completedAt?: Maybe<Scalars['AWSDateTime']>
  id?: Maybe<Scalars['String']>
  isCompleted?: Maybe<Scalars['Boolean']>
  isMeetingScheduled?: Maybe<Scalars['Boolean']>
  label?: Maybe<Array<Scalars['String']>>
  recommendations?: Maybe<Array<Scalars['String']>>
  value?: Maybe<Array<Scalars['String']>>
}

export type StepDataInput = {
  businessProfileFields?: InputMaybe<StepBusinessProfileFieldsInput>
  completedAt?: InputMaybe<Scalars['AWSDateTime']>
  id?: InputMaybe<Scalars['String']>
  isCompleted?: InputMaybe<Scalars['Boolean']>
  isMeetingScheduled?: InputMaybe<Scalars['Boolean']>
  label?: InputMaybe<Array<Scalars['String']>>
  recommendations?: InputMaybe<Array<Scalars['String']>>
  value?: InputMaybe<Array<Scalars['String']>>
}

export type StepResponse = {
  __typename?: 'StepResponse'
  completedAt?: Maybe<Scalars['AWSDateTime']>
  id?: Maybe<Scalars['String']>
  isCompleted?: Maybe<Scalars['Boolean']>
  stepData?: Maybe<Array<StepData>>
  stepTitle?: Maybe<Scalars['String']>
}

export type SubscribeGoogleCalendarWebhookResponse = {
  __typename?: 'SubscribeGoogleCalendarWebhookResponse'
  data?: Maybe<Scalars['String']>
  error?: Maybe<Scalars['String']>
}

export type Subscription = {
  __typename?: 'Subscription'
  onTaskProgressUpdated?: Maybe<TaskResult>
}

export type SubscriptionOnTaskProgressUpdatedArgs = {
  id: Scalars['ID']
}

export type SystemSetting = {
  __typename?: 'SystemSetting'
  category?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type TabResponse = {
  __typename?: 'TabResponse'
  completedAt?: Maybe<Scalars['AWSDateTime']>
  id?: Maybe<Scalars['String']>
  isCompleted?: Maybe<Scalars['Boolean']>
  stepResponse?: Maybe<Array<StepResponse>>
  tabName?: Maybe<Scalars['String']>
}

export type Task = {
  __typename?: 'Task'
  assignedByUserId?: Maybe<Scalars['String']>
  assignedToUserId?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  completedAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  playType?: Maybe<Scalars['String']>
  prerequisites?: Maybe<TaskPrerequisites>
  rank?: Maybe<Scalars['String']>
  recommendedBy?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type TaskResult = {
  __typename?: 'TaskResult'
  id?: Maybe<Scalars['ID']>
  payload?: Maybe<Scalars['AWSJSON']>
  status?: Maybe<Scalars['ID']>
}

export enum TaskResultStatus {
  Complete = 'COMPLETE',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

export enum TaskStatus {
  Completed = 'COMPLETED',
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED',
}

export enum TaskType {
  Assessment = 'ASSESSMENT',
  Play = 'PLAY',
}

export type Tenant = {
  __typename?: 'Tenant'
  adminId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type Term = {
  __typename?: 'Term'
  replacement: Scalars['String']
  term: Scalars['String']
}

export type TimePeriod = {
  day?: InputMaybe<Scalars['Int']>
  month: Scalars['Int']
  year: Scalars['Int']
}

export type TimeRangeInput = {
  endDate?: InputMaybe<Scalars['AWSDateTime']>
  n?: InputMaybe<Scalars['Int']>
  rangeType?: InputMaybe<TimeRangeType>
  relative?: InputMaybe<RelativeTimeRange>
  startDate?: InputMaybe<Scalars['AWSDateTime']>
}

export enum TimeRangeType {
  Absolute = 'ABSOLUTE',
  Relative = 'RELATIVE',
}

export type TimeRangeValueInput = {
  asOfDate: Scalars['AWSDate']
}

export type TimeSeriesResponse = {
  __typename?: 'TimeSeriesResponse'
  labels?: Maybe<Array<Scalars['String']>>
  values?: Maybe<Array<Scalars['Float']>>
}

export type TimeSlotsInput = {
  label?: InputMaybe<Scalars['String']>
  slots?: InputMaybe<Array<InputMaybe<SlotInput>>>
  value?: InputMaybe<Scalars['String']>
}

export type TimeSlotsObj = {
  __typename?: 'TimeSlotsObj'
  label?: Maybe<Scalars['String']>
  slots?: Maybe<Array<Maybe<SlotOutput>>>
  value?: Maybe<Scalars['String']>
}

export type Tool = {
  __typename?: 'Tool'
  authType?: Maybe<ToolAuthType>
  authorizationQueryParameters?: Maybe<Array<KeyValuePair>>
  authorizationUri?: Maybe<Scalars['String']>
  categories?: Maybe<Array<ToolCategory>>
  category?: Maybe<Scalars['String']>
  clientId?: Maybe<Scalars['String']>
  clientSecret?: Maybe<Scalars['String']>
  connectionFormImageUrl?: Maybe<Scalars['String']>
  connectionStatus?: Maybe<Scalars['String']>
  connectorName?: Maybe<Scalars['String']>
  cyclrToolId?: Maybe<Scalars['String']>
  dataTypes?: Maybe<Array<ToolDataTypes>>
  description?: Maybe<Scalars['String']>
  diagnosticSupported?: Maybe<Scalars['Boolean']>
  featured?: Maybe<Scalars['Boolean']>
  group?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  imageUrl?: Maybe<Scalars['String']>
  isBookmarked?: Maybe<Scalars['Boolean']>
  isConnected?: Maybe<Scalars['Boolean']>
  isRecommended?: Maybe<Scalars['Boolean']>
  isSelected?: Maybe<Scalars['Boolean']>
  keyWords?: Maybe<Array<Scalars['String']>>
  longDescription?: Maybe<Scalars['String']>
  oAuthUrl?: Maybe<Scalars['String']>
  options?: Maybe<ToolConnectionOptions>
  pricingPageUrl?: Maybe<Scalars['String']>
  recommendedBy?: Maybe<Scalars['String']>
  relatedPlays?: Maybe<Array<Maybe<Scalars['String']>>>
  scopes?: Maybe<Array<Scalars['String']>>
  subCategory?: Maybe<Scalars['String']>
  supportLevel?: Maybe<ToolSupportLevel>
  tags?: Maybe<Array<Scalars['String']>>
  tokenUrl?: Maybe<Scalars['String']>
  toolDisplayName?: Maybe<Scalars['String']>
  toolHelpText?: Maybe<Scalars['String']>
  toolName?: Maybe<Scalars['String']>
  toolType?: Maybe<ToolType>
  toolUrl?: Maybe<Scalars['String']>
  userToolsImageUrl?: Maybe<Scalars['String']>
  visible?: Maybe<Scalars['Boolean']>
}

export enum ToolAuthType {
  ApiKey = 'API_KEY',
  Oauth = 'OAUTH',
  UsernameAndPassword = 'USERNAME_AND_PASSWORD',
}

export type ToolCategory = {
  __typename?: 'ToolCategory'
  category: Scalars['String']
  subCategory: Scalars['String']
}

export type ToolCategoryInput = {
  category: Scalars['String']
  subCategory: Scalars['String']
}

export type ToolConnectionForm = {
  __typename?: 'ToolConnectionForm'
  description: Scalars['String']
  inputProperties: Array<ToolConnectionFormInputProperty>
}

export type ToolConnectionFormInput = {
  description: Scalars['String']
  inputProperties: Array<ToolConnectionFormInputPropertyInput>
}

export type ToolConnectionFormInputProperty = {
  __typename?: 'ToolConnectionFormInputProperty'
  displayName: Scalars['String']
  name: Scalars['String']
  placeholder?: Maybe<Scalars['String']>
  type: Scalars['String']
  validationRules: Array<Scalars['String']>
}

export type ToolConnectionFormInputPropertyInput = {
  displayName: Scalars['String']
  name: Scalars['String']
  placeholder?: InputMaybe<Scalars['String']>
  type: Scalars['String']
  validationRules: Array<Scalars['String']>
}

export type ToolConnectionOptions = {
  __typename?: 'ToolConnectionOptions'
  connectionForm?: Maybe<ToolConnectionForm>
}

export type ToolConnectionOptionsInput = {
  connectionForm?: InputMaybe<ToolConnectionFormInput>
}

export enum ToolConnectionStatus {
  Connected = 'CONNECTED',
  ConnectionError = 'CONNECTION_ERROR',
  Disconnected = 'DISCONNECTED',
  Reconnected = 'RECONNECTED',
}

export type ToolConnectionSuccessInput = {
  connectorName: Scalars['String']
  playId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}

export enum ToolDataTypes {
  Bookkeeping = 'Bookkeeping',
  Marketing = 'Marketing',
  Sales = 'Sales',
}

export type ToolImageFileInput = {
  base64Data: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  type: Scalars['String']
}

export enum ToolSupportLevel {
  Connect = 'CONNECT',
  DataRetrieval = 'DATA_RETRIEVAL',
  Open = 'OPEN',
}

export enum ToolType {
  Calendar = 'CALENDAR',
}

export type TopActiveClient = {
  __typename?: 'TopActiveClient'
  businessName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  industry?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  logo?: Maybe<UserFile>
  score?: Maybe<Scalars['Float']>
  tenantId?: Maybe<Scalars['String']>
  topGoal?: Maybe<Scalars['String']>
  totalTime?: Maybe<Scalars['Float']>
}

export type UpdateBusinessGoalInput = {
  expectedEndDate?: InputMaybe<Scalars['String']>
  goalId: Scalars['ID']
  goalPriority?: InputMaybe<BusinessGoalPriority>
  progress?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<UserTaskItemStatus>
  tenantId: Scalars['ID']
  userId?: InputMaybe<Scalars['ID']>
}

export type UpdateBusinessGoalPlanInput = {
  goalId?: InputMaybe<Scalars['ID']>
  milestones?: InputMaybe<Array<BusinessGoalMilestoneInput>>
  planId: Scalars['ID']
  state?: InputMaybe<BusinessGoalPlanState>
}

export type UpdateBusinessProfileInput = {
  EIN?: InputMaybe<Scalars['String']>
  POS?: InputMaybe<Scalars['String']>
  POSOptionId?: InputMaybe<Scalars['String']>
  accountingMethod?: InputMaybe<Scalars['String']>
  accountingMethodOptionId?: InputMaybe<Scalars['String']>
  additionalContext?: InputMaybe<Scalars['AWSJSON']>
  additionalInfo?: InputMaybe<Scalars['String']>
  address?: InputMaybe<AddressInput>
  avatar?: InputMaybe<Scalars['String']>
  bookKeepingTool?: InputMaybe<Scalars['String']>
  bookKeepingToolOptionId?: InputMaybe<Scalars['String']>
  budget?: InputMaybe<Scalars['Float']>
  businessAddress?: InputMaybe<BusinessAddressInput>
  businessBankAccount?: InputMaybe<Scalars['Boolean']>
  businessClassification?: InputMaybe<Scalars['String']>
  businessClassificationOptionId?: InputMaybe<Scalars['String']>
  businessDescription?: InputMaybe<Scalars['String']>
  businessEmail?: InputMaybe<Scalars['String']>
  businessLocations?: InputMaybe<Array<Scalars['String']>>
  businessLocationsOptionIds?: InputMaybe<Array<Scalars['String']>>
  businessName?: InputMaybe<Scalars['String']>
  businessNature?: InputMaybe<Scalars['String']>
  businessNatureOptionId?: InputMaybe<Scalars['String']>
  businessPlan?: InputMaybe<Scalars['String']>
  businessStage?: InputMaybe<Scalars['String']>
  businessStageOptionId?: InputMaybe<Scalars['String']>
  businessVision?: InputMaybe<Scalars['String']>
  challenges?: InputMaybe<Scalars['String']>
  companyEmployees?: InputMaybe<Scalars['String']>
  contractors?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['AWSDateTime']>
  dba?: InputMaybe<Scalars['String']>
  dbaNames?: InputMaybe<Array<Scalars['String']>>
  debtFinancing?: InputMaybe<Scalars['String']>
  domainName?: InputMaybe<Scalars['String']>
  education?: InputMaybe<Scalars['String']>
  employeeBenefit?: InputMaybe<Scalars['String']>
  exitPlan?: InputMaybe<Scalars['String']>
  exitPlanOptionId?: InputMaybe<Scalars['String']>
  expenses?: InputMaybe<Scalars['Float']>
  financialGrowth?: InputMaybe<Scalars['String']>
  foodSubIndustry?: InputMaybe<Array<Scalars['String']>>
  foodSubIndustryOptionIds?: InputMaybe<Array<Scalars['String']>>
  fullTimeEmployees?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Scalars['String']>
  googleBusinessProfilePlays?: InputMaybe<Array<Scalars['String']>>
  googleBusinessProfilePlaysOptionIds?: InputMaybe<Array<Scalars['String']>>
  googleWorkspacePlays?: InputMaybe<Array<Scalars['String']>>
  googleWorkspacePlaysOptionIds?: InputMaybe<Array<Scalars['String']>>
  grossRevenueGoalForThisYear?: InputMaybe<Scalars['String']>
  hasAvatar?: InputMaybe<Scalars['Boolean']>
  hasBookKeepingTool?: InputMaybe<Scalars['String']>
  hasBookKeepingToolOptionId?: InputMaybe<Scalars['String']>
  hasBudget?: InputMaybe<Scalars['String']>
  hasBudgetOptionId?: InputMaybe<Scalars['String']>
  hasBusinessEmail?: InputMaybe<Scalars['String']>
  hasBusinessEmailOptionId?: InputMaybe<Scalars['String']>
  hasBusinessEmployees?: InputMaybe<Scalars['String']>
  hasBusinessEmployeesOptionId?: InputMaybe<Scalars['String']>
  hasBusinessPhoneNumber?: InputMaybe<Scalars['String']>
  hasBusinessPhoneNumberOptionId?: InputMaybe<Scalars['String']>
  hasDomainName?: InputMaybe<Scalars['String']>
  hasDomainNameOptionId?: InputMaybe<Scalars['String']>
  hasEsignatureTool?: InputMaybe<Scalars['String']>
  hasEsignatureToolOptionId?: InputMaybe<Scalars['String']>
  hasGoogleBusinessProfile?: InputMaybe<Scalars['String']>
  hasGoogleBusinessProfileOptionId?: InputMaybe<Scalars['String']>
  hasGoogleChrome?: InputMaybe<Scalars['String']>
  hasGoogleChromeOptionId?: InputMaybe<Scalars['String']>
  hasGoogleWorkspace?: InputMaybe<Scalars['String']>
  hasGoogleWorkspaceOptionId?: InputMaybe<Scalars['String']>
  hasLogo?: InputMaybe<Scalars['String']>
  hasLogoOptionId?: InputMaybe<Scalars['String']>
  hasLogoTrademarked?: InputMaybe<Scalars['String']>
  hasLogoTrademarkedOptionId?: InputMaybe<Scalars['String']>
  hasMoreLocations?: InputMaybe<Scalars['String']>
  hasMoreLocationsOptionId?: InputMaybe<Scalars['String']>
  hasPOS?: InputMaybe<Scalars['String']>
  hasPOSOptionId?: InputMaybe<Scalars['String']>
  hasPaymentProcessor?: InputMaybe<Scalars['String']>
  hasPaymentProcessorOptionId?: InputMaybe<Scalars['String']>
  hasPricingStrategies?: InputMaybe<Scalars['String']>
  hasPricingStrategiesOptionId?: InputMaybe<Scalars['String']>
  hasQuickBooks?: InputMaybe<Scalars['String']>
  hasQuickBooksOptionId?: InputMaybe<Scalars['String']>
  haveStartedGeneratingSales?: InputMaybe<Scalars['Boolean']>
  hoursSpent?: InputMaybe<Scalars['String']>
  hoursSpentOptionId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  isOnboarded?: InputMaybe<Scalars['Boolean']>
  isUserCreation?: InputMaybe<Scalars['Boolean']>
  jobsCreated?: InputMaybe<Scalars['String']>
  lastUpdatedBusinessPlan?: InputMaybe<Scalars['AWSDateTime']>
  lastYearRevenue?: InputMaybe<Scalars['String']>
  launchDate?: InputMaybe<Scalars['AWSDateTime']>
  learnPricingStrategies?: InputMaybe<Scalars['String']>
  learnPricingStrategiesOptionId?: InputMaybe<Scalars['String']>
  loginFrequency?: InputMaybe<Scalars['String']>
  logo?: InputMaybe<Scalars['String']>
  minorityOwned?: InputMaybe<Scalars['String']>
  month?: InputMaybe<Scalars['String']>
  motivation?: InputMaybe<Scalars['String']>
  naicsCode?: InputMaybe<Scalars['String']>
  newCustomer?: InputMaybe<Scalars['String']>
  noOfBusinessStarted?: InputMaybe<Scalars['String']>
  numberOfBusinessBankAccounts?: InputMaybe<Scalars['String']>
  onboardedAt?: InputMaybe<Scalars['String']>
  otherExitPlan?: InputMaybe<Scalars['String']>
  otherGoals?: InputMaybe<Array<Scalars['String']>>
  otherGoalsOptionIds?: InputMaybe<Array<Scalars['String']>>
  otherRegisteredBusinessStructure?: InputMaybe<Scalars['String']>
  otherWhereDoYouDoBusiness?: InputMaybe<Scalars['String']>
  partTimeEmployees?: InputMaybe<Scalars['String']>
  paymentAcceptance?: InputMaybe<Scalars['String']>
  paymentAcceptanceOptionId?: InputMaybe<Scalars['String']>
  paymentAcceptancePlan?: InputMaybe<Scalars['String']>
  paymentAcceptancePlanOptionId?: InputMaybe<Scalars['String']>
  paymentApps?: InputMaybe<Array<Scalars['String']>>
  paymentAppsOptionIds?: InputMaybe<Array<Scalars['String']>>
  paymentProcessor?: InputMaybe<Scalars['String']>
  paymentProcessorOptionId?: InputMaybe<Scalars['String']>
  paymentTypes?: InputMaybe<Array<Scalars['String']>>
  paymentTypesOptionIds?: InputMaybe<Array<Scalars['String']>>
  personalGoals?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  physicalLocation?: InputMaybe<Scalars['String']>
  pricingStrategies?: InputMaybe<Array<Scalars['String']>>
  pricingStrategiesOptionIds?: InputMaybe<Array<Scalars['String']>>
  productSale?: InputMaybe<Scalars['String']>
  productsAndServices?: InputMaybe<Array<Scalars['String']>>
  productsAndServicesOptionIds?: InputMaybe<Array<Scalars['String']>>
  quickBookPlays?: InputMaybe<Array<Scalars['String']>>
  quickBookPlaysOptionIds?: InputMaybe<Array<Scalars['String']>>
  race?: InputMaybe<Scalars['String']>
  registerBusinessStructure?: InputMaybe<Scalars['String']>
  registerBusinessStructureOptionId?: InputMaybe<Scalars['String']>
  revenue?: InputMaybe<Scalars['String']>
  revenueStatus?: InputMaybe<Scalars['String']>
  revenueStatusOptionId?: InputMaybe<Scalars['String']>
  saleGoal?: InputMaybe<Scalars['String']>
  sbaCertified?: InputMaybe<Scalars['String']>
  seasonalityDetails?: InputMaybe<Scalars['String']>
  serviceSale?: InputMaybe<Scalars['String']>
  servicesSubIndustry?: InputMaybe<Scalars['String']>
  servicesSubIndustryOptionId?: InputMaybe<Scalars['String']>
  squarePlays?: InputMaybe<Array<Scalars['String']>>
  squarePlaysOptionIds?: InputMaybe<Array<Scalars['String']>>
  stripePlays?: InputMaybe<Array<Scalars['String']>>
  stripePlaysOptionIds?: InputMaybe<Array<Scalars['String']>>
  strongBusinessAreas?: InputMaybe<Array<Scalars['String']>>
  strongBusinessAreasOptionIds?: InputMaybe<Array<Scalars['String']>>
  supportAndHelp?: InputMaybe<Array<Scalars['String']>>
  supportAndHelpOptionIds?: InputMaybe<Array<Scalars['String']>>
  targetAudience?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  thisYearRevenue?: InputMaybe<Scalars['String']>
  topGoal?: InputMaybe<Scalars['String']>
  topGoalOptionId?: InputMaybe<Scalars['String']>
  totalEmployees?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>
  usePOS?: InputMaybe<Scalars['String']>
  usePOSOptionId?: InputMaybe<Scalars['String']>
  usePaymentProcessor?: InputMaybe<Scalars['String']>
  usePaymentProcessorOptionId?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  veteranOwned?: InputMaybe<Scalars['String']>
  volunteers?: InputMaybe<Scalars['String']>
  wages?: InputMaybe<Scalars['String']>
  whereDoYouDoBusiness?: InputMaybe<Array<Scalars['String']>>
  whereDoYouDoBusinessOptionIds?: InputMaybe<Array<Scalars['String']>>
  year?: InputMaybe<Scalars['String']>
  yearEstablished?: InputMaybe<Scalars['String']>
  yearsInBusiness?: InputMaybe<Scalars['String']>
}

export type UpdateMyProfileInput = {
  advisors?: InputMaybe<Array<Scalars['String']>>
  attestationStatus?: InputMaybe<Scalars['String']>
  bio?: InputMaybe<Scalars['String']>
  calendarEventViewPreference?: InputMaybe<CalendarEventViewPreference>
  calendlyEventsUrl?: InputMaybe<Scalars['String']>
  companyAdvisor?: InputMaybe<Scalars['String']>
  companyEmployees?: InputMaybe<Scalars['Int']>
  defaultVideoLinkDescription?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['AWSEmail']>
  firstName?: InputMaybe<Scalars['String']>
  interimTimezone?: InputMaybe<Scalars['String']>
  isConsentGivenToAdvisor?: InputMaybe<Scalars['Boolean']>
  isNewUser?: InputMaybe<Scalars['Boolean']>
  isSmsEnabled?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  mobileContactNumber?: InputMaybe<Scalars['AWSPhone']>
  name?: InputMaybe<Scalars['String']>
  onboardingStatus?: InputMaybe<OnboardingStatus>
  owners?: InputMaybe<Array<Scalars['String']>>
  schedulingUrl?: InputMaybe<Scalars['String']>
  smsSubscriptionStatus?: InputMaybe<SmsSubscriptionStatus>
  status?: InputMaybe<Scalars['String']>
  streamAccessToken?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  timezone?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
  websiteURL?: InputMaybe<Scalars['String']>
  yearsInBusiness?: InputMaybe<Scalars['String']>
}

export type UpdateOwnerInput = {
  ownerExperience?: InputMaybe<Scalars['String']>
  selectedTools?: InputMaybe<Scalars['AWSJSON']>
  tenantId: Scalars['String']
}

export type UpdateToolInput = {
  authType?: InputMaybe<ToolAuthType>
  authorizationQueryParameters?: InputMaybe<Array<KeyValuePairInput>>
  authorizationUri?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Array<ToolCategoryInput>>
  category?: InputMaybe<Scalars['String']>
  clientId?: InputMaybe<Scalars['String']>
  clientSecret?: InputMaybe<Scalars['String']>
  connectionFormImageUrl?: InputMaybe<Scalars['String']>
  connectionStatus?: InputMaybe<Scalars['String']>
  connectorName?: InputMaybe<Scalars['String']>
  cyclrToolId?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  diagnosticSupported?: InputMaybe<Scalars['Boolean']>
  featured?: InputMaybe<Scalars['Boolean']>
  group?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  imageFile?: InputMaybe<ToolImageFileInput>
  imageUrl?: InputMaybe<Scalars['String']>
  isBookmarked?: InputMaybe<Scalars['Boolean']>
  isConnected?: InputMaybe<Scalars['Boolean']>
  isRecommended?: InputMaybe<Scalars['Boolean']>
  isSelected?: InputMaybe<Scalars['Boolean']>
  keyWords?: InputMaybe<Array<Scalars['String']>>
  longDescription?: InputMaybe<Scalars['String']>
  options?: InputMaybe<ToolConnectionOptionsInput>
  pricingPageUrl?: InputMaybe<Scalars['String']>
  recommendedBy?: InputMaybe<Scalars['String']>
  relatedPlays?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  scopes?: InputMaybe<Array<Scalars['String']>>
  supportLevel?: InputMaybe<ToolSupportLevel>
  tags?: InputMaybe<Array<Scalars['String']>>
  tokenUrl?: InputMaybe<Scalars['String']>
  toolDisplayName?: InputMaybe<Scalars['String']>
  toolHelpText?: InputMaybe<Scalars['String']>
  toolName?: InputMaybe<Scalars['String']>
  toolType?: InputMaybe<ToolType>
  toolUrl?: InputMaybe<Scalars['String']>
  userToolsImageUrl?: InputMaybe<Scalars['String']>
  visible?: InputMaybe<Scalars['Boolean']>
}

export type UpdateUserAvailabilityInput = {
  availabilityHours?: InputMaybe<AvailabilityHoursInput>
  tenantId?: InputMaybe<Scalars['String']>
}

export type UpdateUserAvailabilityResponse = {
  __typename?: 'UpdateUserAvailabilityResponse'
  data?: Maybe<SetUserAvailabilityObj>
  error?: Maybe<Scalars['String']>
}

export type UpdateUserCalendarEvent = {
  __typename?: 'UpdateUserCalendarEvent'
  attendees?: Maybe<Array<Maybe<AttendeesType>>>
  comment?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  eventId?: Maybe<Scalars['String']>
  eventType?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  tackleId?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  videoLinkDescription?: Maybe<Scalars['String']>
}

export type UpdateUserCalendarEventInput = {
  attendees?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  comment?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  eventId?: InputMaybe<Scalars['String']>
  eventType?: InputMaybe<Scalars['String']>
  location?: InputMaybe<Scalars['String']>
  tackleId: Scalars['String']
  title?: InputMaybe<Scalars['String']>
  videoLinkDescription?: InputMaybe<Scalars['String']>
}

export type UpdateUserCalendarEventResponse = {
  __typename?: 'UpdateUserCalendarEventResponse'
  data?: Maybe<UpdateUserCalendarEvent>
  error?: Maybe<Scalars['String']>
}

export type UpdateUserNotificationsInput = {
  id?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  updateAll?: InputMaybe<Scalars['Boolean']>
}

export type UpdateUserPasswordInput = {
  password?: InputMaybe<Scalars['String']>
}

export type UpdateUserTaskInput = {
  assigneeId?: InputMaybe<Scalars['ID']>
  category?: InputMaybe<BusinessGoalCategory>
  estimationHour?: InputMaybe<Scalars['Int']>
  expectedEndDate?: InputMaybe<Scalars['String']>
  howToDo?: InputMaybe<Scalars['String']>
  howToLinks?: InputMaybe<Array<InputMaybe<UserTaskHowToInput>>>
  instructionForTask?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Scalars['Int']>
  startDate?: InputMaybe<Scalars['String']>
  status?: InputMaybe<UserTaskItemStatus>
  taskId: Scalars['ID']
  taskPriority?: InputMaybe<BusinessGoalPriority>
  tenantId: Scalars['ID']
  userId?: InputMaybe<Scalars['ID']>
}

export type UserAnswer = {
  __typename?: 'UserAnswer'
  id?: Maybe<Scalars['String']>
  meta?: Maybe<Scalars['AWSJSON']>
  optionId?: Maybe<Scalars['String']>
  question?: Maybe<Scalars['AWSJSON']>
  value?: Maybe<Scalars['String']>
}

export type UserAssessmentResponse = {
  __typename?: 'UserAssessmentResponse'
  data?: Maybe<AssessmentResponse>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type UserAvailability = {
  __typename?: 'UserAvailability'
  date: Scalars['String']
  slots: Array<SlotObj>
}

export type UserFile = {
  __typename?: 'UserFile'
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type UserIntegrations = {
  __typename?: 'UserIntegrations'
  cyclrToolId?: Maybe<Scalars['String']>
  group?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  imageUrl?: Maybe<Scalars['String']>
  isConnected?: Maybe<Scalars['Boolean']>
  isRecommended?: Maybe<Scalars['Boolean']>
  isSelected?: Maybe<Scalars['Boolean']>
  lastConnectedAt?: Maybe<Scalars['AWSDateTime']>
  name?: Maybe<Scalars['String']>
  recommendedBy?: Maybe<Scalars['String']>
  toolUrl?: Maybe<Scalars['String']>
  userToolsImageUrl?: Maybe<Scalars['String']>
}

export type UserIntegrationsResponse = {
  __typename?: 'UserIntegrationsResponse'
  data?: Maybe<Array<Maybe<ListAssessment>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type UserMeetingData = {
  __typename?: 'UserMeetingData'
  advisorName?: Maybe<Scalars['String']>
  attendees?: Maybe<Array<CalendarEventAttendee>>
  createdAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  endTime?: Maybe<Scalars['String']>
  eventId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  inviteeId?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  organizerName?: Maybe<Scalars['String']>
  ownerId?: Maybe<Scalars['String']>
  participantId?: Maybe<Scalars['String']>
  participantName?: Maybe<Scalars['String']>
  role?: Maybe<Array<Maybe<Scalars['String']>>>
  startTime?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
  timezone?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  videoLinkDescription?: Maybe<Scalars['String']>
}

export type UserNotification = {
  __typename?: 'UserNotification'
  businessName?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  firstName?: Maybe<Scalars['String']>
  id: Scalars['String']
  lastName?: Maybe<Scalars['String']>
  meta?: Maybe<Scalars['AWSJSON']>
  notificationType: Scalars['String']
  sender?: Maybe<Scalars['AWSJSON']>
  status?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type UserNotificationData = {
  __typename?: 'UserNotificationData'
  lastEvaluatedKey?: Maybe<Scalars['String']>
  notifications?: Maybe<Array<Maybe<UserNotification>>>
  totalCount?: Maybe<Scalars['Int']>
}

export type UserProfile = {
  __typename?: 'UserProfile'
  activatedAt?: Maybe<Scalars['String']>
  algoliaSearchKey?: Maybe<Scalars['String']>
  attestationStatus?: Maybe<Scalars['String']>
  bio?: Maybe<Scalars['String']>
  calendarEventViewPreference?: Maybe<CalendarEventViewPreference>
  calendlyEventsUrl?: Maybe<Scalars['String']>
  companyAdvisor?: Maybe<Scalars['String']>
  companyEmployees?: Maybe<Scalars['Int']>
  createdAt: Scalars['AWSDateTime']
  defaultVideoLinkDescription?: Maybe<Scalars['String']>
  email: Scalars['AWSEmail']
  firstName?: Maybe<Scalars['String']>
  firstSigninDate?: Maybe<Scalars['String']>
  googleCalendarWebhookMetaData?: Maybe<GoogleCalendarWebhookMetaData>
  id: Scalars['ID']
  interimTimezone?: Maybe<Scalars['String']>
  isConsentGivenToAdvisor?: Maybe<Scalars['Boolean']>
  isNewUser?: Maybe<Scalars['Boolean']>
  isSmsEnabled?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  mobileContactNumber?: Maybe<Scalars['AWSPhone']>
  name?: Maybe<Scalars['String']>
  onboardingStatus?: Maybe<OnboardingStatus>
  organizationName?: Maybe<Scalars['String']>
  ownerExperience?: Maybe<Scalars['String']>
  roles?: Maybe<Array<Maybe<Scalars['String']>>>
  schedulingUrl?: Maybe<Scalars['String']>
  smsSubscriptionStatus?: Maybe<SmsSubscriptionStatus>
  status?: Maybe<Scalars['String']>
  streamAccessToken?: Maybe<Scalars['String']>
  submissionEndDay?: Maybe<Scalars['Int']>
  submissionReminderDay?: Maybe<Scalars['Int']>
  submissionReportDay?: Maybe<Scalars['Int']>
  submissionStartDay?: Maybe<Scalars['Int']>
  tempPassword?: Maybe<Scalars['String']>
  tenantId?: Maybe<Array<Scalars['String']>>
  timezone?: Maybe<Scalars['String']>
  updatedAt: Scalars['AWSDateTime']
  websiteURL?: Maybe<Scalars['String']>
  yearsInBusiness?: Maybe<Scalars['String']>
}

export type UserRecommendationsResponse = {
  __typename?: 'UserRecommendationsResponse'
  data?: Maybe<Scalars['AWSJSON']>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type UserResponse = {
  __typename?: 'UserResponse'
  data?: Maybe<UserAnswer>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type UserTask = {
  __typename?: 'UserTask'
  assigneeId?: Maybe<Scalars['ID']>
  category?: Maybe<BusinessGoalCategory>
  createdBy?: Maybe<Scalars['String']>
  createdById?: Maybe<Scalars['ID']>
  estimationHour?: Maybe<Scalars['Int']>
  expectedEndDate?: Maybe<Scalars['String']>
  filter?: Maybe<BusinessGoalDateFilter>
  howToDo?: Maybe<Scalars['String']>
  howToLinks?: Maybe<Array<Maybe<UserTaskHowTo>>>
  instructionForTask?: Maybe<Scalars['String']>
  kpis?: Maybe<Scalars['String']>
  milestoneId?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['ID']>
  resources?: Maybe<Scalars['String']>
  role?: Maybe<Scalars['String']>
  source?: Maybe<UserTaskSource>
  startDate?: Maybe<Scalars['String']>
  status?: Maybe<UserTaskItemStatus>
  taskId?: Maybe<Scalars['ID']>
  taskPriority?: Maybe<BusinessGoalPriority>
  tenantId?: Maybe<Scalars['ID']>
  timeline?: Maybe<Scalars['String']>
  tool?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['ID']>
  why?: Maybe<Scalars['String']>
}

export type UserTaskHowTo = {
  __typename?: 'UserTaskHowTo'
  howToId: Scalars['ID']
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type UserTaskHowToInput = {
  howToId: Scalars['ID']
  title?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
}

export type UserTaskHowToResponse = {
  __typename?: 'UserTaskHowToResponse'
  text: Scalars['String']
  videoLink?: Maybe<Scalars['String']>
}

export type UserTaskInput = {
  estimationHour?: InputMaybe<Scalars['Int']>
  expectedEndDate?: InputMaybe<Scalars['String']>
  howToDo?: InputMaybe<Scalars['String']>
  howToLinks?: InputMaybe<Array<InputMaybe<UserTaskHowToInput>>>
  instructionForTask?: InputMaybe<Scalars['String']>
  kpis?: InputMaybe<Scalars['String']>
  milestoneId?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  order?: InputMaybe<Scalars['Int']>
  planId?: InputMaybe<Scalars['ID']>
  resources?: InputMaybe<Scalars['String']>
  role?: InputMaybe<Scalars['String']>
  source?: InputMaybe<UserTaskSource>
  startDate?: InputMaybe<Scalars['String']>
  status?: InputMaybe<UserTaskItemStatus>
  taskId: Scalars['ID']
  tenantId?: InputMaybe<Scalars['ID']>
  timeline?: InputMaybe<Scalars['String']>
  tool?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['ID']>
  why?: InputMaybe<Scalars['String']>
}

export enum UserTaskItemFilterDateRange {
  OneMonth = 'ONE_MONTH',
  OneWeek = 'ONE_WEEK',
  Today = 'TODAY',
}

export enum UserTaskItemStatus {
  Archived = 'ARCHIVED',
  Blocked = 'BLOCKED',
  Creating = 'CREATING',
  Done = 'DONE',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS',
  Paused = 'PAUSED',
  Todo = 'TODO',
}

export enum UserTaskQuickFilter {
  NoProjectAssigned = 'NO_PROJECT_ASSIGNED',
}

export enum UserTaskSource {
  Assessment = 'ASSESSMENT',
  General = 'GENERAL',
  Goal = 'GOAL',
  Meeting = 'MEETING',
  Play = 'PLAY',
}

export type UserTasksFilterInput = {
  dateRange?: InputMaybe<BusinessGoalDateFilter>
  milestoneId?: InputMaybe<Scalars['String']>
  planId?: InputMaybe<Scalars['String']>
  quickFilter?: InputMaybe<UserTaskQuickFilter>
  source?: InputMaybe<UserTaskSource>
  status?: InputMaybe<UserTaskItemStatus>
}

export type Attendee = {
  __typename?: 'attendee'
  email: Scalars['String']
  responseStatus?: Maybe<Scalars['String']>
}

export type AttendeesInterface = {
  email: Scalars['String']
  organizer?: InputMaybe<Scalars['Boolean']>
  responseStatus?: InputMaybe<Scalars['String']>
  self?: InputMaybe<Scalars['Boolean']>
}

export type BusinessAdvisor = {
  __typename?: 'businessAdvisor'
  advisor?: Maybe<Scalars['String']>
  businessId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type CancelOutlookEventInput = {
  eventId: Scalars['String']
}

export type DeleteGoogleCalendarEventInput = {
  eventId?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  tenantId?: InputMaybe<Scalars['String']>
}

export type GetOwnerBusinessDataInput = {
  endTime: Scalars['String']
  startTime: Scalars['String']
}

export type TaskPrerequisites = {
  __typename?: 'taskPrerequisites'
  assessments?: Maybe<Array<Scalars['String']>>
  plays?: Maybe<Array<Scalars['String']>>
}

export type TimeObjInterface = {
  dateTime?: InputMaybe<Scalars['String']>
  timeZone?: InputMaybe<Scalars['String']>
}

export type UpdateGoogleCalendarEventInput = {
  attendees: Array<InputMaybe<AttendeesInterface>>
  description?: InputMaybe<Scalars['String']>
  end: TimeObjInterface
  eventId?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  start: TimeObjInterface
  tenantId?: InputMaybe<Scalars['String']>
}

export type UserMeeting = {
  __typename?: 'userMeeting'
  advisorId: Scalars['String']
  attendees: Array<Maybe<Attendee>>
  createdAt?: Maybe<Scalars['String']>
  endTime: Scalars['String']
  eventId: Scalars['String']
  id: Scalars['String']
  inviteeId?: Maybe<Scalars['String']>
  ownerId: Scalars['String']
  role?: Maybe<Array<Maybe<Scalars['String']>>>
  startTime: Scalars['String']
  status?: Maybe<Scalars['String']>
  tenantId: Scalars['String']
  timezone?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export type AssessmentResponseFieldsFragment = {
  __typename?: 'AssessmentResponse'
  id?: string | null | undefined
  currentStage?: string | null | undefined
  playStage?: string | null | undefined
  type?: string | null | undefined
  userId?: string | null | undefined
  createdAt?: string | null | undefined
  updatedAt?: string | null | undefined
  assessmentResponse?:
    | Array<{
        __typename?: 'AssessmentResponseOutput'
        next?: string | null | undefined
        previous?: Array<string> | null | undefined
        id?: string | null | undefined
        value?: string | null | undefined
        label?: string | null | undefined
        playResponse?: string | null | undefined
        isIndustry?: boolean | null | undefined
        isSubIndustry?: boolean | null | undefined
        isTopGoal?: boolean | null | undefined
        optionType?: string | null | undefined
        submittedAt?: string | null | undefined
        showInReview?: boolean | null | undefined
        questionLabel?: string | null | undefined
        recommendations?:
          | {
              __typename?: 'Recommendations'
              assessments?: Array<string> | null | undefined
              plays?: Array<string> | null | undefined
              assessmentRecommendedAt?: string | null | undefined
              playRecommendedAt?: string | null | undefined
            }
          | null
          | undefined
      }>
    | null
    | undefined
}

export type AssessmentQuestionFieldsFragment = {
  __typename?: 'Assessment'
  id: string
  tenantId?: number | null | undefined
  previous?: string | null | undefined
  assessmentType?: string | null | undefined
  meta?: string | null | undefined
  content?: string | null | undefined
  optionType?: string | null | undefined
  options?: string | null | undefined
  createdAt?: string | null | undefined
  updatedAt?: string | null | undefined
  isVisible?: boolean | null | undefined
}

export type GetAssessmentResponseByQuestionFieldsFragment = {
  __typename?: 'UserResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'UserAnswer'
        id?: string | null | undefined
        value?: string | null | undefined
        question?: string | null | undefined
        meta?: string | null | undefined
      }
    | null
    | undefined
}

export type UserAssessmentResponseFieldsFragment = {
  __typename?: 'UserAssessmentResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'AssessmentResponse'
        id?: string | null | undefined
        currentStage?: string | null | undefined
        playStage?: string | null | undefined
        type?: string | null | undefined
        userId?: string | null | undefined
        totalItems?: number | null | undefined
        totalAttemptedItems?: number | null | undefined
        createdAt?: string | null | undefined
        updatedAt?: string | null | undefined
        completedAt?: string | null | undefined
        assessmentResponse?:
          | Array<{
              __typename?: 'AssessmentResponseOutput'
              next?: string | null | undefined
              previous?: Array<string> | null | undefined
              id?: string | null | undefined
              value?: string | null | undefined
              label?: string | null | undefined
              playResponse?: string | null | undefined
              isIndustry?: boolean | null | undefined
              isSubIndustry?: boolean | null | undefined
              isTopGoal?: boolean | null | undefined
              optionType?: string | null | undefined
              submittedAt?: string | null | undefined
              showInReview?: boolean | null | undefined
              questionLabel?: string | null | undefined
              recommendations?:
                | {
                    __typename?: 'Recommendations'
                    assessments?: Array<string> | null | undefined
                    plays?: Array<string> | null | undefined
                    assessmentRecommendedAt?: string | null | undefined
                    playRecommendedAt?: string | null | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetUserRecommendationsFieldsFragment = {
  __typename?: 'UserRecommendationsResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetUserAssessmentResponseQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}>

export type GetUserAssessmentResponseQuery = {
  __typename?: 'Query'
  getUserAssessmentResponse?:
    | {
        __typename?: 'UserAssessmentResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'AssessmentResponse'
              id?: string | null | undefined
              currentStage?: string | null | undefined
              playStage?: string | null | undefined
              type?: string | null | undefined
              userId?: string | null | undefined
              totalItems?: number | null | undefined
              totalAttemptedItems?: number | null | undefined
              createdAt?: string | null | undefined
              updatedAt?: string | null | undefined
              completedAt?: string | null | undefined
              assessmentResponse?:
                | Array<{
                    __typename?: 'AssessmentResponseOutput'
                    next?: string | null | undefined
                    previous?: Array<string> | null | undefined
                    id?: string | null | undefined
                    value?: string | null | undefined
                    label?: string | null | undefined
                    playResponse?: string | null | undefined
                    isIndustry?: boolean | null | undefined
                    isSubIndustry?: boolean | null | undefined
                    isTopGoal?: boolean | null | undefined
                    optionType?: string | null | undefined
                    submittedAt?: string | null | undefined
                    showInReview?: boolean | null | undefined
                    questionLabel?: string | null | undefined
                    recommendations?:
                      | {
                          __typename?: 'Recommendations'
                          assessments?: Array<string> | null | undefined
                          plays?: Array<string> | null | undefined
                          assessmentRecommendedAt?: string | null | undefined
                          playRecommendedAt?: string | null | undefined
                        }
                      | null
                      | undefined
                  }>
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetAssessmentsQueryVariables = Exact<{
  type?: InputMaybe<Scalars['String']>
}>

export type GetAssessmentsQuery = {
  __typename?: 'Query'
  getAssessments?:
    | Array<
        | {
            __typename?: 'Assessment'
            id: string
            tenantId?: number | null | undefined
            previous?: string | null | undefined
            assessmentType?: string | null | undefined
            meta?: string | null | undefined
            content?: string | null | undefined
            optionType?: string | null | undefined
            options?: string | null | undefined
            createdAt?: string | null | undefined
            updatedAt?: string | null | undefined
            isVisible?: boolean | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type SaveAssessmentResponseMutationVariables = Exact<{
  input: SaveAssessmentResponseInput
}>

export type SaveAssessmentResponseMutation = {
  __typename?: 'Mutation'
  saveAssessmentResponse?:
    | {
        __typename?: 'AssessmentResponse'
        id?: string | null | undefined
        currentStage?: string | null | undefined
        playStage?: string | null | undefined
        type?: string | null | undefined
        userId?: string | null | undefined
        createdAt?: string | null | undefined
        updatedAt?: string | null | undefined
        assessmentResponse?:
          | Array<{
              __typename?: 'AssessmentResponseOutput'
              next?: string | null | undefined
              previous?: Array<string> | null | undefined
              id?: string | null | undefined
              value?: string | null | undefined
              label?: string | null | undefined
              playResponse?: string | null | undefined
              isIndustry?: boolean | null | undefined
              isSubIndustry?: boolean | null | undefined
              isTopGoal?: boolean | null | undefined
              optionType?: string | null | undefined
              submittedAt?: string | null | undefined
              showInReview?: boolean | null | undefined
              questionLabel?: string | null | undefined
              recommendations?:
                | {
                    __typename?: 'Recommendations'
                    assessments?: Array<string> | null | undefined
                    plays?: Array<string> | null | undefined
                    assessmentRecommendedAt?: string | null | undefined
                    playRecommendedAt?: string | null | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetAssessmentResponseByQuestionQueryVariables = Exact<{
  type?: InputMaybe<Scalars['String']>
  question?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}>

export type GetAssessmentResponseByQuestionQuery = {
  __typename?: 'Query'
  getAssessmentResponseByQuestion?:
    | {
        __typename?: 'UserResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'UserAnswer'
              id?: string | null | undefined
              value?: string | null | undefined
              question?: string | null | undefined
              meta?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetUserRecommendationsQueryVariables = Exact<{
  input: GetUserRecommendationsInput
}>

export type GetUserRecommendationsQuery = {
  __typename?: 'Query'
  getUserRecommendations?:
    | {
        __typename?: 'UserRecommendationsResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type ListAssessmentsFieldsFragment = {
  __typename?: 'ListAssessmentResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'ListAssessment'
            type?: string | null | undefined
            order?: string | null | undefined
            isCompleted?: boolean | null | undefined
            isLocked?: boolean | null | undefined
            inProgress?: boolean | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type OptionsFieldsFragment = {
  __typename?: 'GetOptionsResponse'
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'Options'
            id?: string | null | undefined
            type?: string | null | undefined
            name?: string | null | undefined
            order?: string | null | undefined
            dependencies?: string | null | undefined
            toolTipText?: string | null | undefined
            label?: string | null | undefined
            infoText?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type ListAssessmentsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}>

export type ListAssessmentsQuery = {
  __typename?: 'Query'
  listAssessments?:
    | {
        __typename?: 'ListAssessmentResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'ListAssessment'
                  type?: string | null | undefined
                  order?: string | null | undefined
                  isCompleted?: boolean | null | undefined
                  isLocked?: boolean | null | undefined
                  inProgress?: boolean | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetOptionsQueryVariables = Exact<{
  optionTypes: Array<Scalars['String']> | Scalars['String']
}>

export type GetOptionsQuery = {
  __typename?: 'Query'
  getOptions?:
    | {
        __typename?: 'GetOptionsResponse'
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'Options'
                  id?: string | null | undefined
                  type?: string | null | undefined
                  name?: string | null | undefined
                  order?: string | null | undefined
                  dependencies?: string | null | undefined
                  toolTipText?: string | null | undefined
                  label?: string | null | undefined
                  infoText?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetAttestationSummaryFieldsFragment = {
  __typename?: 'AttestationSummary'
  tenantId?: string | null | undefined
  unsignedCount?: number | null | undefined
  signedCount?: number | null | undefined
  submissionStartDate?: string | null | undefined
  submissionEndDate?: string | null | undefined
  submissionReminderDate?: string | null | undefined
  submissionReportDate?: string | null | undefined
  newAggregatedReport?:
    | {
        __typename?: 'Attestation'
        month?: string | null | undefined
        year?: string | null | undefined
        contractors?: string | null | undefined
        businessBankAccount?: string | null | undefined
        jobsCreated?: string | null | undefined
        volunteers?: string | null | undefined
        partTimeEmployees?: string | null | undefined
        fullTimeEmployees?: string | null | undefined
        gender?: string | null | undefined
        race?: string | null | undefined
        education?: string | null | undefined
        wages?: string | null | undefined
        revenue?: string | null | undefined
        debtFinancing?: string | null | undefined
        financialGrowth?: string | null | undefined
        newCustomer?: string | null | undefined
        productSale?: string | null | undefined
        serviceSale?: string | null | undefined
        targetAudience?: string | null | undefined
        physicalLocation?: string | null | undefined
        businessName?: string | null | undefined
        name?: string | null | undefined
        signedDocumentUrl?: string | null | undefined
        error?: string | null | undefined
        createdAt?: string | null | undefined
        downloadUrl?: string | null | undefined
        fileName?: string | null | undefined
      }
    | null
    | undefined
  aggregationReportHistory?:
    | Array<
        | {
            __typename?: 'Attestation'
            month?: string | null | undefined
            year?: string | null | undefined
            contractors?: string | null | undefined
            businessBankAccount?: string | null | undefined
            jobsCreated?: string | null | undefined
            volunteers?: string | null | undefined
            partTimeEmployees?: string | null | undefined
            fullTimeEmployees?: string | null | undefined
            gender?: string | null | undefined
            race?: string | null | undefined
            education?: string | null | undefined
            wages?: string | null | undefined
            revenue?: string | null | undefined
            debtFinancing?: string | null | undefined
            financialGrowth?: string | null | undefined
            newCustomer?: string | null | undefined
            productSale?: string | null | undefined
            serviceSale?: string | null | undefined
            targetAudience?: string | null | undefined
            physicalLocation?: string | null | undefined
            businessName?: string | null | undefined
            name?: string | null | undefined
            signedDocumentUrl?: string | null | undefined
            error?: string | null | undefined
            createdAt?: string | null | undefined
            downloadUrl?: string | null | undefined
            fileName?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type GetAttestationSummaryQueryVariables = Exact<{
  input?: InputMaybe<GetAttestationSummaryInput>
}>

export type GetAttestationSummaryQuery = {
  __typename?: 'Query'
  getAttestationSummary?:
    | {
        __typename?: 'AttestationSummary'
        tenantId?: string | null | undefined
        unsignedCount?: number | null | undefined
        signedCount?: number | null | undefined
        submissionStartDate?: string | null | undefined
        submissionEndDate?: string | null | undefined
        submissionReminderDate?: string | null | undefined
        submissionReportDate?: string | null | undefined
        newAggregatedReport?:
          | {
              __typename?: 'Attestation'
              month?: string | null | undefined
              year?: string | null | undefined
              contractors?: string | null | undefined
              businessBankAccount?: string | null | undefined
              jobsCreated?: string | null | undefined
              volunteers?: string | null | undefined
              partTimeEmployees?: string | null | undefined
              fullTimeEmployees?: string | null | undefined
              gender?: string | null | undefined
              race?: string | null | undefined
              education?: string | null | undefined
              wages?: string | null | undefined
              revenue?: string | null | undefined
              debtFinancing?: string | null | undefined
              financialGrowth?: string | null | undefined
              newCustomer?: string | null | undefined
              productSale?: string | null | undefined
              serviceSale?: string | null | undefined
              targetAudience?: string | null | undefined
              physicalLocation?: string | null | undefined
              businessName?: string | null | undefined
              name?: string | null | undefined
              signedDocumentUrl?: string | null | undefined
              error?: string | null | undefined
              createdAt?: string | null | undefined
              downloadUrl?: string | null | undefined
              fileName?: string | null | undefined
            }
          | null
          | undefined
        aggregationReportHistory?:
          | Array<
              | {
                  __typename?: 'Attestation'
                  month?: string | null | undefined
                  year?: string | null | undefined
                  contractors?: string | null | undefined
                  businessBankAccount?: string | null | undefined
                  jobsCreated?: string | null | undefined
                  volunteers?: string | null | undefined
                  partTimeEmployees?: string | null | undefined
                  fullTimeEmployees?: string | null | undefined
                  gender?: string | null | undefined
                  race?: string | null | undefined
                  education?: string | null | undefined
                  wages?: string | null | undefined
                  revenue?: string | null | undefined
                  debtFinancing?: string | null | undefined
                  financialGrowth?: string | null | undefined
                  newCustomer?: string | null | undefined
                  productSale?: string | null | undefined
                  serviceSale?: string | null | undefined
                  targetAudience?: string | null | undefined
                  physicalLocation?: string | null | undefined
                  businessName?: string | null | undefined
                  name?: string | null | undefined
                  signedDocumentUrl?: string | null | undefined
                  error?: string | null | undefined
                  createdAt?: string | null | undefined
                  downloadUrl?: string | null | undefined
                  fileName?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type BusinessSupportOrganizationFieldsFragment = {
  __typename?: 'BusinessSupportOrganization'
  id: string
  adminId: string
  name: string
  logoUrl?: string | null | undefined
  licenseCount?: number | null | undefined
  licensesUsed?: number | null | undefined
  adminCount?: number | null | undefined
  advisorCount?: number | null | undefined
  terminology?: Array<{ __typename?: 'Term'; term: string; replacement: string }> | null | undefined
}

export type SearchIndustryReportFieldsFragment = {
  __typename?: 'IndustryReport'
  summaryText?: string | null | undefined
  searchedKeyword?: string | null | undefined
  results?:
    | Array<
        | {
            __typename?: 'IndustryReportResult'
            summary?: string | null | undefined
            docUrl?: string | null | undefined
            docTitle?: string | null | undefined
            pageNumber?: number | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type GetBusinessSupportOrganizationQueryVariables = Exact<{
  input: GetBusinessSupportOrganizationInput
}>

export type GetBusinessSupportOrganizationQuery = {
  __typename?: 'Query'
  getBusinessSupportOrganization?:
    | {
        __typename?: 'BusinessSupportOrganization'
        id: string
        adminId: string
        name: string
        logoUrl?: string | null | undefined
        licenseCount?: number | null | undefined
        licensesUsed?: number | null | undefined
        adminCount?: number | null | undefined
        advisorCount?: number | null | undefined
        terminology?:
          | Array<{ __typename?: 'Term'; term: string; replacement: string }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type SearchIndustryReportQueryVariables = Exact<{
  input: SearchIndustryReportInput
}>

export type SearchIndustryReportQuery = {
  __typename?: 'Query'
  searchIndustryReport?:
    | {
        __typename?: 'IndustryReport'
        summaryText?: string | null | undefined
        searchedKeyword?: string | null | undefined
        results?:
          | Array<
              | {
                  __typename?: 'IndustryReportResult'
                  summary?: string | null | undefined
                  docUrl?: string | null | undefined
                  docTitle?: string | null | undefined
                  pageNumber?: number | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type BusinessProfileFieldsFragment = {
  __typename?: 'BusinessProfile'
  id?: string | null | undefined
  userName?: string | null | undefined
  businessName?: string | null | undefined
  yearsInBusiness?: string | null | undefined
  companyEmployees?: string | null | undefined
  createdAt?: string | null | undefined
  updatedAt?: string | null | undefined
  tenantId?: string | null | undefined
  businessVision?: string | null | undefined
  challenges?: string | null | undefined
  otherGoalsOptionIds?: Array<string> | null | undefined
  registerBusinessStructureOptionId?: string | null | undefined
  otherRegisteredBusinessStructure?: string | null | undefined
  month?: string | null | undefined
  year?: string | null | undefined
  contractors?: string | null | undefined
  businessBankAccount?: boolean | null | undefined
  jobsCreated?: string | null | undefined
  volunteers?: string | null | undefined
  partTimeEmployees?: string | null | undefined
  fullTimeEmployees?: string | null | undefined
  gender?: string | null | undefined
  race?: string | null | undefined
  education?: string | null | undefined
  wages?: string | null | undefined
  revenue?: string | null | undefined
  debtFinancing?: string | null | undefined
  financialGrowth?: string | null | undefined
  newCustomer?: string | null | undefined
  productSale?: string | null | undefined
  serviceSale?: string | null | undefined
  targetAudience?: string | null | undefined
  physicalLocation?: string | null | undefined
  additionalInfo?: string | null | undefined
  businessClassificationOptionId?: string | null | undefined
  foodSubIndustryOptionIds?: Array<string> | null | undefined
  servicesSubIndustryOptionId?: string | null | undefined
  strongBusinessAreasOptionIds?: Array<string> | null | undefined
  supportAndHelpOptionIds?: Array<string> | null | undefined
  businessLocationsOptionIds?: Array<string> | null | undefined
  topGoalOptionId?: string | null | undefined
  businessStageOptionId?: string | null | undefined
  domainName?: string | null | undefined
  businessEmail?: string | null | undefined
  naicsCode?: string | null | undefined
  EIN?: string | null | undefined
  hasAvatar?: boolean | null | undefined
  avatar?: string | null | undefined
  yearEstablished?: string | null | undefined
  dba?: string | null | undefined
  totalEmployees?: string | null | undefined
  minorityOwned?: string | null | undefined
  sbaCertified?: string | null | undefined
  veteranOwned?: string | null | undefined
  whereDoYouDoBusinessOptionIds?: Array<string> | null | undefined
  revenueStatusOptionId?: string | null | undefined
  otherWhereDoYouDoBusiness?: string | null | undefined
  hoursSpentOptionId?: string | null | undefined
  exitPlanOptionId?: string | null | undefined
  otherExitPlan?: string | null | undefined
  productsAndServicesOptionIds?: Array<string> | null | undefined
  haveStartedGeneratingSales?: boolean | null | undefined
  motivation?: string | null | undefined
  personalGoals?: string | null | undefined
  launchDate?: string | null | undefined
  businessDescription?: string | null | undefined
  lastYearRevenue?: string | null | undefined
  thisYearRevenue?: string | null | undefined
  grossRevenueGoalForThisYear?: string | null | undefined
  businessAddress?:
    | {
        __typename?: 'BusinessAddress'
        addressLine1?: string | null | undefined
        addressLine2?: string | null | undefined
        city?: string | null | undefined
        state?: string | null | undefined
        zipCode?: string | null | undefined
      }
    | null
    | undefined
  logo?:
    | {
        __typename?: 'UserFile'
        id?: string | null | undefined
        name?: string | null | undefined
        size?: string | null | undefined
        url?: string | null | undefined
      }
    | null
    | undefined
}

export type BusinessProfileFragment = {
  __typename?: 'BusinessProfile'
  userName?: string | null | undefined
  businessName?: string | null | undefined
  yearsInBusiness?: string | null | undefined
  companyEmployees?: string | null | undefined
  createdAt?: string | null | undefined
  updatedAt?: string | null | undefined
  tenantId?: string | null | undefined
  businessVision?: string | null | undefined
  challenges?: string | null | undefined
  otherGoals?: Array<string> | null | undefined
  otherGoalsOptionIds?: Array<string> | null | undefined
  registerBusinessStructure?: string | null | undefined
  registerBusinessStructureOptionId?: string | null | undefined
  otherRegisteredBusinessStructure?: string | null | undefined
  month?: string | null | undefined
  year?: string | null | undefined
  contractors?: string | null | undefined
  businessBankAccount?: boolean | null | undefined
  jobsCreated?: string | null | undefined
  volunteers?: string | null | undefined
  partTimeEmployees?: string | null | undefined
  fullTimeEmployees?: string | null | undefined
  gender?: string | null | undefined
  race?: string | null | undefined
  education?: string | null | undefined
  wages?: string | null | undefined
  revenue?: string | null | undefined
  debtFinancing?: string | null | undefined
  financialGrowth?: string | null | undefined
  newCustomer?: string | null | undefined
  productSale?: string | null | undefined
  serviceSale?: string | null | undefined
  targetAudience?: string | null | undefined
  physicalLocation?: string | null | undefined
  additionalInfo?: string | null | undefined
  businessClassification?: string | null | undefined
  businessClassificationOptionId?: string | null | undefined
  foodSubIndustry?: Array<string> | null | undefined
  foodSubIndustryOptionIds?: Array<string> | null | undefined
  servicesSubIndustry?: string | null | undefined
  servicesSubIndustryOptionId?: string | null | undefined
  strongBusinessAreas?: Array<string> | null | undefined
  strongBusinessAreasOptionIds?: Array<string> | null | undefined
  supportAndHelp?: Array<string> | null | undefined
  supportAndHelpOptionIds?: Array<string> | null | undefined
  businessLocations?: Array<string> | null | undefined
  businessLocationsOptionIds?: Array<string> | null | undefined
  topGoal?: string | null | undefined
  topGoalOptionId?: string | null | undefined
  businessStage?: string | null | undefined
  businessStageOptionId?: string | null | undefined
  noOfBusinessStarted?: string | null | undefined
  thisYearRevenue?: string | null | undefined
  lastYearRevenue?: string | null | undefined
  businessNature?: string | null | undefined
  businessNatureOptionId?: string | null | undefined
  hasLogo?: string | null | undefined
  hasLogoOptionId?: string | null | undefined
  EIN?: string | null | undefined
  naicsCode?: string | null | undefined
  numberOfBusinessBankAccounts?: string | null | undefined
  hasGoogleChrome?: string | null | undefined
  hasGoogleChromeOptionId?: string | null | undefined
  hasDomainName?: string | null | undefined
  hasDomainNameOptionId?: string | null | undefined
  domainName?: string | null | undefined
  hasBusinessEmail?: string | null | undefined
  hasBusinessEmailOptionId?: string | null | undefined
  businessEmail?: string | null | undefined
  hasGoogleWorkspace?: string | null | undefined
  hasGoogleWorkspaceOptionId?: string | null | undefined
  hasEsignatureTool?: string | null | undefined
  hasEsignatureToolOptionId?: string | null | undefined
  hasBusinessPhoneNumber?: string | null | undefined
  hasBusinessPhoneNumberOptionId?: string | null | undefined
  hasLogoTrademarked?: string | null | undefined
  hasLogoTrademarkedOptionId?: string | null | undefined
  hasGoogleBusinessProfile?: string | null | undefined
  hasGoogleBusinessProfileOptionId?: string | null | undefined
  googleWorkspacePlaysOptionIds?: Array<string> | null | undefined
  googleWorkspacePlays?: Array<string> | null | undefined
  googleBusinessProfilePlaysOptionIds?: Array<string> | null | undefined
  googleBusinessProfilePlays?: Array<string> | null | undefined
  onboardedAt?: string | null | undefined
  hasAvatar?: boolean | null | undefined
  yearEstablished?: string | null | undefined
  dba?: string | null | undefined
  totalEmployees?: string | null | undefined
  minorityOwned?: string | null | undefined
  sbaCertified?: string | null | undefined
  veteranOwned?: string | null | undefined
  avatar?: string | null | undefined
  businessDescription?: string | null | undefined
  personalGoals?: string | null | undefined
  seasonalityDetails?: string | null | undefined
  exitPlan?: string | null | undefined
  exitPlanOptionId?: string | null | undefined
  otherExitPlan?: string | null | undefined
  motivation?: string | null | undefined
  grossRevenueGoalForThisYear?: string | null | undefined
  launchDate?: string | null | undefined
  whereDoYouDoBusiness?: Array<string> | null | undefined
  whereDoYouDoBusinessOptionIds?: Array<string> | null | undefined
  revenueStatus?: string | null | undefined
  revenueStatusOptionId?: string | null | undefined
  otherWhereDoYouDoBusiness?: string | null | undefined
  hoursSpent?: string | null | undefined
  hoursSpentOptionId?: string | null | undefined
  productsAndServices?: Array<string> | null | undefined
  productsAndServicesOptionIds?: Array<string> | null | undefined
  haveStartedGeneratingSales?: boolean | null | undefined
  lastUpdatedBusinessPlan?: string | null | undefined
  logo?:
    | {
        __typename?: 'UserFile'
        id?: string | null | undefined
        name?: string | null | undefined
        size?: string | null | undefined
        tenantId?: string | null | undefined
        type?: string | null | undefined
        url?: string | null | undefined
        userId?: string | null | undefined
      }
    | null
    | undefined
  businessPlan?:
    | {
        __typename?: 'UserFile'
        id?: string | null | undefined
        name?: string | null | undefined
        size?: string | null | undefined
        tenantId?: string | null | undefined
        type?: string | null | undefined
        url?: string | null | undefined
        userId?: string | null | undefined
      }
    | null
    | undefined
  monthlyImpactReports?:
    | Array<{
        __typename?: 'UserFile'
        id?: string | null | undefined
        name?: string | null | undefined
        size?: string | null | undefined
        tenantId?: string | null | undefined
        type?: string | null | undefined
        url?: string | null | undefined
        userId?: string | null | undefined
        createdAt?: string | null | undefined
      }>
    | null
    | undefined
  businessAddress?:
    | {
        __typename?: 'BusinessAddress'
        addressLine1?: string | null | undefined
        addressLine2?: string | null | undefined
        city?: string | null | undefined
        state?: string | null | undefined
        zipCode?: string | null | undefined
      }
    | null
    | undefined
}

export type BusinessSummaryResponseFieldsFragment = {
  __typename?: 'BusinessSummaryResponse'
  status: ChartStatus
  data: Array<{
    __typename?: 'BusinessSummaryItem'
    optionType?: string | null | undefined
    data: Array<{
      __typename?: 'BusinessSummaryData'
      optionType?: string | null | undefined
      optionName?: string | null | undefined
      optionId?: string | null | undefined
      tenantId?: string | null | undefined
      summary?: number | null | undefined
    }>
  }>
}

export type GetBusinessProfileQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
}>

export type GetBusinessProfileQuery = {
  __typename?: 'Query'
  getBusinessProfile?:
    | {
        __typename?: 'BusinessProfile'
        userName?: string | null | undefined
        businessName?: string | null | undefined
        yearsInBusiness?: string | null | undefined
        companyEmployees?: string | null | undefined
        createdAt?: string | null | undefined
        updatedAt?: string | null | undefined
        tenantId?: string | null | undefined
        businessVision?: string | null | undefined
        challenges?: string | null | undefined
        otherGoals?: Array<string> | null | undefined
        otherGoalsOptionIds?: Array<string> | null | undefined
        registerBusinessStructure?: string | null | undefined
        registerBusinessStructureOptionId?: string | null | undefined
        otherRegisteredBusinessStructure?: string | null | undefined
        month?: string | null | undefined
        year?: string | null | undefined
        contractors?: string | null | undefined
        businessBankAccount?: boolean | null | undefined
        jobsCreated?: string | null | undefined
        volunteers?: string | null | undefined
        partTimeEmployees?: string | null | undefined
        fullTimeEmployees?: string | null | undefined
        gender?: string | null | undefined
        race?: string | null | undefined
        education?: string | null | undefined
        wages?: string | null | undefined
        revenue?: string | null | undefined
        debtFinancing?: string | null | undefined
        financialGrowth?: string | null | undefined
        newCustomer?: string | null | undefined
        productSale?: string | null | undefined
        serviceSale?: string | null | undefined
        targetAudience?: string | null | undefined
        physicalLocation?: string | null | undefined
        additionalInfo?: string | null | undefined
        businessClassification?: string | null | undefined
        businessClassificationOptionId?: string | null | undefined
        foodSubIndustry?: Array<string> | null | undefined
        foodSubIndustryOptionIds?: Array<string> | null | undefined
        servicesSubIndustry?: string | null | undefined
        servicesSubIndustryOptionId?: string | null | undefined
        strongBusinessAreas?: Array<string> | null | undefined
        strongBusinessAreasOptionIds?: Array<string> | null | undefined
        supportAndHelp?: Array<string> | null | undefined
        supportAndHelpOptionIds?: Array<string> | null | undefined
        businessLocations?: Array<string> | null | undefined
        businessLocationsOptionIds?: Array<string> | null | undefined
        topGoal?: string | null | undefined
        topGoalOptionId?: string | null | undefined
        businessStage?: string | null | undefined
        businessStageOptionId?: string | null | undefined
        noOfBusinessStarted?: string | null | undefined
        thisYearRevenue?: string | null | undefined
        lastYearRevenue?: string | null | undefined
        businessNature?: string | null | undefined
        businessNatureOptionId?: string | null | undefined
        hasLogo?: string | null | undefined
        hasLogoOptionId?: string | null | undefined
        EIN?: string | null | undefined
        naicsCode?: string | null | undefined
        numberOfBusinessBankAccounts?: string | null | undefined
        hasGoogleChrome?: string | null | undefined
        hasGoogleChromeOptionId?: string | null | undefined
        hasDomainName?: string | null | undefined
        hasDomainNameOptionId?: string | null | undefined
        domainName?: string | null | undefined
        hasBusinessEmail?: string | null | undefined
        hasBusinessEmailOptionId?: string | null | undefined
        businessEmail?: string | null | undefined
        hasGoogleWorkspace?: string | null | undefined
        hasGoogleWorkspaceOptionId?: string | null | undefined
        hasEsignatureTool?: string | null | undefined
        hasEsignatureToolOptionId?: string | null | undefined
        hasBusinessPhoneNumber?: string | null | undefined
        hasBusinessPhoneNumberOptionId?: string | null | undefined
        hasLogoTrademarked?: string | null | undefined
        hasLogoTrademarkedOptionId?: string | null | undefined
        hasGoogleBusinessProfile?: string | null | undefined
        hasGoogleBusinessProfileOptionId?: string | null | undefined
        googleWorkspacePlaysOptionIds?: Array<string> | null | undefined
        googleWorkspacePlays?: Array<string> | null | undefined
        googleBusinessProfilePlaysOptionIds?: Array<string> | null | undefined
        googleBusinessProfilePlays?: Array<string> | null | undefined
        onboardedAt?: string | null | undefined
        hasAvatar?: boolean | null | undefined
        yearEstablished?: string | null | undefined
        dba?: string | null | undefined
        totalEmployees?: string | null | undefined
        minorityOwned?: string | null | undefined
        sbaCertified?: string | null | undefined
        veteranOwned?: string | null | undefined
        avatar?: string | null | undefined
        businessDescription?: string | null | undefined
        personalGoals?: string | null | undefined
        seasonalityDetails?: string | null | undefined
        exitPlan?: string | null | undefined
        exitPlanOptionId?: string | null | undefined
        otherExitPlan?: string | null | undefined
        motivation?: string | null | undefined
        grossRevenueGoalForThisYear?: string | null | undefined
        launchDate?: string | null | undefined
        whereDoYouDoBusiness?: Array<string> | null | undefined
        whereDoYouDoBusinessOptionIds?: Array<string> | null | undefined
        revenueStatus?: string | null | undefined
        revenueStatusOptionId?: string | null | undefined
        otherWhereDoYouDoBusiness?: string | null | undefined
        hoursSpent?: string | null | undefined
        hoursSpentOptionId?: string | null | undefined
        productsAndServices?: Array<string> | null | undefined
        productsAndServicesOptionIds?: Array<string> | null | undefined
        haveStartedGeneratingSales?: boolean | null | undefined
        lastUpdatedBusinessPlan?: string | null | undefined
        logo?:
          | {
              __typename?: 'UserFile'
              id?: string | null | undefined
              name?: string | null | undefined
              size?: string | null | undefined
              tenantId?: string | null | undefined
              type?: string | null | undefined
              url?: string | null | undefined
              userId?: string | null | undefined
            }
          | null
          | undefined
        businessPlan?:
          | {
              __typename?: 'UserFile'
              id?: string | null | undefined
              name?: string | null | undefined
              size?: string | null | undefined
              tenantId?: string | null | undefined
              type?: string | null | undefined
              url?: string | null | undefined
              userId?: string | null | undefined
            }
          | null
          | undefined
        monthlyImpactReports?:
          | Array<{
              __typename?: 'UserFile'
              id?: string | null | undefined
              name?: string | null | undefined
              size?: string | null | undefined
              tenantId?: string | null | undefined
              type?: string | null | undefined
              url?: string | null | undefined
              userId?: string | null | undefined
              createdAt?: string | null | undefined
            }>
          | null
          | undefined
        businessAddress?:
          | {
              __typename?: 'BusinessAddress'
              addressLine1?: string | null | undefined
              addressLine2?: string | null | undefined
              city?: string | null | undefined
              state?: string | null | undefined
              zipCode?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type UpdateBusinessProfileMutationVariables = Exact<{
  input: UpdateBusinessProfileInput
}>

export type UpdateBusinessProfileMutation = {
  __typename?: 'Mutation'
  updateBusinessProfile?:
    | {
        __typename?: 'BusinessProfile'
        id?: string | null | undefined
        userName?: string | null | undefined
        businessName?: string | null | undefined
        yearsInBusiness?: string | null | undefined
        companyEmployees?: string | null | undefined
        createdAt?: string | null | undefined
        updatedAt?: string | null | undefined
        tenantId?: string | null | undefined
        businessVision?: string | null | undefined
        challenges?: string | null | undefined
        otherGoalsOptionIds?: Array<string> | null | undefined
        registerBusinessStructureOptionId?: string | null | undefined
        otherRegisteredBusinessStructure?: string | null | undefined
        month?: string | null | undefined
        year?: string | null | undefined
        contractors?: string | null | undefined
        businessBankAccount?: boolean | null | undefined
        jobsCreated?: string | null | undefined
        volunteers?: string | null | undefined
        partTimeEmployees?: string | null | undefined
        fullTimeEmployees?: string | null | undefined
        gender?: string | null | undefined
        race?: string | null | undefined
        education?: string | null | undefined
        wages?: string | null | undefined
        revenue?: string | null | undefined
        debtFinancing?: string | null | undefined
        financialGrowth?: string | null | undefined
        newCustomer?: string | null | undefined
        productSale?: string | null | undefined
        serviceSale?: string | null | undefined
        targetAudience?: string | null | undefined
        physicalLocation?: string | null | undefined
        additionalInfo?: string | null | undefined
        businessClassificationOptionId?: string | null | undefined
        foodSubIndustryOptionIds?: Array<string> | null | undefined
        servicesSubIndustryOptionId?: string | null | undefined
        strongBusinessAreasOptionIds?: Array<string> | null | undefined
        supportAndHelpOptionIds?: Array<string> | null | undefined
        businessLocationsOptionIds?: Array<string> | null | undefined
        topGoalOptionId?: string | null | undefined
        businessStageOptionId?: string | null | undefined
        domainName?: string | null | undefined
        businessEmail?: string | null | undefined
        naicsCode?: string | null | undefined
        EIN?: string | null | undefined
        hasAvatar?: boolean | null | undefined
        avatar?: string | null | undefined
        yearEstablished?: string | null | undefined
        dba?: string | null | undefined
        totalEmployees?: string | null | undefined
        minorityOwned?: string | null | undefined
        sbaCertified?: string | null | undefined
        veteranOwned?: string | null | undefined
        whereDoYouDoBusinessOptionIds?: Array<string> | null | undefined
        revenueStatusOptionId?: string | null | undefined
        otherWhereDoYouDoBusiness?: string | null | undefined
        hoursSpentOptionId?: string | null | undefined
        exitPlanOptionId?: string | null | undefined
        otherExitPlan?: string | null | undefined
        productsAndServicesOptionIds?: Array<string> | null | undefined
        haveStartedGeneratingSales?: boolean | null | undefined
        motivation?: string | null | undefined
        personalGoals?: string | null | undefined
        launchDate?: string | null | undefined
        businessDescription?: string | null | undefined
        lastYearRevenue?: string | null | undefined
        thisYearRevenue?: string | null | undefined
        grossRevenueGoalForThisYear?: string | null | undefined
        businessAddress?:
          | {
              __typename?: 'BusinessAddress'
              addressLine1?: string | null | undefined
              addressLine2?: string | null | undefined
              city?: string | null | undefined
              state?: string | null | undefined
              zipCode?: string | null | undefined
            }
          | null
          | undefined
        logo?:
          | {
              __typename?: 'UserFile'
              id?: string | null | undefined
              name?: string | null | undefined
              size?: string | null | undefined
              url?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetBusinessSummaryQueryVariables = Exact<{
  input: GetBusinessSummaryInput
}>

export type GetBusinessSummaryQuery = {
  __typename?: 'Query'
  getBusinessSummary?:
    | {
        __typename?: 'BusinessSummaryResponse'
        status: ChartStatus
        data: Array<{
          __typename?: 'BusinessSummaryItem'
          optionType?: string | null | undefined
          data: Array<{
            __typename?: 'BusinessSummaryData'
            optionType?: string | null | undefined
            optionName?: string | null | undefined
            optionId?: string | null | undefined
            tenantId?: string | null | undefined
            summary?: number | null | undefined
          }>
        }>
      }
    | null
    | undefined
}

export type CyclrConnectorFieldsFragment = {
  __typename?: 'CyclrConnectorResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<{
        __typename?: 'CyclrConnector'
        id?: number | null | undefined
        name?: string | null | undefined
        authenticated?: boolean | null | undefined
        connectorId?: number | null | undefined
      }>
    | null
    | undefined
}

export type CalenderEventFieldsFragment = {
  __typename?: 'CalendarEventResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data: Array<{
    __typename?: 'CalendarEvent'
    summary?: string | null | undefined
    description?: string | null | undefined
    status?: string | null | undefined
    eventTime?: string | null | undefined
    meta?: string | null | undefined
    location?: string | null | undefined
    eventType?: string | null | undefined
    advisorProfiles?: string | null | undefined
    profile?: string | null | undefined
    timezone?: string | null | undefined
    eventId?: string | null | undefined
    inviteeId?: string | null | undefined
    advisorId?: string | null | undefined
    availability?: string | null | undefined
    ownerId?: string | null | undefined
    videoLinkDescription?: string | null | undefined
    id?: string | null | undefined
    isAllDay?: boolean | null | undefined
    participantName?: string | null | undefined
    organizerName?: string | null | undefined
    participantFirstName?: string | null | undefined
    participantLastName?: string | null | undefined
    organizerFirstName?: string | null | undefined
    organizerLastName?: string | null | undefined
    start?:
      | {
          __typename?: 'CalendarEventDate'
          dateTime?: string | null | undefined
          timeZone?: string | null | undefined
        }
      | null
      | undefined
    end?:
      | {
          __typename?: 'CalendarEventDate'
          dateTime?: string | null | undefined
          timeZone?: string | null | undefined
        }
      | null
      | undefined
    attendees?:
      | Array<{
          __typename?: 'CalendarEventAttendee'
          email: string
          responseStatus?: string | null | undefined
          optional?: boolean | null | undefined
        }>
      | null
      | undefined
    externalReferenceIds?:
      | Array<{
          __typename?: 'CalendarEventExternalReferenceId'
          calendarId?: string | null | undefined
          type: string
          externalEventId?: string | null | undefined
          userId: string
          eventId: string
          status?: string | null | undefined
          meta?: string | null | undefined
          createdAt?: string | null | undefined
          startDate?: string | null | undefined
        }>
      | null
      | undefined
  }>
}

export type OAuthUrlFieldsFragment = {
  __typename?: 'OAuthUrlResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type UserMeetingFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type CalendlyUserFieldsFragment = {
  __typename?: 'CalendlyUserResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'CalendlyUser'
        email?: string | null | undefined
        name?: string | null | undefined
        schedulingUrl?: string | null | undefined
        calendlyEventsUrl?: string | null | undefined
      }
    | null
    | undefined
}

export type DeleteCalendarFieldsFragment = {
  __typename?: 'DeleteCalendarEventResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type DeleteGoogleCalendarEventFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type CancelOutlookEventFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type UpdateGoogleCalendarEventFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetMeetingTypesFieldsFragment = {
  __typename?: 'GetMeetingTypeResponse'
  success?: boolean | null | undefined
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'MeetingTypes'
            id?: string | null | undefined
            tenantId?: string | null | undefined
            duration?: string | null | undefined
            label?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type SetUserAvailabilityFieldsFragment = {
  __typename?: 'SetUserAvailabilityResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'SetUserAvailabilityObj'
        createdAt?: string | null | undefined
        updatedAt?: string | null | undefined
        userId?: string | null | undefined
        tenantId?: string | null | undefined
        id?: string | null | undefined
        availabilityHours?:
          | {
              __typename?: 'AvailabilityHoursObj'
              duration?: Array<string> | null | undefined
              timezone?: string | null | undefined
              timeSlots?:
                | Array<
                    | {
                        __typename?: 'TimeSlotsObj'
                        label?: string | null | undefined
                        value?: string | null | undefined
                        slots?:
                          | Array<
                              | {
                                  __typename?: 'SlotOutput'
                                  startTime?: string | null | undefined
                                  endTime?: string | null | undefined
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetUserAvailabilityFieldsFragment = {
  __typename?: 'GetUserAvailabilityResponse'
  meetingLocation?: Array<string | null | undefined> | null | undefined
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'GetUserAvailability'
        duration: string
        availability: Array<{
          __typename?: 'UserAvailability'
          date: string
          slots: Array<{
            __typename?: 'SlotObj'
            isAvailable?: boolean | null | undefined
            slotStartTime?: string | null | undefined
            slotEndTime?: string | null | undefined
          }>
        }>
      }
    | null
    | undefined
}

export type SetUserMeetingResponseFieldsFragment = {
  __typename?: 'UserMeetingData'
  id?: string | null | undefined
  eventId?: string | null | undefined
  updatedAt?: string | null | undefined
  createdAt?: string | null | undefined
  participantId?: string | null | undefined
  tenantId?: string | null | undefined
  startTime?: string | null | undefined
  endTime?: string | null | undefined
  description?: string | null | undefined
  ownerId?: string | null | undefined
  inviteeId?: string | null | undefined
  title?: string | null | undefined
  timezone?: string | null | undefined
  role?: Array<string | null | undefined> | null | undefined
  status?: string | null | undefined
  location?: string | null | undefined
  videoLinkDescription?: string | null | undefined
  advisorName?: string | null | undefined
  organizerName?: string | null | undefined
  attendees?:
    | Array<{
        __typename?: 'CalendarEventAttendee'
        email: string
        responseStatus?: string | null | undefined
        optional?: boolean | null | undefined
      }>
    | null
    | undefined
}

export type GetUserConnectorsQueryVariables = Exact<{
  connectorName?: InputMaybe<Scalars['String']>
}>

export type GetUserConnectorsQuery = {
  __typename?: 'Query'
  getUserConnectors?:
    | {
        __typename?: 'CyclrConnectorResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<{
              __typename?: 'CyclrConnector'
              id?: number | null | undefined
              name?: string | null | undefined
              authenticated?: boolean | null | undefined
              connectorId?: number | null | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetUserCalendarEventsQueryVariables = Exact<{
  calendarName?: InputMaybe<Scalars['String']>
  timeMin?: InputMaybe<Scalars['String']>
  timeMax?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}>

export type GetUserCalendarEventsQuery = {
  __typename?: 'Query'
  getUserCalendarEvents?:
    | {
        __typename?: 'CalendarEventResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data: Array<{
          __typename?: 'CalendarEvent'
          summary?: string | null | undefined
          description?: string | null | undefined
          status?: string | null | undefined
          eventTime?: string | null | undefined
          meta?: string | null | undefined
          location?: string | null | undefined
          eventType?: string | null | undefined
          advisorProfiles?: string | null | undefined
          profile?: string | null | undefined
          timezone?: string | null | undefined
          eventId?: string | null | undefined
          inviteeId?: string | null | undefined
          advisorId?: string | null | undefined
          availability?: string | null | undefined
          ownerId?: string | null | undefined
          videoLinkDescription?: string | null | undefined
          id?: string | null | undefined
          isAllDay?: boolean | null | undefined
          participantName?: string | null | undefined
          organizerName?: string | null | undefined
          participantFirstName?: string | null | undefined
          participantLastName?: string | null | undefined
          organizerFirstName?: string | null | undefined
          organizerLastName?: string | null | undefined
          start?:
            | {
                __typename?: 'CalendarEventDate'
                dateTime?: string | null | undefined
                timeZone?: string | null | undefined
              }
            | null
            | undefined
          end?:
            | {
                __typename?: 'CalendarEventDate'
                dateTime?: string | null | undefined
                timeZone?: string | null | undefined
              }
            | null
            | undefined
          attendees?:
            | Array<{
                __typename?: 'CalendarEventAttendee'
                email: string
                responseStatus?: string | null | undefined
                optional?: boolean | null | undefined
              }>
            | null
            | undefined
          externalReferenceIds?:
            | Array<{
                __typename?: 'CalendarEventExternalReferenceId'
                calendarId?: string | null | undefined
                type: string
                externalEventId?: string | null | undefined
                userId: string
                eventId: string
                status?: string | null | undefined
                meta?: string | null | undefined
                createdAt?: string | null | undefined
                startDate?: string | null | undefined
              }>
            | null
            | undefined
        }>
      }
    | null
    | undefined
}

export type UpdateUserCalendarFieldsFragment = {
  __typename?: 'UpdateUserCalendarEventResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'UpdateUserCalendarEvent'
        title?: string | null | undefined
        eventId?: string | null | undefined
        location?: string | null | undefined
        tackleId?: string | null | undefined
        eventType?: string | null | undefined
        description?: string | null | undefined
        comment?: string | null | undefined
        videoLinkDescription?: string | null | undefined
        attendees?:
          | Array<
              | {
                  __typename?: 'AttendeesType'
                  email?: string | null | undefined
                  organizer?: boolean | null | undefined
                  self?: boolean | null | undefined
                  responseStatus?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type SubscribeGoogleCalendarWebhookFieldsFragment = {
  __typename?: 'SubscribeGoogleCalendarWebhookResponse'
  data?: string | null | undefined
  error?: string | null | undefined
}

export type CancelUserCalendarFieldsFragment = {
  __typename?: 'CancelUserCalendarEventResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'CancelUserCalendarEvent'
        tackleId?: string | null | undefined
        eventId?: string | null | undefined
        eventType?: string | null | undefined
      }
    | null
    | undefined
}

export type UpdateUserAvailabilityFieldsFragment = {
  __typename?: 'UpdateUserAvailabilityResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'SetUserAvailabilityObj'
        createdAt?: string | null | undefined
        updatedAt?: string | null | undefined
        userId?: string | null | undefined
        tenantId?: string | null | undefined
        id?: string | null | undefined
        availabilityHours?:
          | {
              __typename?: 'AvailabilityHoursObj'
              duration?: Array<string> | null | undefined
              timezone?: string | null | undefined
              timeSlots?:
                | Array<
                    | {
                        __typename?: 'TimeSlotsObj'
                        label?: string | null | undefined
                        value?: string | null | undefined
                        slots?:
                          | Array<
                              | {
                                  __typename?: 'SlotOutput'
                                  startTime?: string | null | undefined
                                  endTime?: string | null | undefined
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetUserAvailabilityHoursFieldsFragment = {
  __typename?: 'GetUserAvailabilityHoursResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'SetUserAvailabilityObj'
        createdAt?: string | null | undefined
        updatedAt?: string | null | undefined
        userId?: string | null | undefined
        tenantId?: string | null | undefined
        id?: string | null | undefined
        availabilityHours?:
          | {
              __typename?: 'AvailabilityHoursObj'
              duration?: Array<string> | null | undefined
              timezone?: string | null | undefined
              timeSlots?:
                | Array<
                    | {
                        __typename?: 'TimeSlotsObj'
                        label?: string | null | undefined
                        value?: string | null | undefined
                        slots?:
                          | Array<
                              | {
                                  __typename?: 'SlotOutput'
                                  startTime?: string | null | undefined
                                  endTime?: string | null | undefined
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetUserMeetingByIdFieldsFragment = {
  __typename?: 'GetUserMeetingByIdResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'CalendarEvent'
        summary?: string | null | undefined
        description?: string | null | undefined
        status?: string | null | undefined
        eventTime?: string | null | undefined
        meta?: string | null | undefined
        location?: string | null | undefined
        eventType?: string | null | undefined
        advisorProfiles?: string | null | undefined
        profile?: string | null | undefined
        timezone?: string | null | undefined
        eventId?: string | null | undefined
        inviteeId?: string | null | undefined
        advisorId?: string | null | undefined
        ownerId?: string | null | undefined
        availability?: string | null | undefined
        comment?: string | null | undefined
        videoLinkDescription?: string | null | undefined
        id?: string | null | undefined
        participantName?: string | null | undefined
        organizerName?: string | null | undefined
        participantFirstName?: string | null | undefined
        participantLastName?: string | null | undefined
        organizerFirstName?: string | null | undefined
        organizerLastName?: string | null | undefined
        start?:
          | {
              __typename?: 'CalendarEventDate'
              dateTime?: string | null | undefined
              timeZone?: string | null | undefined
            }
          | null
          | undefined
        end?:
          | {
              __typename?: 'CalendarEventDate'
              dateTime?: string | null | undefined
              timeZone?: string | null | undefined
            }
          | null
          | undefined
        attendees?:
          | Array<{
              __typename?: 'CalendarEventAttendee'
              email: string
              responseStatus?: string | null | undefined
              optional?: boolean | null | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetOAuthUrlQueryVariables = Exact<{
  input: GetOAuthUrlInput
}>

export type GetOAuthUrlQuery = {
  __typename?: 'Query'
  getOAuthUrl?:
    | {
        __typename?: 'OAuthUrlResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetCalendlyUserQueryVariables = Exact<{ [key: string]: never }>

export type GetCalendlyUserQuery = {
  __typename?: 'Query'
  getCalendlyUser?:
    | {
        __typename?: 'CalendlyUserResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'CalendlyUser'
              email?: string | null | undefined
              name?: string | null | undefined
              schedulingUrl?: string | null | undefined
              calendlyEventsUrl?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type SubscribeCalendlyWebhookQueryVariables = Exact<{
  tenantId?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
}>

export type SubscribeCalendlyWebhookQuery = {
  __typename?: 'Query'
  subscribeCalendlyWebhook?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type SubscribeGoogleCalendarWebhookMutationVariables = Exact<{
  tenantId?: InputMaybe<Scalars['String']>
}>

export type SubscribeGoogleCalendarWebhookMutation = {
  __typename?: 'Mutation'
  subscribeGoogleCalendarWebhook?:
    | {
        __typename?: 'SubscribeGoogleCalendarWebhookResponse'
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetUserMeetingQueryVariables = Exact<{
  advisorId?: InputMaybe<Scalars['String']>
}>

export type GetUserMeetingQuery = {
  __typename?: 'Query'
  getUserMeeting?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type DeleteCalendarEventMutationVariables = Exact<{
  input: DeleteCalendarEventInput
}>

export type DeleteCalendarEventMutation = {
  __typename?: 'Mutation'
  deleteCalendarEvent?:
    | {
        __typename?: 'DeleteCalendarEventResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type DeleteGoogleCalendarEventQueryVariables = Exact<{
  input?: InputMaybe<DeleteGoogleCalendarEventInput>
}>

export type DeleteGoogleCalendarEventQuery = {
  __typename?: 'Query'
  deleteGoogleCalendarEvent?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type UpdateGoogleCalendarEventQueryVariables = Exact<{
  input?: InputMaybe<UpdateGoogleCalendarEventInput>
}>

export type UpdateGoogleCalendarEventQuery = {
  __typename?: 'Query'
  updateGoogleCalendarEvent?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type CancelOutlookEventQueryVariables = Exact<{
  input?: InputMaybe<CancelOutlookEventInput>
}>

export type CancelOutlookEventQuery = {
  __typename?: 'Query'
  cancelOutlookEvent?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetMeetingTypesQueryVariables = Exact<{
  tenantId: Scalars['String']
}>

export type GetMeetingTypesQuery = {
  __typename?: 'Query'
  getMeetingTypes?:
    | {
        __typename?: 'GetMeetingTypeResponse'
        success?: boolean | null | undefined
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'MeetingTypes'
                  id?: string | null | undefined
                  tenantId?: string | null | undefined
                  duration?: string | null | undefined
                  label?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type SetUserAvailabilityMutationVariables = Exact<{
  input?: InputMaybe<SetUserAvailabilityInput>
}>

export type SetUserAvailabilityMutation = {
  __typename?: 'Mutation'
  setUserAvailability?:
    | {
        __typename?: 'SetUserAvailabilityResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'SetUserAvailabilityObj'
              createdAt?: string | null | undefined
              updatedAt?: string | null | undefined
              userId?: string | null | undefined
              tenantId?: string | null | undefined
              id?: string | null | undefined
              availabilityHours?:
                | {
                    __typename?: 'AvailabilityHoursObj'
                    duration?: Array<string> | null | undefined
                    timezone?: string | null | undefined
                    timeSlots?:
                      | Array<
                          | {
                              __typename?: 'TimeSlotsObj'
                              label?: string | null | undefined
                              value?: string | null | undefined
                              slots?:
                                | Array<
                                    | {
                                        __typename?: 'SlotOutput'
                                        startTime?: string | null | undefined
                                        endTime?: string | null | undefined
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetUserAvailabilityQueryVariables = Exact<{
  input: GetUserAvailabilityInput
}>

export type GetUserAvailabilityQuery = {
  __typename?: 'Query'
  getUserAvailability?:
    | {
        __typename?: 'GetUserAvailabilityResponse'
        meetingLocation?: Array<string | null | undefined> | null | undefined
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'GetUserAvailability'
              duration: string
              availability: Array<{
                __typename?: 'UserAvailability'
                date: string
                slots: Array<{
                  __typename?: 'SlotObj'
                  isAvailable?: boolean | null | undefined
                  slotStartTime?: string | null | undefined
                  slotEndTime?: string | null | undefined
                }>
              }>
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type SetUserMeetingMutationVariables = Exact<{
  input: SetUserMeetingInput
}>

export type SetUserMeetingMutation = {
  __typename?: 'Mutation'
  setUserMeeting?:
    | {
        __typename?: 'UserMeetingData'
        id?: string | null | undefined
        eventId?: string | null | undefined
        updatedAt?: string | null | undefined
        createdAt?: string | null | undefined
        participantId?: string | null | undefined
        tenantId?: string | null | undefined
        startTime?: string | null | undefined
        endTime?: string | null | undefined
        description?: string | null | undefined
        ownerId?: string | null | undefined
        inviteeId?: string | null | undefined
        title?: string | null | undefined
        timezone?: string | null | undefined
        role?: Array<string | null | undefined> | null | undefined
        status?: string | null | undefined
        location?: string | null | undefined
        videoLinkDescription?: string | null | undefined
        advisorName?: string | null | undefined
        organizerName?: string | null | undefined
        attendees?:
          | Array<{
              __typename?: 'CalendarEventAttendee'
              email: string
              responseStatus?: string | null | undefined
              optional?: boolean | null | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type UpdateUserCalendarEventMutationVariables = Exact<{
  input: UpdateUserCalendarEventInput
}>

export type UpdateUserCalendarEventMutation = {
  __typename?: 'Mutation'
  updateUserCalendarEvent?:
    | {
        __typename?: 'UpdateUserCalendarEventResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'UpdateUserCalendarEvent'
              title?: string | null | undefined
              eventId?: string | null | undefined
              location?: string | null | undefined
              tackleId?: string | null | undefined
              eventType?: string | null | undefined
              description?: string | null | undefined
              comment?: string | null | undefined
              videoLinkDescription?: string | null | undefined
              attendees?:
                | Array<
                    | {
                        __typename?: 'AttendeesType'
                        email?: string | null | undefined
                        organizer?: boolean | null | undefined
                        self?: boolean | null | undefined
                        responseStatus?: string | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type CancelUserCalendarEventMutationVariables = Exact<{
  input: CancelUserCalendarEventInput
}>

export type CancelUserCalendarEventMutation = {
  __typename?: 'Mutation'
  cancelUserCalendarEvent?:
    | {
        __typename?: 'CancelUserCalendarEventResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'CancelUserCalendarEvent'
              tackleId?: string | null | undefined
              eventId?: string | null | undefined
              eventType?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetUserAvailabilityHoursQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
}>

export type GetUserAvailabilityHoursQuery = {
  __typename?: 'Query'
  getUserAvailabilityHours?:
    | {
        __typename?: 'GetUserAvailabilityHoursResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'SetUserAvailabilityObj'
              createdAt?: string | null | undefined
              updatedAt?: string | null | undefined
              userId?: string | null | undefined
              tenantId?: string | null | undefined
              id?: string | null | undefined
              availabilityHours?:
                | {
                    __typename?: 'AvailabilityHoursObj'
                    duration?: Array<string> | null | undefined
                    timezone?: string | null | undefined
                    timeSlots?:
                      | Array<
                          | {
                              __typename?: 'TimeSlotsObj'
                              label?: string | null | undefined
                              value?: string | null | undefined
                              slots?:
                                | Array<
                                    | {
                                        __typename?: 'SlotOutput'
                                        startTime?: string | null | undefined
                                        endTime?: string | null | undefined
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type UpdateUserAvailabilityMutationVariables = Exact<{
  input?: InputMaybe<UpdateUserAvailabilityInput>
}>

export type UpdateUserAvailabilityMutation = {
  __typename?: 'Mutation'
  updateUserAvailability?:
    | {
        __typename?: 'UpdateUserAvailabilityResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'SetUserAvailabilityObj'
              createdAt?: string | null | undefined
              updatedAt?: string | null | undefined
              userId?: string | null | undefined
              tenantId?: string | null | undefined
              id?: string | null | undefined
              availabilityHours?:
                | {
                    __typename?: 'AvailabilityHoursObj'
                    duration?: Array<string> | null | undefined
                    timezone?: string | null | undefined
                    timeSlots?:
                      | Array<
                          | {
                              __typename?: 'TimeSlotsObj'
                              label?: string | null | undefined
                              value?: string | null | undefined
                              slots?:
                                | Array<
                                    | {
                                        __typename?: 'SlotOutput'
                                        startTime?: string | null | undefined
                                        endTime?: string | null | undefined
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetUserMeetingByIdQueryVariables = Exact<{
  input: GetUserMeetingByIdInput
}>

export type GetUserMeetingByIdQuery = {
  __typename?: 'Query'
  getUserMeetingById?:
    | {
        __typename?: 'GetUserMeetingByIdResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'CalendarEvent'
              summary?: string | null | undefined
              description?: string | null | undefined
              status?: string | null | undefined
              eventTime?: string | null | undefined
              meta?: string | null | undefined
              location?: string | null | undefined
              eventType?: string | null | undefined
              advisorProfiles?: string | null | undefined
              profile?: string | null | undefined
              timezone?: string | null | undefined
              eventId?: string | null | undefined
              inviteeId?: string | null | undefined
              advisorId?: string | null | undefined
              ownerId?: string | null | undefined
              availability?: string | null | undefined
              comment?: string | null | undefined
              videoLinkDescription?: string | null | undefined
              id?: string | null | undefined
              participantName?: string | null | undefined
              organizerName?: string | null | undefined
              participantFirstName?: string | null | undefined
              participantLastName?: string | null | undefined
              organizerFirstName?: string | null | undefined
              organizerLastName?: string | null | undefined
              start?:
                | {
                    __typename?: 'CalendarEventDate'
                    dateTime?: string | null | undefined
                    timeZone?: string | null | undefined
                  }
                | null
                | undefined
              end?:
                | {
                    __typename?: 'CalendarEventDate'
                    dateTime?: string | null | undefined
                    timeZone?: string | null | undefined
                  }
                | null
                | undefined
              attendees?:
                | Array<{
                    __typename?: 'CalendarEventAttendee'
                    email: string
                    responseStatus?: string | null | undefined
                    optional?: boolean | null | undefined
                  }>
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetAccountingChartsQueryVariables = Exact<{
  input: QueryAccountingReportInput
}>

export type GetAccountingChartsQuery = {
  __typename?: 'Query'
  getAccountingCharts?:
    | {
        __typename?: 'AccountingChartResponse'
        status: ChartStatus
        data: Array<
          | {
              __typename: 'CashBalanceByMonthReport'
              startDate?: string | null | undefined
              endDate?: string | null | undefined
              cashBalance?: number | null | undefined
              source?: string | null | undefined
            }
          | {
              __typename: 'CashBalanceReport'
              cashBalance?: number | null | undefined
              source?: string | null | undefined
              dateRange?: DateRanges | null | undefined
              endDate?: string | null | undefined
              growth?: number | null | undefined
              startDate?: string | null | undefined
            }
          | {
              __typename: 'LiquidCashReport'
              liquidCash?: number | null | undefined
              source?: string | null | undefined
              dateRange?: DateRanges | null | undefined
              endDate?: string | null | undefined
              growth?: number | null | undefined
              startDate?: string | null | undefined
            }
          | {
              __typename: 'ProfitAndLossReport'
              profitAndLossNetIncome?: number | null | undefined
              source?: string | null | undefined
              dateRange?: DateRanges | null | undefined
              endDate?: string | null | undefined
              growth?: number | null | undefined
              startDate?: string | null | undefined
            }
        >
      }
    | null
    | undefined
}

export type AccountingChartFragmentFragment = {
  __typename?: 'AccountingChartResponse'
  status: ChartStatus
  data: Array<
    | {
        __typename: 'CashBalanceByMonthReport'
        startDate?: string | null | undefined
        endDate?: string | null | undefined
        cashBalance?: number | null | undefined
        source?: string | null | undefined
      }
    | {
        __typename: 'CashBalanceReport'
        cashBalance?: number | null | undefined
        source?: string | null | undefined
        dateRange?: DateRanges | null | undefined
        endDate?: string | null | undefined
        growth?: number | null | undefined
        startDate?: string | null | undefined
      }
    | {
        __typename: 'LiquidCashReport'
        liquidCash?: number | null | undefined
        source?: string | null | undefined
        dateRange?: DateRanges | null | undefined
        endDate?: string | null | undefined
        growth?: number | null | undefined
        startDate?: string | null | undefined
      }
    | {
        __typename: 'ProfitAndLossReport'
        profitAndLossNetIncome?: number | null | undefined
        source?: string | null | undefined
        dateRange?: DateRanges | null | undefined
        endDate?: string | null | undefined
        growth?: number | null | undefined
        startDate?: string | null | undefined
      }
  >
}

type AccountingReportOutputFragment_CashBalanceByMonthReport_Fragment = {
  __typename?: 'CashBalanceByMonthReport'
  startDate?: string | null | undefined
  endDate?: string | null | undefined
  cashBalance?: number | null | undefined
  source?: string | null | undefined
}

type AccountingReportOutputFragment_CashBalanceReport_Fragment = {
  __typename?: 'CashBalanceReport'
  cashBalance?: number | null | undefined
  source?: string | null | undefined
  dateRange?: DateRanges | null | undefined
  endDate?: string | null | undefined
  growth?: number | null | undefined
  startDate?: string | null | undefined
}

type AccountingReportOutputFragment_LiquidCashReport_Fragment = {
  __typename?: 'LiquidCashReport'
  liquidCash?: number | null | undefined
  source?: string | null | undefined
  dateRange?: DateRanges | null | undefined
  endDate?: string | null | undefined
  growth?: number | null | undefined
  startDate?: string | null | undefined
}

type AccountingReportOutputFragment_ProfitAndLossReport_Fragment = {
  __typename?: 'ProfitAndLossReport'
  profitAndLossNetIncome?: number | null | undefined
  source?: string | null | undefined
  dateRange?: DateRanges | null | undefined
  endDate?: string | null | undefined
  growth?: number | null | undefined
  startDate?: string | null | undefined
}

export type AccountingReportOutputFragmentFragment =
  | AccountingReportOutputFragment_CashBalanceByMonthReport_Fragment
  | AccountingReportOutputFragment_CashBalanceReport_Fragment
  | AccountingReportOutputFragment_LiquidCashReport_Fragment
  | AccountingReportOutputFragment_ProfitAndLossReport_Fragment

export type LiquidCashReportFieldsFragment = {
  __typename?: 'LiquidCashReport'
  liquidCash?: number | null | undefined
  source?: string | null | undefined
  dateRange?: DateRanges | null | undefined
  endDate?: string | null | undefined
  growth?: number | null | undefined
  startDate?: string | null | undefined
}

export type CashBalanceReportFieldsFragment = {
  __typename?: 'CashBalanceReport'
  cashBalance?: number | null | undefined
  source?: string | null | undefined
  dateRange?: DateRanges | null | undefined
  endDate?: string | null | undefined
  growth?: number | null | undefined
  startDate?: string | null | undefined
}

export type ProfitAndLossReportFieldsFragment = {
  __typename?: 'ProfitAndLossReport'
  profitAndLossNetIncome?: number | null | undefined
  source?: string | null | undefined
  dateRange?: DateRanges | null | undefined
  endDate?: string | null | undefined
  growth?: number | null | undefined
  startDate?: string | null | undefined
}

export type CashBalanceByMonthReportFieldsFragment = {
  __typename?: 'CashBalanceByMonthReport'
  startDate?: string | null | undefined
  endDate?: string | null | undefined
  cashBalance?: number | null | undefined
  source?: string | null | undefined
}

export type CreateChatSessionFieldsFragment = { __typename?: 'ChatSession'; streamUrl: string }

export type CreateChatSessionMutationVariables = Exact<{
  input: CreateChatSessionInput
}>

export type CreateChatSessionMutation = {
  __typename?: 'Mutation'
  createChatSession?: { __typename?: 'ChatSession'; streamUrl: string } | null | undefined
}

export type UserTaskHowToResponseFieldsFragment = {
  __typename?: 'UserTaskHowToResponse'
  text: string
  videoLink?: string | null | undefined
}

export type SearchHowToDoTaskMutationVariables = Exact<{
  input: SearchHowToDoTaskInput
}>

export type SearchHowToDoTaskMutation = {
  __typename?: 'Mutation'
  searchHowToDoTask: {
    __typename?: 'UserTaskHowToResponse'
    text: string
    videoLink?: string | null | undefined
  }
}

export type SendMessageToBotFieldsFragment = {
  __typename?: 'SendMessageToBotResponse'
  response: string
  nextFlow?: string | null | undefined
  isFinished?: boolean | null | undefined
  flowType?: string | null | undefined
}

export type SendMessageToBotMutationVariables = Exact<{
  input: SendMessageToBotInput
}>

export type SendMessageToBotMutation = {
  __typename?: 'Mutation'
  sendMessageToBot: {
    __typename?: 'SendMessageToBotResponse'
    response: string
    nextFlow?: string | null | undefined
    isFinished?: boolean | null | undefined
    flowType?: string | null | undefined
  }
}

export type GetChatSessionsFieldsFragment = {
  __typename?: 'MessageChatSession'
  id: string
  flow?: string | null | undefined
  createdAt?: string | null | undefined
  title?: string | null | undefined
}

export type GetChatSessionsQueryVariables = Exact<{ [key: string]: never }>

export type GetChatSessionsQuery = {
  __typename?: 'Query'
  getChatSessions?:
    | Array<{
        __typename?: 'MessageChatSession'
        id: string
        flow?: string | null | undefined
        createdAt?: string | null | undefined
        title?: string | null | undefined
      }>
    | null
    | undefined
}

export type CreateFlowChatSessionMutationVariables = Exact<{
  input: CreateFlowChatSessionInput
}>

export type CreateFlowChatSessionMutation = {
  __typename?: 'Mutation'
  createFlowChatSession: {
    __typename?: 'MessageChatSession'
    id: string
    flow?: string | null | undefined
    createdAt?: string | null | undefined
    title?: string | null | undefined
  }
}

export type SummarySessionTitleMutationVariables = Exact<{
  id: Scalars['String']
}>

export type SummarySessionTitleMutation = {
  __typename?: 'Mutation'
  summarySessionTitle: {
    __typename?: 'MessageChatSession'
    id: string
    flow?: string | null | undefined
    createdAt?: string | null | undefined
    title?: string | null | undefined
  }
}

export type AdvisorDashboardFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type TopActiveClientsFieldsFragment = {
  __typename?: 'GetTopActiveClientsResponse'
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'TopActiveClient'
            id?: string | null | undefined
            firstName?: string | null | undefined
            lastName?: string | null | undefined
            tenantId?: string | null | undefined
            email?: string | null | undefined
            score?: number | null | undefined
            totalTime?: number | null | undefined
            topGoal?: string | null | undefined
            industry?: string | null | undefined
            businessName?: string | null | undefined
            logo?:
              | {
                  __typename?: 'UserFile'
                  id?: string | null | undefined
                  name?: string | null | undefined
                  size?: string | null | undefined
                  tenantId?: string | null | undefined
                  type?: string | null | undefined
                  url?: string | null | undefined
                  userId?: string | null | undefined
                }
              | null
              | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type GetUserToolsFieldsFragment = {
  __typename?: 'GetUserToolsResponse'
  success?: boolean | null | undefined
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'Tool'
            id?: string | null | undefined
            cyclrToolId?: string | null | undefined
            imageUrl?: string | null | undefined
            toolName?: string | null | undefined
            toolDisplayName?: string | null | undefined
            userToolsImageUrl?: string | null | undefined
            connectionFormImageUrl?: string | null | undefined
            toolUrl?: string | null | undefined
            isConnected?: boolean | null | undefined
            isRecommended?: boolean | null | undefined
            recommendedBy?: string | null | undefined
            isSelected?: boolean | null | undefined
            group?: string | null | undefined
            category?: string | null | undefined
            visible?: boolean | null | undefined
            connectionStatus?: string | null | undefined
            connectorName?: string | null | undefined
            isBookmarked?: boolean | null | undefined
            supportLevel?: ToolSupportLevel | null | undefined
            description?: string | null | undefined
            diagnosticSupported?: boolean | null | undefined
            longDescription?: string | null | undefined
            toolHelpText?: string | null | undefined
            relatedPlays?: Array<string | null | undefined> | null | undefined
            authType?: ToolAuthType | null | undefined
            options?:
              | {
                  __typename?: 'ToolConnectionOptions'
                  connectionForm?:
                    | {
                        __typename?: 'ToolConnectionForm'
                        description: string
                        inputProperties: Array<{
                          __typename?: 'ToolConnectionFormInputProperty'
                          name: string
                          displayName: string
                          placeholder?: string | null | undefined
                          type: string
                          validationRules: Array<string>
                        }>
                      }
                    | null
                    | undefined
                }
              | null
              | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type BookmarkToolFieldsFragment = {
  __typename?: 'BookmarkToolResponse'
  success?: boolean | null | undefined
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'Tool'
        id?: string | null | undefined
        cyclrToolId?: string | null | undefined
        imageUrl?: string | null | undefined
        toolName?: string | null | undefined
        toolDisplayName?: string | null | undefined
        userToolsImageUrl?: string | null | undefined
        connectionFormImageUrl?: string | null | undefined
        toolUrl?: string | null | undefined
        isConnected?: boolean | null | undefined
        isRecommended?: boolean | null | undefined
        recommendedBy?: string | null | undefined
        isSelected?: boolean | null | undefined
        group?: string | null | undefined
        category?: string | null | undefined
        visible?: boolean | null | undefined
        connectionStatus?: string | null | undefined
        connectorName?: string | null | undefined
        isBookmarked?: boolean | null | undefined
        supportLevel?: ToolSupportLevel | null | undefined
        description?: string | null | undefined
        diagnosticSupported?: boolean | null | undefined
        longDescription?: string | null | undefined
        toolHelpText?: string | null | undefined
        options?:
          | {
              __typename?: 'ToolConnectionOptions'
              connectionForm?:
                | {
                    __typename?: 'ToolConnectionForm'
                    description: string
                    inputProperties: Array<{
                      __typename?: 'ToolConnectionFormInputProperty'
                      name: string
                      displayName: string
                      placeholder?: string | null | undefined
                      type: string
                      validationRules: Array<string>
                    }>
                  }
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type ClientsFieldsFragment = {
  __typename?: 'GetClientsResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'GetClientsResponseData'
            id: string
            name?: string | null | undefined
            firstName?: string | null | undefined
            lastName?: string | null | undefined
            email: string
            createdAt: string
            updatedAt: string
            websiteURL?: string | null | undefined
            bio?: string | null | undefined
            roles?: Array<string | null | undefined> | null | undefined
            isNewUser?: boolean | null | undefined
            businessName?: string | null | undefined
            mobileContactNumber?: string | null | undefined
            yearsInBusiness?: string | null | undefined
            companyAdvisor?: string | null | undefined
            schedulingUrl?: string | null | undefined
            calendlyEventsUrl?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type TackleMeetingFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type ActivityLogFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type OwnerActivityDetailsFieldsFragment = {
  __typename?: 'OwnerActivitySummary'
  timesSignedIn?: number | null | undefined
  timeOnPlatform?: string | null | undefined
  toolsIntegrated?: number | null | undefined
  chats?: number | null | undefined
  meetingsScheduled?: number | null | undefined
  meetingsReScheduled?: number | null | undefined
  signInPerWeek?: number | null | undefined
  avgMinsPerSession?: number | null | undefined
}

export type UserIntegrationFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type TackleMeetingsFieldsFragment = {
  __typename?: 'GetTackleMeetingResponse'
  success: boolean
  message?: string | null | undefined
  pagination?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'CalendarEvent'
            summary?: string | null | undefined
            description?: string | null | undefined
            status?: string | null | undefined
            eventTime?: string | null | undefined
            meta?: string | null | undefined
            location?: string | null | undefined
            eventType?: string | null | undefined
            advisorProfiles?: string | null | undefined
            profile?: string | null | undefined
            timezone?: string | null | undefined
            eventId?: string | null | undefined
            inviteeId?: string | null | undefined
            advisorId?: string | null | undefined
            availability?: string | null | undefined
            comment?: string | null | undefined
            videoLinkDescription?: string | null | undefined
            organizerName?: string | null | undefined
            participantName?: string | null | undefined
            ownerId?: string | null | undefined
            participantFirstName?: string | null | undefined
            participantLastName?: string | null | undefined
            organizerFirstName?: string | null | undefined
            organizerLastName?: string | null | undefined
            start?:
              | {
                  __typename?: 'CalendarEventDate'
                  dateTime?: string | null | undefined
                  timeZone?: string | null | undefined
                }
              | null
              | undefined
            end?:
              | {
                  __typename?: 'CalendarEventDate'
                  dateTime?: string | null | undefined
                  timeZone?: string | null | undefined
                }
              | null
              | undefined
            attendees?:
              | Array<{
                  __typename?: 'CalendarEventAttendee'
                  email: string
                  responseStatus?: string | null | undefined
                  optional?: boolean | null | undefined
                }>
              | null
              | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type DeauthenticateConnectorFieldsFragment = {
  __typename?: 'ApiResponse'
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetAllToolsFieldsFragment = {
  __typename?: 'GetAllToolsResponse'
  success?: boolean | null | undefined
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'Tool'
            id?: string | null | undefined
            toolDisplayName?: string | null | undefined
            cyclrToolId?: string | null | undefined
            imageUrl?: string | null | undefined
            toolName?: string | null | undefined
            userToolsImageUrl?: string | null | undefined
            connectionFormImageUrl?: string | null | undefined
            toolUrl?: string | null | undefined
            isConnected?: boolean | null | undefined
            isRecommended?: boolean | null | undefined
            recommendedBy?: string | null | undefined
            isSelected?: boolean | null | undefined
            group?: string | null | undefined
            category?: string | null | undefined
            visible?: boolean | null | undefined
            supportLevel?: ToolSupportLevel | null | undefined
            relatedPlays?: Array<string | null | undefined> | null | undefined
            isBookmarked?: boolean | null | undefined
            description?: string | null | undefined
            diagnosticSupported?: boolean | null | undefined
            longDescription?: string | null | undefined
            toolHelpText?: string | null | undefined
            authType?: ToolAuthType | null | undefined
            options?:
              | {
                  __typename?: 'ToolConnectionOptions'
                  connectionForm?:
                    | {
                        __typename?: 'ToolConnectionForm'
                        description: string
                        inputProperties: Array<{
                          __typename?: 'ToolConnectionFormInputProperty'
                          name: string
                          displayName: string
                          placeholder?: string | null | undefined
                          type: string
                          validationRules: Array<string>
                        }>
                      }
                    | null
                    | undefined
                }
              | null
              | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type GetAttestationReportsFieldsFragment = {
  __typename?: 'GetAttestationReportsResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'GetAttestationReportsResponseObj'
        lastEvaluatedKey?: string | null | undefined
        prevEvaluatedKey?: string | null | undefined
        totalPages?: number | null | undefined
        totalRecords?: number | null | undefined
        pageSize?: number | null | undefined
        currentPage?: number | null | undefined
        items?:
          | Array<
              | {
                  __typename?: 'Attestation'
                  month?: string | null | undefined
                  year?: string | null | undefined
                  contractors?: string | null | undefined
                  businessBankAccount?: string | null | undefined
                  jobsCreated?: string | null | undefined
                  volunteers?: string | null | undefined
                  partTimeEmployees?: string | null | undefined
                  fullTimeEmployees?: string | null | undefined
                  gender?: string | null | undefined
                  race?: string | null | undefined
                  education?: string | null | undefined
                  wages?: string | null | undefined
                  revenue?: string | null | undefined
                  debtFinancing?: string | null | undefined
                  financialGrowth?: string | null | undefined
                  newCustomer?: string | null | undefined
                  productSale?: string | null | undefined
                  serviceSale?: string | null | undefined
                  targetAudience?: string | null | undefined
                  physicalLocation?: string | null | undefined
                  businessName?: string | null | undefined
                  name?: string | null | undefined
                  signedDocumentUrl?: string | null | undefined
                  properName?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type UpdateOwnerFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetAdvisorDashboardQueryVariables = Exact<{
  filterType?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}>

export type GetAdvisorDashboardQuery = {
  __typename?: 'Query'
  getAdvisorDashboard?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetTopActiveClientsQueryVariables = Exact<{
  tenantId?: InputMaybe<Scalars['String']>
}>

export type GetTopActiveClientsQuery = {
  __typename?: 'Query'
  getTopActiveClients?:
    | {
        __typename?: 'GetTopActiveClientsResponse'
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'TopActiveClient'
                  id?: string | null | undefined
                  firstName?: string | null | undefined
                  lastName?: string | null | undefined
                  tenantId?: string | null | undefined
                  email?: string | null | undefined
                  score?: number | null | undefined
                  totalTime?: number | null | undefined
                  topGoal?: string | null | undefined
                  industry?: string | null | undefined
                  businessName?: string | null | undefined
                  logo?:
                    | {
                        __typename?: 'UserFile'
                        id?: string | null | undefined
                        name?: string | null | undefined
                        size?: string | null | undefined
                        tenantId?: string | null | undefined
                        type?: string | null | undefined
                        url?: string | null | undefined
                        userId?: string | null | undefined
                      }
                    | null
                    | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetClientsQueryVariables = Exact<{
  input?: InputMaybe<GetClientsInput>
}>

export type GetClientsQuery = {
  __typename?: 'Query'
  getClients?:
    | {
        __typename?: 'GetClientsResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'GetClientsResponseData'
                  id: string
                  name?: string | null | undefined
                  firstName?: string | null | undefined
                  lastName?: string | null | undefined
                  email: string
                  createdAt: string
                  updatedAt: string
                  websiteURL?: string | null | undefined
                  bio?: string | null | undefined
                  roles?: Array<string | null | undefined> | null | undefined
                  isNewUser?: boolean | null | undefined
                  businessName?: string | null | undefined
                  mobileContactNumber?: string | null | undefined
                  yearsInBusiness?: string | null | undefined
                  companyAdvisor?: string | null | undefined
                  schedulingUrl?: string | null | undefined
                  calendlyEventsUrl?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetOwnerActivityDetailsQueryVariables = Exact<{
  ownerId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}>

export type GetOwnerActivityDetailsQuery = {
  __typename?: 'Query'
  getOwnerActivityDetails?:
    | {
        __typename?: 'OwnerActivitySummary'
        timesSignedIn?: number | null | undefined
        timeOnPlatform?: string | null | undefined
        toolsIntegrated?: number | null | undefined
        chats?: number | null | undefined
        meetingsScheduled?: number | null | undefined
        meetingsReScheduled?: number | null | undefined
        signInPerWeek?: number | null | undefined
        avgMinsPerSession?: number | null | undefined
      }
    | null
    | undefined
}

export type GetActivityLogsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}>

export type GetActivityLogsQuery = {
  __typename?: 'Query'
  getActivityLogs?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetUserIntegrationsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}>

export type GetUserIntegrationsQuery = {
  __typename?: 'Query'
  getUserIntegrations?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetTackleMeetingsQueryVariables = Exact<{
  input?: InputMaybe<GetTackleMeetingsInput>
}>

export type GetTackleMeetingsQuery = {
  __typename?: 'Query'
  getTackleMeetings?:
    | {
        __typename?: 'GetTackleMeetingResponse'
        success: boolean
        message?: string | null | undefined
        pagination?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'CalendarEvent'
                  summary?: string | null | undefined
                  description?: string | null | undefined
                  status?: string | null | undefined
                  eventTime?: string | null | undefined
                  meta?: string | null | undefined
                  location?: string | null | undefined
                  eventType?: string | null | undefined
                  advisorProfiles?: string | null | undefined
                  profile?: string | null | undefined
                  timezone?: string | null | undefined
                  eventId?: string | null | undefined
                  inviteeId?: string | null | undefined
                  advisorId?: string | null | undefined
                  availability?: string | null | undefined
                  comment?: string | null | undefined
                  videoLinkDescription?: string | null | undefined
                  organizerName?: string | null | undefined
                  participantName?: string | null | undefined
                  ownerId?: string | null | undefined
                  participantFirstName?: string | null | undefined
                  participantLastName?: string | null | undefined
                  organizerFirstName?: string | null | undefined
                  organizerLastName?: string | null | undefined
                  start?:
                    | {
                        __typename?: 'CalendarEventDate'
                        dateTime?: string | null | undefined
                        timeZone?: string | null | undefined
                      }
                    | null
                    | undefined
                  end?:
                    | {
                        __typename?: 'CalendarEventDate'
                        dateTime?: string | null | undefined
                        timeZone?: string | null | undefined
                      }
                    | null
                    | undefined
                  attendees?:
                    | Array<{
                        __typename?: 'CalendarEventAttendee'
                        email: string
                        responseStatus?: string | null | undefined
                        optional?: boolean | null | undefined
                      }>
                    | null
                    | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type DeauthenticateConnectorMutationVariables = Exact<{
  input?: InputMaybe<DeauthenticateConnectorInput>
}>

export type DeauthenticateConnectorMutation = {
  __typename?: 'Mutation'
  deauthenticateConnector?:
    | {
        __typename?: 'ApiResponse'
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetAllToolsQueryVariables = Exact<{
  tenantId?: InputMaybe<Scalars['String']>
}>

export type GetAllToolsQuery = {
  __typename?: 'Query'
  getAllTools?:
    | {
        __typename?: 'GetAllToolsResponse'
        success?: boolean | null | undefined
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'Tool'
                  id?: string | null | undefined
                  toolDisplayName?: string | null | undefined
                  cyclrToolId?: string | null | undefined
                  imageUrl?: string | null | undefined
                  toolName?: string | null | undefined
                  userToolsImageUrl?: string | null | undefined
                  connectionFormImageUrl?: string | null | undefined
                  toolUrl?: string | null | undefined
                  isConnected?: boolean | null | undefined
                  isRecommended?: boolean | null | undefined
                  recommendedBy?: string | null | undefined
                  isSelected?: boolean | null | undefined
                  group?: string | null | undefined
                  category?: string | null | undefined
                  visible?: boolean | null | undefined
                  supportLevel?: ToolSupportLevel | null | undefined
                  relatedPlays?: Array<string | null | undefined> | null | undefined
                  isBookmarked?: boolean | null | undefined
                  description?: string | null | undefined
                  diagnosticSupported?: boolean | null | undefined
                  longDescription?: string | null | undefined
                  toolHelpText?: string | null | undefined
                  authType?: ToolAuthType | null | undefined
                  options?:
                    | {
                        __typename?: 'ToolConnectionOptions'
                        connectionForm?:
                          | {
                              __typename?: 'ToolConnectionForm'
                              description: string
                              inputProperties: Array<{
                                __typename?: 'ToolConnectionFormInputProperty'
                                name: string
                                displayName: string
                                placeholder?: string | null | undefined
                                type: string
                                validationRules: Array<string>
                              }>
                            }
                          | null
                          | undefined
                      }
                    | null
                    | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type UpdateOwnerMutationVariables = Exact<{
  input?: InputMaybe<UpdateOwnerInput>
}>

export type UpdateOwnerMutation = {
  __typename?: 'Mutation'
  updateOwner?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetUserToolsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}>

export type GetUserToolsQuery = {
  __typename?: 'Query'
  getUserTools?:
    | {
        __typename?: 'GetUserToolsResponse'
        success?: boolean | null | undefined
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'Tool'
                  id?: string | null | undefined
                  cyclrToolId?: string | null | undefined
                  imageUrl?: string | null | undefined
                  toolName?: string | null | undefined
                  toolDisplayName?: string | null | undefined
                  userToolsImageUrl?: string | null | undefined
                  connectionFormImageUrl?: string | null | undefined
                  toolUrl?: string | null | undefined
                  isConnected?: boolean | null | undefined
                  isRecommended?: boolean | null | undefined
                  recommendedBy?: string | null | undefined
                  isSelected?: boolean | null | undefined
                  group?: string | null | undefined
                  category?: string | null | undefined
                  visible?: boolean | null | undefined
                  connectionStatus?: string | null | undefined
                  connectorName?: string | null | undefined
                  isBookmarked?: boolean | null | undefined
                  supportLevel?: ToolSupportLevel | null | undefined
                  description?: string | null | undefined
                  diagnosticSupported?: boolean | null | undefined
                  longDescription?: string | null | undefined
                  toolHelpText?: string | null | undefined
                  relatedPlays?: Array<string | null | undefined> | null | undefined
                  authType?: ToolAuthType | null | undefined
                  options?:
                    | {
                        __typename?: 'ToolConnectionOptions'
                        connectionForm?:
                          | {
                              __typename?: 'ToolConnectionForm'
                              description: string
                              inputProperties: Array<{
                                __typename?: 'ToolConnectionFormInputProperty'
                                name: string
                                displayName: string
                                placeholder?: string | null | undefined
                                type: string
                                validationRules: Array<string>
                              }>
                            }
                          | null
                          | undefined
                      }
                    | null
                    | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetAttestationReportsQueryVariables = Exact<{
  input: GetAttestationReportsInput
}>

export type GetAttestationReportsQuery = {
  __typename?: 'Query'
  getAttestationReports?:
    | {
        __typename?: 'GetAttestationReportsResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'GetAttestationReportsResponseObj'
              lastEvaluatedKey?: string | null | undefined
              prevEvaluatedKey?: string | null | undefined
              totalPages?: number | null | undefined
              totalRecords?: number | null | undefined
              pageSize?: number | null | undefined
              currentPage?: number | null | undefined
              items?:
                | Array<
                    | {
                        __typename?: 'Attestation'
                        month?: string | null | undefined
                        year?: string | null | undefined
                        contractors?: string | null | undefined
                        businessBankAccount?: string | null | undefined
                        jobsCreated?: string | null | undefined
                        volunteers?: string | null | undefined
                        partTimeEmployees?: string | null | undefined
                        fullTimeEmployees?: string | null | undefined
                        gender?: string | null | undefined
                        race?: string | null | undefined
                        education?: string | null | undefined
                        wages?: string | null | undefined
                        revenue?: string | null | undefined
                        debtFinancing?: string | null | undefined
                        financialGrowth?: string | null | undefined
                        newCustomer?: string | null | undefined
                        productSale?: string | null | undefined
                        serviceSale?: string | null | undefined
                        targetAudience?: string | null | undefined
                        physicalLocation?: string | null | undefined
                        businessName?: string | null | undefined
                        name?: string | null | undefined
                        signedDocumentUrl?: string | null | undefined
                        properName?: string | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type ToolConnectionSuccessMutationVariables = Exact<{
  input?: InputMaybe<ToolConnectionSuccessInput>
}>

export type ToolConnectionSuccessMutation = {
  __typename?: 'Mutation'
  toolConnectionSuccess?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type BookmarkToolMutationVariables = Exact<{
  input: BookmarkToolInput
}>

export type BookmarkToolMutation = {
  __typename?: 'Mutation'
  bookmarkTool?:
    | {
        __typename?: 'BookmarkToolResponse'
        success?: boolean | null | undefined
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'Tool'
              id?: string | null | undefined
              cyclrToolId?: string | null | undefined
              imageUrl?: string | null | undefined
              toolName?: string | null | undefined
              toolDisplayName?: string | null | undefined
              userToolsImageUrl?: string | null | undefined
              connectionFormImageUrl?: string | null | undefined
              toolUrl?: string | null | undefined
              isConnected?: boolean | null | undefined
              isRecommended?: boolean | null | undefined
              recommendedBy?: string | null | undefined
              isSelected?: boolean | null | undefined
              group?: string | null | undefined
              category?: string | null | undefined
              visible?: boolean | null | undefined
              connectionStatus?: string | null | undefined
              connectorName?: string | null | undefined
              isBookmarked?: boolean | null | undefined
              supportLevel?: ToolSupportLevel | null | undefined
              description?: string | null | undefined
              diagnosticSupported?: boolean | null | undefined
              longDescription?: string | null | undefined
              toolHelpText?: string | null | undefined
              options?:
                | {
                    __typename?: 'ToolConnectionOptions'
                    connectionForm?:
                      | {
                          __typename?: 'ToolConnectionForm'
                          description: string
                          inputProperties: Array<{
                            __typename?: 'ToolConnectionFormInputProperty'
                            name: string
                            displayName: string
                            placeholder?: string | null | undefined
                            type: string
                            validationRules: Array<string>
                          }>
                        }
                      | null
                      | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type ProcessToolBusinessDataMutationVariables = Exact<{
  input: ProcessToolBusinessDataInput
}>

export type ProcessToolBusinessDataMutation = {
  __typename?: 'Mutation'
  processToolBusinessData: string
}

export type GoalDetailsFieldsFragment = {
  __typename?: 'Goal'
  id: string
  name?: string | null | undefined
  description?: string | null | undefined
  isTopGoal?: boolean | null | undefined
  isActiveGoal?: boolean | null | undefined
  assessments?: string | null | undefined
  createdAt?: string | null | undefined
  orderOfSequence?: string | null | undefined
}

export type GetGoalDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>
}>

export type GetGoalDetailsQuery = {
  __typename?: 'Query'
  getGoalDetails?:
    | {
        __typename?: 'Goal'
        id: string
        name?: string | null | undefined
        description?: string | null | undefined
        isTopGoal?: boolean | null | undefined
        isActiveGoal?: boolean | null | undefined
        assessments?: string | null | undefined
        createdAt?: string | null | undefined
        orderOfSequence?: string | null | undefined
      }
    | null
    | undefined
}

export type GetUserGoalsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}>

export type GetUserGoalsQuery = {
  __typename?: 'Query'
  getUserGoals?:
    | Array<
        | {
            __typename?: 'Goal'
            id: string
            name?: string | null | undefined
            description?: string | null | undefined
            isTopGoal?: boolean | null | undefined
            isActiveGoal?: boolean | null | undefined
            assessments?: string | null | undefined
            createdAt?: string | null | undefined
            orderOfSequence?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type GetPendingJobsFieldsFragment = {
  __typename?: 'JobDto'
  id: string
  status: JobStatus
  type: JobTypes
  createdAt?: string | null | undefined
  updatedAt?: string | null | undefined
  payload?: string | null | undefined
}

export type GetPendingJobsQueryVariables = Exact<{ [key: string]: never }>

export type GetPendingJobsQuery = {
  __typename?: 'Query'
  getPendingJobs: Array<{
    __typename?: 'JobDto'
    id: string
    status: JobStatus
    type: JobTypes
    createdAt?: string | null | undefined
    updatedAt?: string | null | undefined
    payload?: string | null | undefined
  }>
}

export type ChartDataFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetChartDataQueryVariables = Exact<{
  input: GetChartDataInput
}>

export type GetChartDataQuery = {
  __typename?: 'Query'
  getChartData?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type OwnerBusinessDataFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetOwnerBusinessDataQueryVariables = Exact<{
  input?: InputMaybe<GetOwnerBusinessDataInput>
}>

export type GetOwnerBusinessDataQuery = {
  __typename?: 'Query'
  getOwnerBusinessData?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetPlayFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetPlaysFieldsFragment = {
  __typename?: 'GetPlaysResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'Play'
            id: string
            content?: string | null | undefined
            title?: string | null | undefined
            type?: string | null | undefined
            recommendedBy?: string | null | undefined
            meta?: string | null | undefined
            guide?: string | null | undefined
            guide1?: string | null | undefined
            setup?: string | null | undefined
            use?: string | null | undefined
            description?: string | null | undefined
            playTabs?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type AuthorizeConnectorFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type PlayResponseFieldsFragment = {
  __typename?: 'PlayResponse'
  tenantId?: string | null | undefined
  playId?: string | null | undefined
  playType?: string | null | undefined
  isCompleted?: boolean | null | undefined
  completedAt?: string | null | undefined
  completedBy?: string | null | undefined
  recommendations?: Array<string> | null | undefined
  businessProfileFields?:
    | { __typename?: 'BusinessProfileFields'; logo?: string | null | undefined }
    | null
    | undefined
  playTabResponse?:
    | Array<{
        __typename?: 'TabResponse'
        id?: string | null | undefined
        tabName?: string | null | undefined
        isCompleted?: boolean | null | undefined
        completedAt?: string | null | undefined
        stepResponse?:
          | Array<{
              __typename?: 'StepResponse'
              id?: string | null | undefined
              stepTitle?: string | null | undefined
              isCompleted?: boolean | null | undefined
              completedAt?: string | null | undefined
              stepData?:
                | Array<{
                    __typename?: 'StepData'
                    id?: string | null | undefined
                    value?: Array<string> | null | undefined
                    label?: Array<string> | null | undefined
                    isCompleted?: boolean | null | undefined
                    completedAt?: string | null | undefined
                    recommendations?: Array<string> | null | undefined
                    isMeetingScheduled?: boolean | null | undefined
                    businessProfileFields?:
                      | {
                          __typename?: 'StepBusinessProfileFields'
                          logo?:
                            | {
                                __typename?: 'FileStructure'
                                id?: string | null | undefined
                                name?: string | null | undefined
                                size?: string | null | undefined
                                tenantId?: string | null | undefined
                                type?: string | null | undefined
                                url?: string | null | undefined
                                userId?: string | null | undefined
                              }
                            | null
                            | undefined
                        }
                      | null
                      | undefined
                  }>
                | null
                | undefined
            }>
          | null
          | undefined
      }>
    | null
    | undefined
}

export type GetPlayQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>
}>

export type GetPlayQuery = {
  __typename?: 'Query'
  getPlay?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetPlaysQueryVariables = Exact<{
  input?: InputMaybe<GetPlaysInput>
}>

export type GetPlaysQuery = {
  __typename?: 'Query'
  getPlays?:
    | {
        __typename?: 'GetPlaysResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'Play'
                  id: string
                  content?: string | null | undefined
                  title?: string | null | undefined
                  type?: string | null | undefined
                  recommendedBy?: string | null | undefined
                  meta?: string | null | undefined
                  guide?: string | null | undefined
                  guide1?: string | null | undefined
                  setup?: string | null | undefined
                  use?: string | null | undefined
                  description?: string | null | undefined
                  playTabs?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetPlayResponseQueryVariables = Exact<{
  input: GetPlayResponseInput
}>

export type GetPlayResponseQuery = {
  __typename?: 'Query'
  getPlayResponse?:
    | Array<{
        __typename?: 'PlayResponse'
        tenantId?: string | null | undefined
        playId?: string | null | undefined
        playType?: string | null | undefined
        isCompleted?: boolean | null | undefined
        completedAt?: string | null | undefined
        completedBy?: string | null | undefined
        recommendations?: Array<string> | null | undefined
        businessProfileFields?:
          | { __typename?: 'BusinessProfileFields'; logo?: string | null | undefined }
          | null
          | undefined
        playTabResponse?:
          | Array<{
              __typename?: 'TabResponse'
              id?: string | null | undefined
              tabName?: string | null | undefined
              isCompleted?: boolean | null | undefined
              completedAt?: string | null | undefined
              stepResponse?:
                | Array<{
                    __typename?: 'StepResponse'
                    id?: string | null | undefined
                    stepTitle?: string | null | undefined
                    isCompleted?: boolean | null | undefined
                    completedAt?: string | null | undefined
                    stepData?:
                      | Array<{
                          __typename?: 'StepData'
                          id?: string | null | undefined
                          value?: Array<string> | null | undefined
                          label?: Array<string> | null | undefined
                          isCompleted?: boolean | null | undefined
                          completedAt?: string | null | undefined
                          recommendations?: Array<string> | null | undefined
                          isMeetingScheduled?: boolean | null | undefined
                          businessProfileFields?:
                            | {
                                __typename?: 'StepBusinessProfileFields'
                                logo?:
                                  | {
                                      __typename?: 'FileStructure'
                                      id?: string | null | undefined
                                      name?: string | null | undefined
                                      size?: string | null | undefined
                                      tenantId?: string | null | undefined
                                      type?: string | null | undefined
                                      url?: string | null | undefined
                                      userId?: string | null | undefined
                                    }
                                  | null
                                  | undefined
                              }
                            | null
                            | undefined
                        }>
                      | null
                      | undefined
                  }>
                | null
                | undefined
            }>
          | null
          | undefined
      }>
    | null
    | undefined
}

export type SavePlayResponseMutationVariables = Exact<{
  input: SavePlayResponseInput
}>

export type SavePlayResponseMutation = {
  __typename?: 'Mutation'
  savePlayResponse?:
    | {
        __typename?: 'PlayResponse'
        tenantId?: string | null | undefined
        playId?: string | null | undefined
        playType?: string | null | undefined
        isCompleted?: boolean | null | undefined
        completedAt?: string | null | undefined
        completedBy?: string | null | undefined
        recommendations?: Array<string> | null | undefined
        businessProfileFields?:
          | { __typename?: 'BusinessProfileFields'; logo?: string | null | undefined }
          | null
          | undefined
        playTabResponse?:
          | Array<{
              __typename?: 'TabResponse'
              id?: string | null | undefined
              tabName?: string | null | undefined
              isCompleted?: boolean | null | undefined
              completedAt?: string | null | undefined
              stepResponse?:
                | Array<{
                    __typename?: 'StepResponse'
                    id?: string | null | undefined
                    stepTitle?: string | null | undefined
                    isCompleted?: boolean | null | undefined
                    completedAt?: string | null | undefined
                    stepData?:
                      | Array<{
                          __typename?: 'StepData'
                          id?: string | null | undefined
                          value?: Array<string> | null | undefined
                          label?: Array<string> | null | undefined
                          isCompleted?: boolean | null | undefined
                          completedAt?: string | null | undefined
                          recommendations?: Array<string> | null | undefined
                          isMeetingScheduled?: boolean | null | undefined
                          businessProfileFields?:
                            | {
                                __typename?: 'StepBusinessProfileFields'
                                logo?:
                                  | {
                                      __typename?: 'FileStructure'
                                      id?: string | null | undefined
                                      name?: string | null | undefined
                                      size?: string | null | undefined
                                      tenantId?: string | null | undefined
                                      type?: string | null | undefined
                                      url?: string | null | undefined
                                      userId?: string | null | undefined
                                    }
                                  | null
                                  | undefined
                              }
                            | null
                            | undefined
                        }>
                      | null
                      | undefined
                  }>
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type AuthorizeConnectorMutationVariables = Exact<{
  authValue: Scalars['String']
}>

export type AuthorizeConnectorMutation = {
  __typename?: 'Mutation'
  authorizeConnector?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type DashboardListingFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type AssessmentPlayListingFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetDashboardListingQueryVariables = Exact<{
  tenantId: Scalars['String']
  category?: InputMaybe<Scalars['String']>
}>

export type GetDashboardListingQuery = {
  __typename?: 'Query'
  getDashboardListing?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetAssessmentPlayListingQueryVariables = Exact<{
  tenantId: Scalars['String']
}>

export type GetAssessmentPlayListingQuery = {
  __typename?: 'Query'
  getAssessmentPlayListing?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type OnTaskProgressUpdatedSubscriptionVariables = Exact<{
  id: Scalars['ID']
}>

export type OnTaskProgressUpdatedSubscription = {
  __typename?: 'Subscription'
  onTaskProgressUpdated?:
    | {
        __typename?: 'TaskResult'
        id?: string | null | undefined
        status?: string | null | undefined
        payload?: string | null | undefined
      }
    | null
    | undefined
}

export type GetToolsFieldsFragment = {
  __typename?: 'GetToolsResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'Tool'
            id?: string | null | undefined
            toolName?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type GetToolsQueryVariables = Exact<{ [key: string]: never }>

export type GetToolsQuery = {
  __typename?: 'Query'
  getTools?:
    | {
        __typename?: 'GetToolsResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'Tool'
                  id?: string | null | undefined
                  toolName?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type ToolFieldsFragment = {
  __typename?: 'GetToolsByTypeResponse'
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'Tool'
            id?: string | null | undefined
            cyclrToolId?: string | null | undefined
            imageUrl?: string | null | undefined
            toolName?: string | null | undefined
            userToolsImageUrl?: string | null | undefined
            toolUrl?: string | null | undefined
            isConnected?: boolean | null | undefined
            isRecommended?: boolean | null | undefined
            recommendedBy?: string | null | undefined
            isSelected?: boolean | null | undefined
            group?: string | null | undefined
            category?: string | null | undefined
            visible?: boolean | null | undefined
            toolType?: ToolType | null | undefined
            authType?: ToolAuthType | null | undefined
            supportLevel?: ToolSupportLevel | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type ToolCategoriesFieldsFragment = {
  __typename?: 'GetAllToolCategoriesResponse'
  categories: Array<{
    __typename?: 'GetAllToolCategoriesResponseItem'
    name: string
    description?: string | null | undefined
  }>
  subCategories: Array<{ __typename?: 'GetAllToolCategoriesResponseItem'; name: string }>
}

export type SearchToolsFieldsFragment = {
  __typename?: 'SearchToolsResponse'
  numberOfPages?: number | null | undefined
  data: Array<{
    __typename?: 'Tool'
    id?: string | null | undefined
    cyclrToolId?: string | null | undefined
    imageUrl?: string | null | undefined
    toolName?: string | null | undefined
    toolDisplayName?: string | null | undefined
    connectionFormImageUrl?: string | null | undefined
    userToolsImageUrl?: string | null | undefined
    toolUrl?: string | null | undefined
    isConnected?: boolean | null | undefined
    isRecommended?: boolean | null | undefined
    recommendedBy?: string | null | undefined
    isSelected?: boolean | null | undefined
    group?: string | null | undefined
    category?: string | null | undefined
    visible?: boolean | null | undefined
    connectionStatus?: string | null | undefined
    connectorName?: string | null | undefined
    pricingPageUrl?: string | null | undefined
    tags?: Array<string> | null | undefined
    subCategory?: string | null | undefined
    supportLevel?: ToolSupportLevel | null | undefined
    relatedPlays?: Array<string | null | undefined> | null | undefined
    isBookmarked?: boolean | null | undefined
    description?: string | null | undefined
    diagnosticSupported?: boolean | null | undefined
    longDescription?: string | null | undefined
    authType?: ToolAuthType | null | undefined
    toolHelpText?: string | null | undefined
    clientId?: string | null | undefined
    clientSecret?: string | null | undefined
    options?:
      | {
          __typename?: 'ToolConnectionOptions'
          connectionForm?:
            | {
                __typename?: 'ToolConnectionForm'
                description: string
                inputProperties: Array<{
                  __typename?: 'ToolConnectionFormInputProperty'
                  name: string
                  displayName: string
                  placeholder?: string | null | undefined
                  type: string
                  validationRules: Array<string>
                }>
              }
            | null
            | undefined
        }
      | null
      | undefined
    categories?:
      | Array<{ __typename?: 'ToolCategory'; category: string; subCategory: string }>
      | null
      | undefined
  }>
}

export type GetToolsByTypeQueryVariables = Exact<{
  input: GetToolsByTypeInput
}>

export type GetToolsByTypeQuery = {
  __typename?: 'Query'
  getToolsByType?:
    | {
        __typename?: 'GetToolsByTypeResponse'
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'Tool'
                  id?: string | null | undefined
                  cyclrToolId?: string | null | undefined
                  imageUrl?: string | null | undefined
                  toolName?: string | null | undefined
                  userToolsImageUrl?: string | null | undefined
                  toolUrl?: string | null | undefined
                  isConnected?: boolean | null | undefined
                  isRecommended?: boolean | null | undefined
                  recommendedBy?: string | null | undefined
                  isSelected?: boolean | null | undefined
                  group?: string | null | undefined
                  category?: string | null | undefined
                  visible?: boolean | null | undefined
                  toolType?: ToolType | null | undefined
                  authType?: ToolAuthType | null | undefined
                  supportLevel?: ToolSupportLevel | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetAllToolCategoriesQueryVariables = Exact<{ [key: string]: never }>

export type GetAllToolCategoriesQuery = {
  __typename?: 'Query'
  getAllToolCategories: {
    __typename?: 'GetAllToolCategoriesResponse'
    categories: Array<{
      __typename?: 'GetAllToolCategoriesResponseItem'
      name: string
      description?: string | null | undefined
    }>
    subCategories: Array<{ __typename?: 'GetAllToolCategoriesResponseItem'; name: string }>
  }
}

export type SearchToolsQueryVariables = Exact<{
  input: SearchToolsInput
}>

export type SearchToolsQuery = {
  __typename?: 'Query'
  searchTools?:
    | {
        __typename?: 'SearchToolsResponse'
        numberOfPages?: number | null | undefined
        data: Array<{
          __typename?: 'Tool'
          id?: string | null | undefined
          cyclrToolId?: string | null | undefined
          imageUrl?: string | null | undefined
          toolName?: string | null | undefined
          toolDisplayName?: string | null | undefined
          connectionFormImageUrl?: string | null | undefined
          userToolsImageUrl?: string | null | undefined
          toolUrl?: string | null | undefined
          isConnected?: boolean | null | undefined
          isRecommended?: boolean | null | undefined
          recommendedBy?: string | null | undefined
          isSelected?: boolean | null | undefined
          group?: string | null | undefined
          category?: string | null | undefined
          visible?: boolean | null | undefined
          connectionStatus?: string | null | undefined
          connectorName?: string | null | undefined
          pricingPageUrl?: string | null | undefined
          tags?: Array<string> | null | undefined
          subCategory?: string | null | undefined
          supportLevel?: ToolSupportLevel | null | undefined
          relatedPlays?: Array<string | null | undefined> | null | undefined
          isBookmarked?: boolean | null | undefined
          description?: string | null | undefined
          diagnosticSupported?: boolean | null | undefined
          longDescription?: string | null | undefined
          authType?: ToolAuthType | null | undefined
          toolHelpText?: string | null | undefined
          clientId?: string | null | undefined
          clientSecret?: string | null | undefined
          options?:
            | {
                __typename?: 'ToolConnectionOptions'
                connectionForm?:
                  | {
                      __typename?: 'ToolConnectionForm'
                      description: string
                      inputProperties: Array<{
                        __typename?: 'ToolConnectionFormInputProperty'
                        name: string
                        displayName: string
                        placeholder?: string | null | undefined
                        type: string
                        validationRules: Array<string>
                      }>
                    }
                  | null
                  | undefined
              }
            | null
            | undefined
          categories?:
            | Array<{ __typename?: 'ToolCategory'; category: string; subCategory: string }>
            | null
            | undefined
        }>
      }
    | null
    | undefined
}

export type GoalSignedUrlFieldsFragment = {
  __typename?: 'SignedUrlResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type CognitoUserFieldsFragment = {
  __typename?: 'CognitoUser'
  email: string
  userName: string
  enabled?: boolean | null | undefined
  userStatus?: string | null | undefined
  name?: string | null | undefined
}

export type GetUserFilesFiledsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type SaveUserFileFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetSignedUrlQueryVariables = Exact<{
  contentType?: InputMaybe<Scalars['String']>
  fileName?: InputMaybe<Scalars['String']>
}>

export type GetSignedUrlQuery = {
  __typename?: 'Query'
  getSignedUrl?:
    | {
        __typename?: 'SignedUrlResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetUserFilesQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}>

export type GetUserFilesQuery = {
  __typename?: 'Query'
  getUserFiles?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetFileQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>
}>

export type GetFileQuery = {
  __typename?: 'Query'
  getFile?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type SaveUserFileMutationVariables = Exact<{
  input: SaveUserFileInput
}>

export type SaveUserFileMutation = {
  __typename?: 'Mutation'
  saveUserFile?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type BigQueryFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type BusinessesFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type AdvisorsFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type LogActivityFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type UserNotificationsFieldsFragment = {
  __typename?: 'GetUserNotificationsResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'UserNotificationData'
        lastEvaluatedKey?: string | null | undefined
        totalCount?: number | null | undefined
        notifications?:
          | Array<
              | {
                  __typename?: 'UserNotification'
                  id: string
                  createdAt?: string | null | undefined
                  meta?: string | null | undefined
                  notificationType: string
                  sender?: string | null | undefined
                  status?: string | null | undefined
                  firstName?: string | null | undefined
                  lastName?: string | null | undefined
                  updatedAt?: string | null | undefined
                  businessName?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type UserNotificationMutateFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetClientsSummaryFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type ResendUserInviteFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type SaveAttestationFieldsFragment = {
  __typename?: 'AttestationResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'Attestation'
        pk?: string | null | undefined
        sk?: string | null | undefined
        id?: string | null | undefined
        tenantId?: string | null | undefined
        month?: string | null | undefined
        year?: string | null | undefined
        contractors?: string | null | undefined
        businessBankAccount?: string | null | undefined
        jobsCreated?: string | null | undefined
        volunteers?: string | null | undefined
        partTimeEmployees?: string | null | undefined
        fullTimeEmployees?: string | null | undefined
        gender?: string | null | undefined
        race?: string | null | undefined
        education?: string | null | undefined
        wages?: string | null | undefined
        revenue?: string | null | undefined
        debtFinancing?: string | null | undefined
        financialGrowth?: string | null | undefined
        newCustomer?: string | null | undefined
        productSale?: string | null | undefined
        serviceSale?: string | null | undefined
        targetAudience?: string | null | undefined
        physicalLocation?: string | null | undefined
        url?: string | null | undefined
      }
    | null
    | undefined
}

export type GetBusinessesQueryVariables = Exact<{
  getBusinessesInput: GetBusinessesInput
}>

export type GetBusinessesQuery = {
  __typename?: 'Query'
  getBusinesses?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type ListCognitoUsersMutationVariables = Exact<{
  input: ListCognitoUsersInput
}>

export type ListCognitoUsersMutation = {
  __typename?: 'Mutation'
  listCognitoUsers: Array<
    | {
        __typename?: 'CognitoUser'
        email: string
        userName: string
        enabled?: boolean | null | undefined
        userStatus?: string | null | undefined
        name?: string | null | undefined
      }
    | null
    | undefined
  >
}

export type AddUserToGroupMutationVariables = Exact<{
  input: AddUserToGroupInput
}>

export type AddUserToGroupMutation = { __typename?: 'Mutation'; addUserToGroup: boolean }

export type UpdateUserPasswordMutationVariables = Exact<{
  input: UpdateUserPasswordInput
}>

export type UpdateUserPasswordMutation = {
  __typename?: 'Mutation'
  updateUserPassword?: boolean | null | undefined
}

export type GetAdvisorsQueryVariables = Exact<{
  getAdvisorsInput?: InputMaybe<GetAdvisorsInput>
}>

export type GetAdvisorsQuery = {
  __typename?: 'Query'
  getAdvisors?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type LogActivityMutationVariables = Exact<{
  activityLogInput: ActivityLogInput
}>

export type LogActivityMutation = {
  __typename?: 'Mutation'
  logActivity?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetUserNotificationsQueryVariables = Exact<{
  input?: InputMaybe<GetUserNotificationsInput>
}>

export type GetUserNotificationsQuery = {
  __typename?: 'Query'
  getUserNotifications?:
    | {
        __typename?: 'GetUserNotificationsResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'UserNotificationData'
              lastEvaluatedKey?: string | null | undefined
              totalCount?: number | null | undefined
              notifications?:
                | Array<
                    | {
                        __typename?: 'UserNotification'
                        id: string
                        createdAt?: string | null | undefined
                        meta?: string | null | undefined
                        notificationType: string
                        sender?: string | null | undefined
                        status?: string | null | undefined
                        firstName?: string | null | undefined
                        lastName?: string | null | undefined
                        updatedAt?: string | null | undefined
                        businessName?: string | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetClientsSummaryQueryVariables = Exact<{
  tenantId?: InputMaybe<Scalars['String']>
}>

export type GetClientsSummaryQuery = {
  __typename?: 'Query'
  getClientsSummary?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type UpdateUserNotificationsMutationVariables = Exact<{
  input?: InputMaybe<UpdateUserNotificationsInput>
}>

export type UpdateUserNotificationsMutation = {
  __typename?: 'Mutation'
  updateUserNotifications?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type DeleteUserNotificationsMutationVariables = Exact<{
  input?: InputMaybe<DeleteUserNotificationsInput>
}>

export type DeleteUserNotificationsMutation = {
  __typename?: 'Mutation'
  deleteUserNotifications?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type ResendUserInviteMutationVariables = Exact<{
  input: ResendUserInviteInput
}>

export type ResendUserInviteMutation = {
  __typename?: 'Mutation'
  resendUserInvite?: boolean | null | undefined
}

export type SaveAttestationMutationVariables = Exact<{
  input: AttestationInput
}>

export type SaveAttestationMutation = {
  __typename?: 'Mutation'
  saveAttestation?:
    | {
        __typename?: 'AttestationResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'Attestation'
              pk?: string | null | undefined
              sk?: string | null | undefined
              id?: string | null | undefined
              tenantId?: string | null | undefined
              month?: string | null | undefined
              year?: string | null | undefined
              contractors?: string | null | undefined
              businessBankAccount?: string | null | undefined
              jobsCreated?: string | null | undefined
              volunteers?: string | null | undefined
              partTimeEmployees?: string | null | undefined
              fullTimeEmployees?: string | null | undefined
              gender?: string | null | undefined
              race?: string | null | undefined
              education?: string | null | undefined
              wages?: string | null | undefined
              revenue?: string | null | undefined
              debtFinancing?: string | null | undefined
              financialGrowth?: string | null | undefined
              newCustomer?: string | null | undefined
              productSale?: string | null | undefined
              serviceSale?: string | null | undefined
              targetAudience?: string | null | undefined
              physicalLocation?: string | null | undefined
              url?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type MakeAnnouncementMutationVariables = Exact<{
  input: MakeAnnouncementInput
}>

export type MakeAnnouncementMutation = {
  __typename?: 'Mutation'
  makeAnnouncement?: boolean | null | undefined
}

export type UserProfileFieldsFragment = {
  __typename?: 'UserProfile'
  id: string
  name?: string | null | undefined
  firstName?: string | null | undefined
  lastName?: string | null | undefined
  email: string
  createdAt: string
  updatedAt: string
  websiteURL?: string | null | undefined
  bio?: string | null | undefined
  roles?: Array<string | null | undefined> | null | undefined
  isNewUser?: boolean | null | undefined
  mobileContactNumber?: string | null | undefined
  yearsInBusiness?: string | null | undefined
  companyEmployees?: number | null | undefined
  companyAdvisor?: string | null | undefined
  schedulingUrl?: string | null | undefined
  calendlyEventsUrl?: string | null | undefined
  tenantId?: Array<string> | null | undefined
  streamAccessToken?: string | null | undefined
  algoliaSearchKey?: string | null | undefined
  firstSigninDate?: string | null | undefined
  activatedAt?: string | null | undefined
  status?: string | null | undefined
  ownerExperience?: string | null | undefined
  submissionStartDay?: number | null | undefined
  submissionEndDay?: number | null | undefined
  submissionReminderDay?: number | null | undefined
  submissionReportDay?: number | null | undefined
  attestationStatus?: string | null | undefined
  isSmsEnabled?: boolean | null | undefined
  defaultVideoLinkDescription?: string | null | undefined
  organizationName?: string | null | undefined
  timezone?: string | null | undefined
  interimTimezone?: string | null | undefined
  smsSubscriptionStatus?: SmsSubscriptionStatus | null | undefined
  tempPassword?: string | null | undefined
  calendarEventViewPreference?: CalendarEventViewPreference | null | undefined
  isConsentGivenToAdvisor?: boolean | null | undefined
  onboardingStatus?: OnboardingStatus | null | undefined
}

export type BoAdvisorsFieldsFragment = {
  __typename?: 'BOAdvisorsResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'Advisors'
            id: string
            firstName?: string | null | undefined
            lastName?: string | null | undefined
            email?: string | null | undefined
            schedulingUrl?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type SaveAssessmentResponseInputFragment = {
  __typename?: 'AssessmentResponse'
  currentStage?: string | null | undefined
  type?: string | null | undefined
  userId?: string | null | undefined
  createdAt?: string | null | undefined
  updatedAt?: string | null | undefined
  assessmentResponse?:
    | Array<{
        __typename?: 'AssessmentResponseOutput'
        next?: string | null | undefined
        previous?: Array<string> | null | undefined
        id?: string | null | undefined
        value?: string | null | undefined
        label?: string | null | undefined
        playResponse?: string | null | undefined
        isIndustry?: boolean | null | undefined
        isSubIndustry?: boolean | null | undefined
        isTopGoal?: boolean | null | undefined
        recommendations?:
          | {
              __typename?: 'Recommendations'
              assessments?: Array<string> | null | undefined
              plays?: Array<string> | null | undefined
              assessmentRecommendedAt?: string | null | undefined
              playRecommendedAt?: string | null | undefined
            }
          | null
          | undefined
      }>
    | null
    | undefined
}

export type AdvisorProfileFieldsFragment = {
  __typename?: 'AdvisorProfile'
  id?: string | null | undefined
  firstName?: string | null | undefined
  lastName?: string | null | undefined
  defaultVideoLinkDescription?: string | null | undefined
}

export type BusinessIndustryChangedFieldsFragment = {
  __typename?: 'GetBusinessIndustryDetailsResponse'
  isSubIndustryUpdated?: boolean | null | undefined
}

export type GetBusinessIndustryDetailsQueryVariables = Exact<{
  input: GetBusinessIndustryDetailsInput
}>

export type GetBusinessIndustryDetailsQuery = {
  __typename?: 'Query'
  getBusinessIndustryDetails?:
    | {
        __typename?: 'GetBusinessIndustryDetailsResponse'
        isSubIndustryUpdated?: boolean | null | undefined
      }
    | null
    | undefined
}

export type GetMyProfileQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}>

export type GetMyProfileQuery = {
  __typename?: 'Query'
  getMyProfile: {
    __typename?: 'UserProfile'
    id: string
    name?: string | null | undefined
    firstName?: string | null | undefined
    lastName?: string | null | undefined
    email: string
    createdAt: string
    updatedAt: string
    websiteURL?: string | null | undefined
    bio?: string | null | undefined
    roles?: Array<string | null | undefined> | null | undefined
    isNewUser?: boolean | null | undefined
    mobileContactNumber?: string | null | undefined
    yearsInBusiness?: string | null | undefined
    companyEmployees?: number | null | undefined
    companyAdvisor?: string | null | undefined
    schedulingUrl?: string | null | undefined
    calendlyEventsUrl?: string | null | undefined
    tenantId?: Array<string> | null | undefined
    streamAccessToken?: string | null | undefined
    algoliaSearchKey?: string | null | undefined
    firstSigninDate?: string | null | undefined
    activatedAt?: string | null | undefined
    status?: string | null | undefined
    ownerExperience?: string | null | undefined
    submissionStartDay?: number | null | undefined
    submissionEndDay?: number | null | undefined
    submissionReminderDay?: number | null | undefined
    submissionReportDay?: number | null | undefined
    attestationStatus?: string | null | undefined
    isSmsEnabled?: boolean | null | undefined
    defaultVideoLinkDescription?: string | null | undefined
    organizationName?: string | null | undefined
    timezone?: string | null | undefined
    interimTimezone?: string | null | undefined
    smsSubscriptionStatus?: SmsSubscriptionStatus | null | undefined
    tempPassword?: string | null | undefined
    calendarEventViewPreference?: CalendarEventViewPreference | null | undefined
    isConsentGivenToAdvisor?: boolean | null | undefined
    onboardingStatus?: OnboardingStatus | null | undefined
  }
}

export type GetBoAdvisorsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}>

export type GetBoAdvisorsQuery = {
  __typename?: 'Query'
  getBoAdvisors?:
    | {
        __typename?: 'BOAdvisorsResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'Advisors'
                  id: string
                  firstName?: string | null | undefined
                  lastName?: string | null | undefined
                  email?: string | null | undefined
                  schedulingUrl?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type UpdateMyProfileMutationVariables = Exact<{
  input: UpdateMyProfileInput
}>

export type UpdateMyProfileMutation = {
  __typename?: 'Mutation'
  updateMyProfile?:
    | {
        __typename?: 'UserProfile'
        id: string
        name?: string | null | undefined
        firstName?: string | null | undefined
        lastName?: string | null | undefined
        email: string
        createdAt: string
        updatedAt: string
        websiteURL?: string | null | undefined
        bio?: string | null | undefined
        roles?: Array<string | null | undefined> | null | undefined
        isNewUser?: boolean | null | undefined
        mobileContactNumber?: string | null | undefined
        yearsInBusiness?: string | null | undefined
        companyEmployees?: number | null | undefined
        companyAdvisor?: string | null | undefined
        schedulingUrl?: string | null | undefined
        calendlyEventsUrl?: string | null | undefined
        tenantId?: Array<string> | null | undefined
        streamAccessToken?: string | null | undefined
        algoliaSearchKey?: string | null | undefined
        firstSigninDate?: string | null | undefined
        activatedAt?: string | null | undefined
        status?: string | null | undefined
        ownerExperience?: string | null | undefined
        submissionStartDay?: number | null | undefined
        submissionEndDay?: number | null | undefined
        submissionReminderDay?: number | null | undefined
        submissionReportDay?: number | null | undefined
        attestationStatus?: string | null | undefined
        isSmsEnabled?: boolean | null | undefined
        defaultVideoLinkDescription?: string | null | undefined
        organizationName?: string | null | undefined
        timezone?: string | null | undefined
        interimTimezone?: string | null | undefined
        smsSubscriptionStatus?: SmsSubscriptionStatus | null | undefined
        tempPassword?: string | null | undefined
        calendarEventViewPreference?: CalendarEventViewPreference | null | undefined
        isConsentGivenToAdvisor?: boolean | null | undefined
        onboardingStatus?: OnboardingStatus | null | undefined
      }
    | null
    | undefined
}

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput
}>

export type CreateUserMutation = {
  __typename?: 'Mutation'
  createUser?:
    | {
        __typename?: 'UserProfile'
        id: string
        name?: string | null | undefined
        firstName?: string | null | undefined
        lastName?: string | null | undefined
        email: string
        createdAt: string
        updatedAt: string
        websiteURL?: string | null | undefined
        bio?: string | null | undefined
        roles?: Array<string | null | undefined> | null | undefined
        isNewUser?: boolean | null | undefined
        mobileContactNumber?: string | null | undefined
        yearsInBusiness?: string | null | undefined
        companyEmployees?: number | null | undefined
        companyAdvisor?: string | null | undefined
        schedulingUrl?: string | null | undefined
        calendlyEventsUrl?: string | null | undefined
        tenantId?: Array<string> | null | undefined
        streamAccessToken?: string | null | undefined
        algoliaSearchKey?: string | null | undefined
        firstSigninDate?: string | null | undefined
        activatedAt?: string | null | undefined
        status?: string | null | undefined
        ownerExperience?: string | null | undefined
        submissionStartDay?: number | null | undefined
        submissionEndDay?: number | null | undefined
        submissionReminderDay?: number | null | undefined
        submissionReportDay?: number | null | undefined
        attestationStatus?: string | null | undefined
        isSmsEnabled?: boolean | null | undefined
        defaultVideoLinkDescription?: string | null | undefined
        organizationName?: string | null | undefined
        timezone?: string | null | undefined
        interimTimezone?: string | null | undefined
        smsSubscriptionStatus?: SmsSubscriptionStatus | null | undefined
        tempPassword?: string | null | undefined
        calendarEventViewPreference?: CalendarEventViewPreference | null | undefined
        isConsentGivenToAdvisor?: boolean | null | undefined
        onboardingStatus?: OnboardingStatus | null | undefined
      }
    | null
    | undefined
}

export type GetAdvisorProfileQueryVariables = Exact<{
  input: GetAdvisorProfileInput
}>

export type GetAdvisorProfileQuery = {
  __typename?: 'Query'
  getAdvisorProfile?:
    | {
        __typename?: 'AdvisorProfile'
        id?: string | null | undefined
        firstName?: string | null | undefined
        lastName?: string | null | undefined
        defaultVideoLinkDescription?: string | null | undefined
      }
    | null
    | undefined
}

export type GoalsFieldsFragment = {
  __typename?: 'BusinessGoal'
  goalId?: string | null | undefined
  name?: string | null | undefined
  status?: UserTaskItemStatus | null | undefined
  progress?: number | null | undefined
  goalPriority?: BusinessGoalPriority | null | undefined
  description?: string | null | undefined
  category?: BusinessGoalCategory | null | undefined
  goalTimeFrame?: BusinessGoalTimeFrame | null | undefined
  logo?: string | null | undefined
  expectedEndDate?: string | null | undefined
  plan?:
    | {
        __typename?: 'BusinessGoalPlan'
        planId?: string | null | undefined
        goalId?: string | null | undefined
        name?: string | null | undefined
        startDate?: string | null | undefined
        expectedEndDate?: string | null | undefined
        timeline?: string | null | undefined
        state?: BusinessGoalPlanState | null | undefined
        progress?: number | null | undefined
        pauseDuration?: number | null | undefined
        pauseDate?: string | null | undefined
        tasks?:
          | Array<
              | {
                  __typename?: 'UserTask'
                  tenantId?: string | null | undefined
                  userId?: string | null | undefined
                  taskId?: string | null | undefined
                  planId?: string | null | undefined
                  milestoneId?: string | null | undefined
                  name?: string | null | undefined
                  status?: UserTaskItemStatus | null | undefined
                  tool?: string | null | undefined
                  timeline?: string | null | undefined
                  startDate?: string | null | undefined
                  expectedEndDate?: string | null | undefined
                  howToDo?: string | null | undefined
                  estimationHour?: number | null | undefined
                  instructionForTask?: string | null | undefined
                  kpis?: string | null | undefined
                  role?: string | null | undefined
                  why?: string | null | undefined
                  order?: number | null | undefined
                  resources?: string | null | undefined
                  createdBy?: string | null | undefined
                  taskPriority?: BusinessGoalPriority | null | undefined
                  category?: BusinessGoalCategory | null | undefined
                  assigneeId?: string | null | undefined
                  howToLinks?:
                    | Array<
                        | {
                            __typename?: 'UserTaskHowTo'
                            howToId: string
                            title?: string | null | undefined
                            url?: string | null | undefined
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
        milestones?:
          | Array<{
              __typename?: 'BusinessGoalMilestone'
              planId?: string | null | undefined
              milestoneId?: string | null | undefined
              name?: string | null | undefined
              descriptions?: string | null | undefined
              timeline?: string | null | undefined
              startDate?: string | null | undefined
              expectedEndDate?: string | null | undefined
              riskAndChallenges?: string | null | undefined
              status?: UserTaskItemStatus | null | undefined
              order?: number | null | undefined
              milestoneSpan?: string | null | undefined
              tasks?:
                | Array<{
                    __typename?: 'UserTask'
                    tenantId?: string | null | undefined
                    userId?: string | null | undefined
                    taskId?: string | null | undefined
                    planId?: string | null | undefined
                    milestoneId?: string | null | undefined
                    name?: string | null | undefined
                    status?: UserTaskItemStatus | null | undefined
                    tool?: string | null | undefined
                    timeline?: string | null | undefined
                    startDate?: string | null | undefined
                    expectedEndDate?: string | null | undefined
                    howToDo?: string | null | undefined
                    estimationHour?: number | null | undefined
                    instructionForTask?: string | null | undefined
                    kpis?: string | null | undefined
                    role?: string | null | undefined
                    why?: string | null | undefined
                    order?: number | null | undefined
                    resources?: string | null | undefined
                    createdBy?: string | null | undefined
                    taskPriority?: BusinessGoalPriority | null | undefined
                    category?: BusinessGoalCategory | null | undefined
                    assigneeId?: string | null | undefined
                    howToLinks?:
                      | Array<
                          | {
                              __typename?: 'UserTaskHowTo'
                              howToId: string
                              title?: string | null | undefined
                              url?: string | null | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }>
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type TaskFragment = {
  __typename?: 'UserTask'
  tenantId?: string | null | undefined
  userId?: string | null | undefined
  taskId?: string | null | undefined
  planId?: string | null | undefined
  milestoneId?: string | null | undefined
  name?: string | null | undefined
  status?: UserTaskItemStatus | null | undefined
  tool?: string | null | undefined
  timeline?: string | null | undefined
  startDate?: string | null | undefined
  expectedEndDate?: string | null | undefined
  howToDo?: string | null | undefined
  estimationHour?: number | null | undefined
  instructionForTask?: string | null | undefined
  kpis?: string | null | undefined
  role?: string | null | undefined
  why?: string | null | undefined
  order?: number | null | undefined
  resources?: string | null | undefined
  createdBy?: string | null | undefined
  taskPriority?: BusinessGoalPriority | null | undefined
  category?: BusinessGoalCategory | null | undefined
  assigneeId?: string | null | undefined
  howToLinks?:
    | Array<
        | {
            __typename?: 'UserTaskHowTo'
            howToId: string
            title?: string | null | undefined
            url?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type GoalFieldsFragment = {
  __typename?: 'BusinessGoal'
  goalId?: string | null | undefined
  userId?: string | null | undefined
  name?: string | null | undefined
  status?: UserTaskItemStatus | null | undefined
  progress?: number | null | undefined
  plan?:
    | {
        __typename?: 'BusinessGoalPlan'
        planId?: string | null | undefined
        goalId?: string | null | undefined
        name?: string | null | undefined
        startDate?: string | null | undefined
        expectedEndDate?: string | null | undefined
        timeline?: string | null | undefined
        state?: BusinessGoalPlanState | null | undefined
        progress?: number | null | undefined
        pauseDuration?: number | null | undefined
        pauseDate?: string | null | undefined
        tasks?:
          | Array<
              | {
                  __typename?: 'UserTask'
                  tenantId?: string | null | undefined
                  userId?: string | null | undefined
                  taskId?: string | null | undefined
                  planId?: string | null | undefined
                  milestoneId?: string | null | undefined
                  name?: string | null | undefined
                  status?: UserTaskItemStatus | null | undefined
                  tool?: string | null | undefined
                  timeline?: string | null | undefined
                  startDate?: string | null | undefined
                  expectedEndDate?: string | null | undefined
                  howToDo?: string | null | undefined
                  estimationHour?: number | null | undefined
                  instructionForTask?: string | null | undefined
                  kpis?: string | null | undefined
                  role?: string | null | undefined
                  why?: string | null | undefined
                  order?: number | null | undefined
                  resources?: string | null | undefined
                  createdBy?: string | null | undefined
                  taskPriority?: BusinessGoalPriority | null | undefined
                  category?: BusinessGoalCategory | null | undefined
                  assigneeId?: string | null | undefined
                  howToLinks?:
                    | Array<
                        | {
                            __typename?: 'UserTaskHowTo'
                            howToId: string
                            title?: string | null | undefined
                            url?: string | null | undefined
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
        milestones?:
          | Array<{
              __typename?: 'BusinessGoalMilestone'
              planId?: string | null | undefined
              milestoneId?: string | null | undefined
              name?: string | null | undefined
              descriptions?: string | null | undefined
              timeline?: string | null | undefined
              startDate?: string | null | undefined
              expectedEndDate?: string | null | undefined
              riskAndChallenges?: string | null | undefined
              status?: UserTaskItemStatus | null | undefined
              order?: number | null | undefined
              milestoneSpan?: string | null | undefined
              tasks?:
                | Array<{
                    __typename?: 'UserTask'
                    tenantId?: string | null | undefined
                    userId?: string | null | undefined
                    taskId?: string | null | undefined
                    planId?: string | null | undefined
                    milestoneId?: string | null | undefined
                    name?: string | null | undefined
                    status?: UserTaskItemStatus | null | undefined
                    tool?: string | null | undefined
                    timeline?: string | null | undefined
                    startDate?: string | null | undefined
                    expectedEndDate?: string | null | undefined
                    howToDo?: string | null | undefined
                    estimationHour?: number | null | undefined
                    instructionForTask?: string | null | undefined
                    kpis?: string | null | undefined
                    role?: string | null | undefined
                    why?: string | null | undefined
                    order?: number | null | undefined
                    resources?: string | null | undefined
                    createdBy?: string | null | undefined
                    taskPriority?: BusinessGoalPriority | null | undefined
                    category?: BusinessGoalCategory | null | undefined
                    assigneeId?: string | null | undefined
                    howToLinks?:
                      | Array<
                          | {
                              __typename?: 'UserTaskHowTo'
                              howToId: string
                              title?: string | null | undefined
                              url?: string | null | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }>
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type UserTaskFieldsFragment = {
  __typename?: 'UserTask'
  tenantId?: string | null | undefined
  userId?: string | null | undefined
  taskId?: string | null | undefined
  planId?: string | null | undefined
  milestoneId?: string | null | undefined
  name?: string | null | undefined
  status?: UserTaskItemStatus | null | undefined
  tool?: string | null | undefined
  timeline?: string | null | undefined
  startDate?: string | null | undefined
  expectedEndDate?: string | null | undefined
  howToDo?: string | null | undefined
  estimationHour?: number | null | undefined
  instructionForTask?: string | null | undefined
  kpis?: string | null | undefined
  role?: string | null | undefined
  why?: string | null | undefined
  order?: number | null | undefined
  resources?: string | null | undefined
  createdBy?: string | null | undefined
  taskPriority?: BusinessGoalPriority | null | undefined
  category?: BusinessGoalCategory | null | undefined
  assigneeId?: string | null | undefined
  howToLinks?:
    | Array<
        | {
            __typename?: 'UserTaskHowTo'
            howToId: string
            title?: string | null | undefined
            url?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type GetBusinessGoalsQueryVariables = Exact<{
  input: GetBusinessGoalsInput
}>

export type GetBusinessGoalsQuery = {
  __typename?: 'Query'
  getBusinessGoals?:
    | Array<{
        __typename?: 'BusinessGoal'
        goalId?: string | null | undefined
        name?: string | null | undefined
        status?: UserTaskItemStatus | null | undefined
        progress?: number | null | undefined
        goalPriority?: BusinessGoalPriority | null | undefined
        description?: string | null | undefined
        category?: BusinessGoalCategory | null | undefined
        goalTimeFrame?: BusinessGoalTimeFrame | null | undefined
        logo?: string | null | undefined
        expectedEndDate?: string | null | undefined
        plan?:
          | {
              __typename?: 'BusinessGoalPlan'
              planId?: string | null | undefined
              goalId?: string | null | undefined
              name?: string | null | undefined
              startDate?: string | null | undefined
              expectedEndDate?: string | null | undefined
              timeline?: string | null | undefined
              state?: BusinessGoalPlanState | null | undefined
              progress?: number | null | undefined
              pauseDuration?: number | null | undefined
              pauseDate?: string | null | undefined
              tasks?:
                | Array<
                    | {
                        __typename?: 'UserTask'
                        tenantId?: string | null | undefined
                        userId?: string | null | undefined
                        taskId?: string | null | undefined
                        planId?: string | null | undefined
                        milestoneId?: string | null | undefined
                        name?: string | null | undefined
                        status?: UserTaskItemStatus | null | undefined
                        tool?: string | null | undefined
                        timeline?: string | null | undefined
                        startDate?: string | null | undefined
                        expectedEndDate?: string | null | undefined
                        howToDo?: string | null | undefined
                        estimationHour?: number | null | undefined
                        instructionForTask?: string | null | undefined
                        kpis?: string | null | undefined
                        role?: string | null | undefined
                        why?: string | null | undefined
                        order?: number | null | undefined
                        resources?: string | null | undefined
                        createdBy?: string | null | undefined
                        taskPriority?: BusinessGoalPriority | null | undefined
                        category?: BusinessGoalCategory | null | undefined
                        assigneeId?: string | null | undefined
                        howToLinks?:
                          | Array<
                              | {
                                  __typename?: 'UserTaskHowTo'
                                  howToId: string
                                  title?: string | null | undefined
                                  url?: string | null | undefined
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
              milestones?:
                | Array<{
                    __typename?: 'BusinessGoalMilestone'
                    planId?: string | null | undefined
                    milestoneId?: string | null | undefined
                    name?: string | null | undefined
                    descriptions?: string | null | undefined
                    timeline?: string | null | undefined
                    startDate?: string | null | undefined
                    expectedEndDate?: string | null | undefined
                    riskAndChallenges?: string | null | undefined
                    status?: UserTaskItemStatus | null | undefined
                    order?: number | null | undefined
                    milestoneSpan?: string | null | undefined
                    tasks?:
                      | Array<{
                          __typename?: 'UserTask'
                          tenantId?: string | null | undefined
                          userId?: string | null | undefined
                          taskId?: string | null | undefined
                          planId?: string | null | undefined
                          milestoneId?: string | null | undefined
                          name?: string | null | undefined
                          status?: UserTaskItemStatus | null | undefined
                          tool?: string | null | undefined
                          timeline?: string | null | undefined
                          startDate?: string | null | undefined
                          expectedEndDate?: string | null | undefined
                          howToDo?: string | null | undefined
                          estimationHour?: number | null | undefined
                          instructionForTask?: string | null | undefined
                          kpis?: string | null | undefined
                          role?: string | null | undefined
                          why?: string | null | undefined
                          order?: number | null | undefined
                          resources?: string | null | undefined
                          createdBy?: string | null | undefined
                          taskPriority?: BusinessGoalPriority | null | undefined
                          category?: BusinessGoalCategory | null | undefined
                          assigneeId?: string | null | undefined
                          howToLinks?:
                            | Array<
                                | {
                                    __typename?: 'UserTaskHowTo'
                                    howToId: string
                                    title?: string | null | undefined
                                    url?: string | null | undefined
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined
                        }>
                      | null
                      | undefined
                  }>
                | null
                | undefined
            }
          | null
          | undefined
      }>
    | null
    | undefined
}

export type GetBusinessGoalQueryVariables = Exact<{
  input: GetBusinessGoalInput
}>

export type GetBusinessGoalQuery = {
  __typename?: 'Query'
  getBusinessGoal?:
    | {
        __typename?: 'BusinessGoal'
        goalId?: string | null | undefined
        userId?: string | null | undefined
        name?: string | null | undefined
        status?: UserTaskItemStatus | null | undefined
        progress?: number | null | undefined
        plan?:
          | {
              __typename?: 'BusinessGoalPlan'
              planId?: string | null | undefined
              goalId?: string | null | undefined
              name?: string | null | undefined
              startDate?: string | null | undefined
              expectedEndDate?: string | null | undefined
              timeline?: string | null | undefined
              state?: BusinessGoalPlanState | null | undefined
              progress?: number | null | undefined
              pauseDuration?: number | null | undefined
              pauseDate?: string | null | undefined
              tasks?:
                | Array<
                    | {
                        __typename?: 'UserTask'
                        tenantId?: string | null | undefined
                        userId?: string | null | undefined
                        taskId?: string | null | undefined
                        planId?: string | null | undefined
                        milestoneId?: string | null | undefined
                        name?: string | null | undefined
                        status?: UserTaskItemStatus | null | undefined
                        tool?: string | null | undefined
                        timeline?: string | null | undefined
                        startDate?: string | null | undefined
                        expectedEndDate?: string | null | undefined
                        howToDo?: string | null | undefined
                        estimationHour?: number | null | undefined
                        instructionForTask?: string | null | undefined
                        kpis?: string | null | undefined
                        role?: string | null | undefined
                        why?: string | null | undefined
                        order?: number | null | undefined
                        resources?: string | null | undefined
                        createdBy?: string | null | undefined
                        taskPriority?: BusinessGoalPriority | null | undefined
                        category?: BusinessGoalCategory | null | undefined
                        assigneeId?: string | null | undefined
                        howToLinks?:
                          | Array<
                              | {
                                  __typename?: 'UserTaskHowTo'
                                  howToId: string
                                  title?: string | null | undefined
                                  url?: string | null | undefined
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
              milestones?:
                | Array<{
                    __typename?: 'BusinessGoalMilestone'
                    planId?: string | null | undefined
                    milestoneId?: string | null | undefined
                    name?: string | null | undefined
                    descriptions?: string | null | undefined
                    timeline?: string | null | undefined
                    startDate?: string | null | undefined
                    expectedEndDate?: string | null | undefined
                    riskAndChallenges?: string | null | undefined
                    status?: UserTaskItemStatus | null | undefined
                    order?: number | null | undefined
                    milestoneSpan?: string | null | undefined
                    tasks?:
                      | Array<{
                          __typename?: 'UserTask'
                          tenantId?: string | null | undefined
                          userId?: string | null | undefined
                          taskId?: string | null | undefined
                          planId?: string | null | undefined
                          milestoneId?: string | null | undefined
                          name?: string | null | undefined
                          status?: UserTaskItemStatus | null | undefined
                          tool?: string | null | undefined
                          timeline?: string | null | undefined
                          startDate?: string | null | undefined
                          expectedEndDate?: string | null | undefined
                          howToDo?: string | null | undefined
                          estimationHour?: number | null | undefined
                          instructionForTask?: string | null | undefined
                          kpis?: string | null | undefined
                          role?: string | null | undefined
                          why?: string | null | undefined
                          order?: number | null | undefined
                          resources?: string | null | undefined
                          createdBy?: string | null | undefined
                          taskPriority?: BusinessGoalPriority | null | undefined
                          category?: BusinessGoalCategory | null | undefined
                          assigneeId?: string | null | undefined
                          howToLinks?:
                            | Array<
                                | {
                                    __typename?: 'UserTaskHowTo'
                                    howToId: string
                                    title?: string | null | undefined
                                    url?: string | null | undefined
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined
                        }>
                      | null
                      | undefined
                  }>
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetUserTaskQueryVariables = Exact<{
  input: GetUserTaskInput
}>

export type GetUserTaskQuery = {
  __typename?: 'Query'
  getUserTask?:
    | {
        __typename?: 'UserTask'
        tenantId?: string | null | undefined
        userId?: string | null | undefined
        taskId?: string | null | undefined
        planId?: string | null | undefined
        milestoneId?: string | null | undefined
        name?: string | null | undefined
        status?: UserTaskItemStatus | null | undefined
        tool?: string | null | undefined
        timeline?: string | null | undefined
        startDate?: string | null | undefined
        expectedEndDate?: string | null | undefined
        howToDo?: string | null | undefined
        estimationHour?: number | null | undefined
        instructionForTask?: string | null | undefined
        kpis?: string | null | undefined
        role?: string | null | undefined
        why?: string | null | undefined
        order?: number | null | undefined
        resources?: string | null | undefined
        createdBy?: string | null | undefined
        taskPriority?: BusinessGoalPriority | null | undefined
        category?: BusinessGoalCategory | null | undefined
        assigneeId?: string | null | undefined
        howToLinks?:
          | Array<
              | {
                  __typename?: 'UserTaskHowTo'
                  howToId: string
                  title?: string | null | undefined
                  url?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetUserTasksQueryVariables = Exact<{
  input: GetUserTasksInput
}>

export type GetUserTasksQuery = {
  __typename?: 'Query'
  getUserTasks?:
    | Array<{
        __typename?: 'UserTask'
        tenantId?: string | null | undefined
        userId?: string | null | undefined
        taskId?: string | null | undefined
        planId?: string | null | undefined
        milestoneId?: string | null | undefined
        name?: string | null | undefined
        status?: UserTaskItemStatus | null | undefined
        tool?: string | null | undefined
        timeline?: string | null | undefined
        startDate?: string | null | undefined
        expectedEndDate?: string | null | undefined
        howToDo?: string | null | undefined
        estimationHour?: number | null | undefined
        instructionForTask?: string | null | undefined
        kpis?: string | null | undefined
        role?: string | null | undefined
        why?: string | null | undefined
        order?: number | null | undefined
        resources?: string | null | undefined
        createdBy?: string | null | undefined
        taskPriority?: BusinessGoalPriority | null | undefined
        category?: BusinessGoalCategory | null | undefined
        assigneeId?: string | null | undefined
        howToLinks?:
          | Array<
              | {
                  __typename?: 'UserTaskHowTo'
                  howToId: string
                  title?: string | null | undefined
                  url?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }>
    | null
    | undefined
}

export type UpdateBusinessGoalMutationVariables = Exact<{
  input: UpdateBusinessGoalInput
}>

export type UpdateBusinessGoalMutation = {
  __typename?: 'Mutation'
  updateBusinessGoal?: string | null | undefined
}

export type CreateUserTaskMutationVariables = Exact<{
  input: CreateUserTaskInput
}>

export type CreateUserTaskMutation = {
  __typename?: 'Mutation'
  createUserTask?:
    | {
        __typename?: 'UserTask'
        tenantId?: string | null | undefined
        userId?: string | null | undefined
        taskId?: string | null | undefined
        planId?: string | null | undefined
        milestoneId?: string | null | undefined
        name?: string | null | undefined
        status?: UserTaskItemStatus | null | undefined
        tool?: string | null | undefined
        timeline?: string | null | undefined
        startDate?: string | null | undefined
        expectedEndDate?: string | null | undefined
        howToDo?: string | null | undefined
        estimationHour?: number | null | undefined
        instructionForTask?: string | null | undefined
        kpis?: string | null | undefined
        role?: string | null | undefined
        why?: string | null | undefined
        order?: number | null | undefined
        resources?: string | null | undefined
        createdBy?: string | null | undefined
        taskPriority?: BusinessGoalPriority | null | undefined
        category?: BusinessGoalCategory | null | undefined
        assigneeId?: string | null | undefined
        howToLinks?:
          | Array<
              | {
                  __typename?: 'UserTaskHowTo'
                  howToId: string
                  title?: string | null | undefined
                  url?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type DeleteUserTaskMutationVariables = Exact<{
  input: DeleteUserTaskInput
}>

export type DeleteUserTaskMutation = {
  __typename?: 'Mutation'
  deleteUserTask?: string | null | undefined
}

export type UpdateUserTaskMutationVariables = Exact<{
  input: UpdateUserTaskInput
}>

export type UpdateUserTaskMutation = {
  __typename?: 'Mutation'
  updateUserTask?:
    | {
        __typename?: 'UserTask'
        tenantId?: string | null | undefined
        userId?: string | null | undefined
        taskId?: string | null | undefined
        planId?: string | null | undefined
        milestoneId?: string | null | undefined
        name?: string | null | undefined
        status?: UserTaskItemStatus | null | undefined
        tool?: string | null | undefined
        timeline?: string | null | undefined
        startDate?: string | null | undefined
        expectedEndDate?: string | null | undefined
        howToDo?: string | null | undefined
        estimationHour?: number | null | undefined
        instructionForTask?: string | null | undefined
        kpis?: string | null | undefined
        role?: string | null | undefined
        why?: string | null | undefined
        order?: number | null | undefined
        resources?: string | null | undefined
        createdBy?: string | null | undefined
        taskPriority?: BusinessGoalPriority | null | undefined
        category?: BusinessGoalCategory | null | undefined
        assigneeId?: string | null | undefined
        howToLinks?:
          | Array<
              | {
                  __typename?: 'UserTaskHowTo'
                  howToId: string
                  title?: string | null | undefined
                  url?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetOnboardingFlowStateFieldsFragment = {
  __typename?: 'OnboardingFlowState'
  userId: string
  flowType?: OnboardingFlowType | null | undefined
  status: OnboardingFlowStepStatus
  step: string
  toolProcessingData?: Array<string> | null | undefined
  metadata: string
  currentStepType: OnboardingFlowStepTypes
}

export type GetOnboardingFlowStateQueryVariables = Exact<{
  input: GetOnboardingFlowStateInput
}>

export type GetOnboardingFlowStateQuery = {
  __typename?: 'Query'
  getOnboardingFlowState?:
    | {
        __typename?: 'OnboardingFlowState'
        userId: string
        flowType?: OnboardingFlowType | null | undefined
        status: OnboardingFlowStepStatus
        step: string
        toolProcessingData?: Array<string> | null | undefined
        metadata: string
        currentStepType: OnboardingFlowStepTypes
      }
    | null
    | undefined
}

export const AssessmentResponseFieldsFragmentDoc = `
    fragment assessmentResponseFields on AssessmentResponse {
  id
  currentStage
  playStage
  type
  userId
  assessmentResponse {
    next
    previous
    id
    value
    label
    recommendations {
      assessments
      plays
      assessmentRecommendedAt
      playRecommendedAt
    }
    playResponse
    isIndustry
    isSubIndustry
    isTopGoal
    optionType
    submittedAt
    showInReview
    questionLabel
  }
  createdAt
  updatedAt
}
    `
export const AssessmentQuestionFieldsFragmentDoc = `
    fragment assessmentQuestionFields on Assessment {
  id
  tenantId
  previous
  assessmentType
  meta
  content
  optionType
  options
  createdAt
  updatedAt
  isVisible
}
    `
export const GetAssessmentResponseByQuestionFieldsFragmentDoc = `
    fragment getAssessmentResponseByQuestionFields on UserResponse {
  success
  message
  data {
    id
    value
    question
    meta
  }
  error
}
    `
export const UserAssessmentResponseFieldsFragmentDoc = `
    fragment userAssessmentResponseFields on UserAssessmentResponse {
  success
  message
  data {
    id
    currentStage
    playStage
    type
    userId
    assessmentResponse {
      next
      previous
      id
      value
      label
      recommendations {
        assessments
        plays
        assessmentRecommendedAt
        playRecommendedAt
      }
      playResponse
      isIndustry
      isSubIndustry
      isTopGoal
      optionType
      submittedAt
      showInReview
      questionLabel
    }
    totalItems
    totalAttemptedItems
    createdAt
    updatedAt
    completedAt
  }
  error
}
    `
export const GetUserRecommendationsFieldsFragmentDoc = `
    fragment getUserRecommendationsFields on UserRecommendationsResponse {
  success
  message
  data
  error
}
    `
export const ListAssessmentsFieldsFragmentDoc = `
    fragment listAssessmentsFields on ListAssessmentResponse {
  success
  message
  data {
    type
    order
    isCompleted
    isLocked
    inProgress
  }
  error
}
    `
export const OptionsFieldsFragmentDoc = `
    fragment optionsFields on GetOptionsResponse {
  error
  data {
    id
    type
    name
    order
    dependencies
    toolTipText
    label
    infoText
  }
}
    `
export const GetAttestationSummaryFieldsFragmentDoc = `
    fragment getAttestationSummaryFields on AttestationSummary {
  tenantId
  unsignedCount
  signedCount
  submissionStartDate
  submissionEndDate
  submissionReminderDate
  submissionReportDate
  newAggregatedReport {
    month
    year
    contractors
    businessBankAccount
    jobsCreated
    volunteers
    partTimeEmployees
    fullTimeEmployees
    gender
    race
    education
    wages
    revenue
    debtFinancing
    financialGrowth
    newCustomer
    productSale
    serviceSale
    targetAudience
    physicalLocation
    businessName
    name
    signedDocumentUrl
    error
    createdAt
    downloadUrl
    fileName
  }
  aggregationReportHistory {
    month
    year
    contractors
    businessBankAccount
    jobsCreated
    volunteers
    partTimeEmployees
    fullTimeEmployees
    gender
    race
    education
    wages
    revenue
    debtFinancing
    financialGrowth
    newCustomer
    productSale
    serviceSale
    targetAudience
    physicalLocation
    businessName
    name
    signedDocumentUrl
    error
    createdAt
    downloadUrl
    fileName
  }
}
    `
export const BusinessSupportOrganizationFieldsFragmentDoc = `
    fragment businessSupportOrganizationFields on BusinessSupportOrganization {
  id
  adminId
  name
  logoUrl
  terminology {
    term
    replacement
  }
  licenseCount
  licensesUsed
  adminCount
  advisorCount
}
    `
export const SearchIndustryReportFieldsFragmentDoc = `
    fragment searchIndustryReportFields on IndustryReport {
  summaryText
  searchedKeyword
  results {
    summary
    docUrl
    docTitle
    pageNumber
  }
}
    `
export const BusinessProfileFieldsFragmentDoc = `
    fragment businessProfileFields on BusinessProfile {
  id
  userName
  businessName
  yearsInBusiness
  companyEmployees
  createdAt
  updatedAt
  tenantId
  businessVision
  challenges
  otherGoalsOptionIds
  registerBusinessStructureOptionId
  otherRegisteredBusinessStructure
  month
  year
  contractors
  businessBankAccount
  jobsCreated
  volunteers
  partTimeEmployees
  fullTimeEmployees
  gender
  race
  education
  wages
  revenue
  debtFinancing
  financialGrowth
  newCustomer
  productSale
  serviceSale
  targetAudience
  physicalLocation
  additionalInfo
  businessClassificationOptionId
  foodSubIndustryOptionIds
  servicesSubIndustryOptionId
  strongBusinessAreasOptionIds
  supportAndHelpOptionIds
  businessLocationsOptionIds
  topGoalOptionId
  businessStageOptionId
  domainName
  businessEmail
  naicsCode
  EIN
  businessAddress {
    addressLine1
    addressLine2
    city
    state
    zipCode
  }
  logo {
    id
    name
    size
    url
  }
  hasAvatar
  avatar
  yearEstablished
  dba
  totalEmployees
  minorityOwned
  sbaCertified
  veteranOwned
  whereDoYouDoBusinessOptionIds
  revenueStatusOptionId
  otherWhereDoYouDoBusiness
  hoursSpentOptionId
  exitPlanOptionId
  otherExitPlan
  productsAndServicesOptionIds
  haveStartedGeneratingSales
  motivation
  personalGoals
  launchDate
  businessDescription
  lastYearRevenue
  thisYearRevenue
  grossRevenueGoalForThisYear
}
    `
export const BusinessProfileFragmentDoc = `
    fragment businessProfile on BusinessProfile {
  userName
  businessName
  yearsInBusiness
  companyEmployees
  createdAt
  updatedAt
  tenantId
  businessVision
  challenges
  otherGoals
  otherGoalsOptionIds
  registerBusinessStructure
  registerBusinessStructureOptionId
  otherRegisteredBusinessStructure
  month
  year
  contractors
  businessBankAccount
  jobsCreated
  volunteers
  partTimeEmployees
  fullTimeEmployees
  gender
  race
  education
  wages
  revenue
  debtFinancing
  financialGrowth
  newCustomer
  productSale
  serviceSale
  targetAudience
  physicalLocation
  additionalInfo
  businessClassification
  businessClassificationOptionId
  foodSubIndustry
  foodSubIndustryOptionIds
  servicesSubIndustry
  servicesSubIndustryOptionId
  strongBusinessAreas
  strongBusinessAreasOptionIds
  supportAndHelp
  supportAndHelpOptionIds
  businessLocations
  businessLocationsOptionIds
  topGoal
  topGoalOptionId
  businessStage
  businessStageOptionId
  noOfBusinessStarted
  thisYearRevenue
  lastYearRevenue
  businessNature
  businessNatureOptionId
  hasLogo
  hasLogoOptionId
  logo {
    id
    name
    size
    tenantId
    type
    url
    userId
  }
  businessPlan {
    id
    name
    size
    tenantId
    type
    url
    userId
  }
  EIN
  naicsCode
  numberOfBusinessBankAccounts
  hasGoogleChrome
  hasGoogleChromeOptionId
  hasDomainName
  hasDomainNameOptionId
  domainName
  hasBusinessEmail
  hasBusinessEmailOptionId
  businessEmail
  hasGoogleWorkspace
  hasGoogleWorkspaceOptionId
  hasEsignatureTool
  hasEsignatureToolOptionId
  hasBusinessPhoneNumber
  hasBusinessPhoneNumberOptionId
  hasLogoTrademarked
  hasLogoTrademarkedOptionId
  hasGoogleBusinessProfile
  hasGoogleBusinessProfileOptionId
  googleWorkspacePlaysOptionIds
  googleWorkspacePlays
  googleBusinessProfilePlaysOptionIds
  googleBusinessProfilePlays
  onboardedAt
  monthlyImpactReports {
    id
    name
    size
    tenantId
    type
    url
    userId
    createdAt
  }
  businessAddress {
    addressLine1
    addressLine2
    city
    state
    zipCode
  }
  hasAvatar
  yearEstablished
  dba
  totalEmployees
  minorityOwned
  sbaCertified
  veteranOwned
  avatar
  businessDescription
  personalGoals
  seasonalityDetails
  exitPlan
  exitPlanOptionId
  otherExitPlan
  motivation
  grossRevenueGoalForThisYear
  launchDate
  whereDoYouDoBusiness
  whereDoYouDoBusinessOptionIds
  revenueStatus
  revenueStatusOptionId
  otherWhereDoYouDoBusiness
  hoursSpent
  hoursSpentOptionId
  productsAndServices
  productsAndServicesOptionIds
  haveStartedGeneratingSales
  lastUpdatedBusinessPlan
}
    `
export const BusinessSummaryResponseFieldsFragmentDoc = `
    fragment businessSummaryResponseFields on BusinessSummaryResponse {
  data {
    optionType
    data {
      optionType
      optionName
      optionId
      tenantId
      summary
    }
  }
  status
}
    `
export const CyclrConnectorFieldsFragmentDoc = `
    fragment cyclrConnectorFields on CyclrConnectorResponse {
  success
  message
  data {
    id
    name
    authenticated
    connectorId
  }
  error
}
    `
export const CalenderEventFieldsFragmentDoc = `
    fragment calenderEventFields on CalendarEventResponse {
  success
  message
  data {
    summary
    description
    start {
      dateTime
      timeZone
    }
    end {
      dateTime
      timeZone
    }
    status
    eventTime
    meta
    location
    eventType
    attendees {
      email
      responseStatus
      optional
    }
    advisorProfiles
    profile
    timezone
    eventId
    inviteeId
    advisorId
    availability
    ownerId
    videoLinkDescription
    id
    isAllDay
    participantName
    organizerName
    participantFirstName
    participantLastName
    organizerFirstName
    organizerLastName
    externalReferenceIds {
      calendarId
      type
      externalEventId
      userId
      eventId
      status
      meta
      createdAt
      startDate
    }
  }
  error
}
    `
export const OAuthUrlFieldsFragmentDoc = `
    fragment oAuthUrlFields on OAuthUrlResponse {
  success
  message
  data
  error
}
    `
export const UserMeetingFieldsFragmentDoc = `
    fragment userMeetingFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const CalendlyUserFieldsFragmentDoc = `
    fragment calendlyUserFields on CalendlyUserResponse {
  success
  message
  data {
    email
    name
    schedulingUrl
    calendlyEventsUrl
  }
  error
}
    `
export const DeleteCalendarFieldsFragmentDoc = `
    fragment deleteCalendarFields on DeleteCalendarEventResponse {
  success
  message
  data
  error
}
    `
export const DeleteGoogleCalendarEventFieldsFragmentDoc = `
    fragment deleteGoogleCalendarEventFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const CancelOutlookEventFieldsFragmentDoc = `
    fragment cancelOutlookEventFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const UpdateGoogleCalendarEventFieldsFragmentDoc = `
    fragment updateGoogleCalendarEventFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const GetMeetingTypesFieldsFragmentDoc = `
    fragment getMeetingTypesFields on GetMeetingTypeResponse {
  success
  message
  data {
    id
    tenantId
    duration
    label
  }
  error
}
    `
export const SetUserAvailabilityFieldsFragmentDoc = `
    fragment setUserAvailabilityFields on SetUserAvailabilityResponse {
  data {
    createdAt
    updatedAt
    userId
    tenantId
    id
    availabilityHours {
      duration
      timezone
      timeSlots {
        label
        value
        slots {
          startTime
          endTime
        }
      }
    }
  }
  error
}
    `
export const GetUserAvailabilityFieldsFragmentDoc = `
    fragment getUserAvailabilityFields on GetUserAvailabilityResponse {
  data {
    duration
    availability {
      date
      slots {
        isAvailable
        slotStartTime
        slotEndTime
      }
    }
  }
  meetingLocation
  error
}
    `
export const SetUserMeetingResponseFieldsFragmentDoc = `
    fragment setUserMeetingResponseFields on UserMeetingData {
  id
  eventId
  updatedAt
  createdAt
  participantId
  tenantId
  startTime
  endTime
  description
  ownerId
  inviteeId
  title
  timezone
  attendees {
    email
    responseStatus
    optional
  }
  role
  status
  location
  videoLinkDescription
  advisorName
  organizerName
}
    `
export const UpdateUserCalendarFieldsFragmentDoc = `
    fragment UpdateUserCalendarFields on UpdateUserCalendarEventResponse {
  data {
    title
    eventId
    location
    tackleId
    eventType
    description
    comment
    attendees {
      email
      organizer
      self
      responseStatus
    }
    videoLinkDescription
  }
  error
}
    `
export const SubscribeGoogleCalendarWebhookFieldsFragmentDoc = `
    fragment subscribeGoogleCalendarWebhookFields on SubscribeGoogleCalendarWebhookResponse {
  data
  error
}
    `
export const CancelUserCalendarFieldsFragmentDoc = `
    fragment CancelUserCalendarFields on CancelUserCalendarEventResponse {
  data {
    tackleId
    eventId
    eventType
  }
  error
}
    `
export const UpdateUserAvailabilityFieldsFragmentDoc = `
    fragment updateUserAvailabilityFields on UpdateUserAvailabilityResponse {
  data {
    createdAt
    updatedAt
    userId
    tenantId
    id
    availabilityHours {
      duration
      timezone
      timeSlots {
        label
        value
        slots {
          startTime
          endTime
        }
      }
    }
  }
  error
}
    `
export const GetUserAvailabilityHoursFieldsFragmentDoc = `
    fragment getUserAvailabilityHoursFields on GetUserAvailabilityHoursResponse {
  data {
    createdAt
    updatedAt
    userId
    tenantId
    id
    availabilityHours {
      duration
      timezone
      timeSlots {
        label
        value
        slots {
          startTime
          endTime
        }
      }
    }
  }
  error
}
    `
export const GetUserMeetingByIdFieldsFragmentDoc = `
    fragment getUserMeetingByIdFields on GetUserMeetingByIdResponse {
  data {
    summary
    description
    start {
      dateTime
      timeZone
    }
    end {
      dateTime
      timeZone
    }
    status
    eventTime
    meta
    location
    eventType
    attendees {
      email
      responseStatus
      optional
    }
    advisorProfiles
    profile
    timezone
    eventId
    inviteeId
    advisorId
    ownerId
    availability
    comment
    videoLinkDescription
    id
    participantName
    organizerName
    participantFirstName
    participantLastName
    organizerFirstName
    organizerLastName
  }
  error
}
    `
export const LiquidCashReportFieldsFragmentDoc = `
    fragment liquidCashReportFields on LiquidCashReport {
  liquidCash
  source
  dateRange
  endDate
  growth
  startDate
}
    `
export const CashBalanceReportFieldsFragmentDoc = `
    fragment cashBalanceReportFields on CashBalanceReport {
  cashBalance
  source
  dateRange
  endDate
  growth
  startDate
}
    `
export const ProfitAndLossReportFieldsFragmentDoc = `
    fragment profitAndLossReportFields on ProfitAndLossReport {
  profitAndLossNetIncome
  source
  dateRange
  endDate
  growth
  startDate
}
    `
export const CashBalanceByMonthReportFieldsFragmentDoc = `
    fragment cashBalanceByMonthReportFields on CashBalanceByMonthReport {
  startDate
  endDate
  cashBalance
  source
}
    `
export const AccountingReportOutputFragmentFragmentDoc = `
    fragment AccountingReportOutputFragment on AccountingReportOutput {
  ...liquidCashReportFields
  ...cashBalanceReportFields
  ...profitAndLossReportFields
  ...cashBalanceByMonthReportFields
}
    ${LiquidCashReportFieldsFragmentDoc}
${CashBalanceReportFieldsFragmentDoc}
${ProfitAndLossReportFieldsFragmentDoc}
${CashBalanceByMonthReportFieldsFragmentDoc}`
export const AccountingChartFragmentFragmentDoc = `
    fragment AccountingChartFragment on AccountingChartResponse {
  data {
    __typename
    ...AccountingReportOutputFragment
  }
  status
}
    ${AccountingReportOutputFragmentFragmentDoc}`
export const CreateChatSessionFieldsFragmentDoc = `
    fragment createChatSessionFields on ChatSession {
  streamUrl
}
    `
export const UserTaskHowToResponseFieldsFragmentDoc = `
    fragment userTaskHowToResponseFields on UserTaskHowToResponse {
  text
  videoLink
}
    `
export const SendMessageToBotFieldsFragmentDoc = `
    fragment sendMessageToBotFields on SendMessageToBotResponse {
  response
  nextFlow
  isFinished
  flowType
}
    `
export const GetChatSessionsFieldsFragmentDoc = `
    fragment getChatSessionsFields on MessageChatSession {
  id
  flow
  createdAt
  title
}
    `
export const AdvisorDashboardFieldsFragmentDoc = `
    fragment AdvisorDashboardFields on ApiResponse {
  success
  message
  data
  data
  error
}
    `
export const TopActiveClientsFieldsFragmentDoc = `
    fragment TopActiveClientsFields on GetTopActiveClientsResponse {
  error
  data {
    id
    firstName
    lastName
    tenantId
    email
    score
    totalTime
    topGoal
    industry
    businessName
    logo {
      id
      name
      size
      tenantId
      type
      url
      userId
    }
  }
}
    `
export const GetUserToolsFieldsFragmentDoc = `
    fragment getUserToolsFields on GetUserToolsResponse {
  success
  message
  data {
    id
    cyclrToolId
    imageUrl
    toolName
    toolDisplayName
    userToolsImageUrl
    connectionFormImageUrl
    toolUrl
    isConnected
    isRecommended
    recommendedBy
    isSelected
    group
    category
    visible
    connectionStatus
    connectorName
    isBookmarked
    supportLevel
    options {
      connectionForm {
        description
        inputProperties {
          name
          displayName
          placeholder
          type
          validationRules
        }
      }
    }
    description
    diagnosticSupported
    longDescription
    toolHelpText
    relatedPlays
    authType
  }
  error
}
    `
export const BookmarkToolFieldsFragmentDoc = `
    fragment bookmarkToolFields on BookmarkToolResponse {
  success
  message
  data {
    id
    cyclrToolId
    imageUrl
    toolName
    toolDisplayName
    userToolsImageUrl
    connectionFormImageUrl
    toolUrl
    isConnected
    isRecommended
    recommendedBy
    isSelected
    group
    category
    visible
    connectionStatus
    connectorName
    isBookmarked
    supportLevel
    options {
      connectionForm {
        description
        inputProperties {
          name
          displayName
          placeholder
          type
          validationRules
        }
      }
    }
    description
    diagnosticSupported
    longDescription
    toolHelpText
  }
  error
}
    `
export const ClientsFieldsFragmentDoc = `
    fragment ClientsFields on GetClientsResponse {
  success
  message
  data {
    id
    name
    firstName
    lastName
    email
    createdAt
    updatedAt
    websiteURL
    bio
    roles
    isNewUser
    businessName
    mobileContactNumber
    yearsInBusiness
    companyAdvisor
    schedulingUrl
    calendlyEventsUrl
  }
  error
}
    `
export const TackleMeetingFieldsFragmentDoc = `
    fragment tackleMeetingFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const ActivityLogFieldsFragmentDoc = `
    fragment activityLogFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const OwnerActivityDetailsFieldsFragmentDoc = `
    fragment ownerActivityDetailsFields on OwnerActivitySummary {
  timesSignedIn
  timeOnPlatform
  toolsIntegrated
  chats
  meetingsScheduled
  meetingsReScheduled
  signInPerWeek
  avgMinsPerSession
}
    `
export const UserIntegrationFieldsFragmentDoc = `
    fragment userIntegrationFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const TackleMeetingsFieldsFragmentDoc = `
    fragment tackleMeetingsFields on GetTackleMeetingResponse {
  success
  message
  data {
    summary
    description
    start {
      dateTime
      timeZone
    }
    end {
      dateTime
      timeZone
    }
    status
    eventTime
    meta
    location
    eventType
    attendees {
      email
      responseStatus
      optional
    }
    advisorProfiles
    profile
    timezone
    eventId
    inviteeId
    advisorId
    availability
    comment
    videoLinkDescription
    organizerName
    participantName
    ownerId
    participantFirstName
    participantLastName
    organizerFirstName
    organizerLastName
  }
  pagination
  error
}
    `
export const DeauthenticateConnectorFieldsFragmentDoc = `
    fragment deauthenticateConnectorFields on ApiResponse {
  message
  data
  error
}
    `
export const GetAllToolsFieldsFragmentDoc = `
    fragment getAllToolsFields on GetAllToolsResponse {
  success
  message
  data {
    id
    toolDisplayName
    cyclrToolId
    imageUrl
    toolName
    userToolsImageUrl
    connectionFormImageUrl
    toolUrl
    isConnected
    isRecommended
    recommendedBy
    isSelected
    group
    category
    visible
    supportLevel
    relatedPlays
    isBookmarked
    supportLevel
    options {
      connectionForm {
        description
        inputProperties {
          name
          displayName
          placeholder
          type
          validationRules
        }
      }
    }
    description
    diagnosticSupported
    longDescription
    toolHelpText
    authType
  }
  error
}
    `
export const GetAttestationReportsFieldsFragmentDoc = `
    fragment getAttestationReportsFields on GetAttestationReportsResponse {
  error
  data {
    lastEvaluatedKey
    prevEvaluatedKey
    totalPages
    totalRecords
    pageSize
    currentPage
    items {
      month
      year
      contractors
      businessBankAccount
      jobsCreated
      volunteers
      partTimeEmployees
      fullTimeEmployees
      gender
      race
      education
      wages
      revenue
      debtFinancing
      financialGrowth
      newCustomer
      productSale
      serviceSale
      targetAudience
      physicalLocation
      businessName
      name
      signedDocumentUrl
      properName
    }
  }
}
    `
export const UpdateOwnerFieldsFragmentDoc = `
    fragment updateOwnerFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const GoalDetailsFieldsFragmentDoc = `
    fragment goalDetailsFields on Goal {
  id
  name
  description
  isTopGoal
  isActiveGoal
  assessments
  createdAt
  orderOfSequence
}
    `
export const GetPendingJobsFieldsFragmentDoc = `
    fragment getPendingJobsFields on JobDto {
  id
  status
  type
  createdAt
  updatedAt
  payload
}
    `
export const ChartDataFieldsFragmentDoc = `
    fragment chartDataFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const OwnerBusinessDataFieldsFragmentDoc = `
    fragment ownerBusinessDataFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const GetPlayFieldsFragmentDoc = `
    fragment getPlayFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const GetPlaysFieldsFragmentDoc = `
    fragment getPlaysFields on GetPlaysResponse {
  success
  message
  data {
    id
    content
    title
    type
    recommendedBy
    meta
    guide
    guide1
    setup
    use
    description
    playTabs
  }
  error
}
    `
export const AuthorizeConnectorFieldsFragmentDoc = `
    fragment authorizeConnectorFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const PlayResponseFieldsFragmentDoc = `
    fragment playResponseFields on PlayResponse {
  tenantId
  playId
  playType
  isCompleted
  completedAt
  completedBy
  recommendations
  businessProfileFields {
    logo
  }
  playTabResponse {
    id
    tabName
    isCompleted
    completedAt
    stepResponse {
      id
      stepTitle
      isCompleted
      completedAt
      stepData {
        id
        value
        label
        isCompleted
        completedAt
        recommendations
        isMeetingScheduled
        businessProfileFields {
          logo {
            id
            name
            size
            tenantId
            type
            url
            userId
          }
        }
      }
    }
  }
}
    `
export const DashboardListingFieldsFragmentDoc = `
    fragment dashboardListingFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const AssessmentPlayListingFieldsFragmentDoc = `
    fragment assessmentPlayListingFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const GetToolsFieldsFragmentDoc = `
    fragment getToolsFields on GetToolsResponse {
  success
  message
  data {
    id
    toolName
  }
  error
}
    `
export const ToolFieldsFragmentDoc = `
    fragment toolFields on GetToolsByTypeResponse {
  error
  data {
    id
    cyclrToolId
    imageUrl
    toolName
    userToolsImageUrl
    toolUrl
    isConnected
    isRecommended
    recommendedBy
    isSelected
    group
    category
    visible
    toolType
    authType
    supportLevel
  }
}
    `
export const ToolCategoriesFieldsFragmentDoc = `
    fragment toolCategoriesFields on GetAllToolCategoriesResponse {
  categories {
    name
    description
  }
  subCategories {
    name
  }
}
    `
export const SearchToolsFieldsFragmentDoc = `
    fragment searchToolsFields on SearchToolsResponse {
  data {
    id
    cyclrToolId
    imageUrl
    toolName
    toolDisplayName
    connectionFormImageUrl
    userToolsImageUrl
    toolUrl
    isConnected
    isRecommended
    recommendedBy
    isSelected
    group
    category
    visible
    connectionStatus
    connectorName
    pricingPageUrl
    tags
    subCategory
    options {
      connectionForm {
        description
        inputProperties {
          name
          displayName
          placeholder
          type
          validationRules
        }
      }
    }
    supportLevel
    relatedPlays
    isBookmarked
    description
    diagnosticSupported
    longDescription
    authType
    toolHelpText
    categories {
      category
      subCategory
    }
    clientId
    clientSecret
  }
  numberOfPages
}
    `
export const GoalSignedUrlFieldsFragmentDoc = `
    fragment goalSignedUrlFields on SignedUrlResponse {
  success
  message
  data
  error
}
    `
export const CognitoUserFieldsFragmentDoc = `
    fragment cognitoUserFields on CognitoUser {
  email
  userName
  enabled
  userStatus
  name
}
    `
export const GetUserFilesFiledsFragmentDoc = `
    fragment getUserFilesFileds on ApiResponse {
  success
  message
  data
  error
}
    `
export const SaveUserFileFieldsFragmentDoc = `
    fragment saveUserFileFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const BigQueryFieldsFragmentDoc = `
    fragment bigQueryFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const BusinessesFieldsFragmentDoc = `
    fragment BusinessesFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const AdvisorsFieldsFragmentDoc = `
    fragment AdvisorsFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const LogActivityFieldsFragmentDoc = `
    fragment logActivityFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const UserNotificationsFieldsFragmentDoc = `
    fragment UserNotificationsFields on GetUserNotificationsResponse {
  error
  data {
    lastEvaluatedKey
    totalCount
    notifications {
      id
      createdAt
      meta
      notificationType
      sender
      status
      firstName
      lastName
      updatedAt
      businessName
    }
  }
}
    `
export const UserNotificationMutateFieldsFragmentDoc = `
    fragment UserNotificationMutateFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const GetClientsSummaryFieldsFragmentDoc = `
    fragment GetClientsSummaryFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const ResendUserInviteFieldsFragmentDoc = `
    fragment resendUserInviteFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const SaveAttestationFieldsFragmentDoc = `
    fragment saveAttestationFields on AttestationResponse {
  error
  data {
    pk
    sk
    id
    tenantId
    month
    year
    contractors
    businessBankAccount
    jobsCreated
    volunteers
    partTimeEmployees
    fullTimeEmployees
    gender
    race
    education
    wages
    revenue
    debtFinancing
    financialGrowth
    newCustomer
    productSale
    serviceSale
    targetAudience
    physicalLocation
    url
  }
}
    `
export const UserProfileFieldsFragmentDoc = `
    fragment userProfileFields on UserProfile {
  id
  name
  firstName
  lastName
  email
  createdAt
  updatedAt
  websiteURL
  bio
  roles
  isNewUser
  mobileContactNumber
  yearsInBusiness
  companyEmployees
  companyAdvisor
  schedulingUrl
  calendlyEventsUrl
  tenantId
  streamAccessToken
  algoliaSearchKey
  firstSigninDate
  activatedAt
  status
  ownerExperience
  submissionStartDay
  submissionEndDay
  submissionReminderDay
  submissionReportDay
  attestationStatus
  isSmsEnabled
  defaultVideoLinkDescription
  organizationName
  timezone
  interimTimezone
  smsSubscriptionStatus
  tempPassword
  calendarEventViewPreference
  isConsentGivenToAdvisor
  onboardingStatus
}
    `
export const BoAdvisorsFieldsFragmentDoc = `
    fragment BoAdvisorsFields on BOAdvisorsResponse {
  success
  message
  data {
    id
    firstName
    lastName
    email
    schedulingUrl
  }
  error
}
    `
export const SaveAssessmentResponseInputFragmentDoc = `
    fragment SaveAssessmentResponseInput on AssessmentResponse {
  currentStage
  type
  assessmentResponse {
    next
    previous
    id
    value
    label
    recommendations {
      assessments
      plays
      assessmentRecommendedAt
      playRecommendedAt
    }
    playResponse
    isIndustry
    isSubIndustry
    isTopGoal
  }
  userId
  createdAt
  updatedAt
}
    `
export const AdvisorProfileFieldsFragmentDoc = `
    fragment advisorProfileFields on AdvisorProfile {
  id
  firstName
  lastName
  defaultVideoLinkDescription
}
    `
export const BusinessIndustryChangedFieldsFragmentDoc = `
    fragment businessIndustryChangedFields on GetBusinessIndustryDetailsResponse {
  isSubIndustryUpdated
}
    `
export const TaskFragmentDoc = `
    fragment task on UserTask {
  tenantId
  userId
  taskId
  planId
  milestoneId
  name
  status
  tool
  timeline
  startDate
  expectedEndDate
  howToDo
  estimationHour
  instructionForTask
  kpis
  role
  why
  order
  resources
  createdBy
  howToLinks {
    howToId
    title
    url
  }
  taskPriority
  category
  assigneeId
}
    `
export const GoalsFieldsFragmentDoc = `
    fragment goalsFields on BusinessGoal {
  goalId
  name
  status
  progress
  goalPriority
  description
  category
  goalTimeFrame
  logo
  expectedEndDate
  plan {
    planId
    goalId
    name
    startDate
    expectedEndDate
    timeline
    state
    progress
    pauseDuration
    pauseDate
    tasks {
      ...task
    }
    milestones {
      planId
      milestoneId
      name
      descriptions
      timeline
      startDate
      expectedEndDate
      riskAndChallenges
      status
      order
      milestoneSpan
      tasks {
        ...task
      }
    }
  }
}
    ${TaskFragmentDoc}`
export const GoalFieldsFragmentDoc = `
    fragment goalFields on BusinessGoal {
  goalId
  userId
  name
  status
  progress
  plan {
    planId
    goalId
    name
    startDate
    expectedEndDate
    timeline
    state
    progress
    pauseDuration
    pauseDate
    tasks {
      ...task
    }
    milestones {
      planId
      milestoneId
      name
      descriptions
      timeline
      startDate
      expectedEndDate
      riskAndChallenges
      status
      order
      milestoneSpan
      tasks {
        ...task
      }
    }
  }
}
    ${TaskFragmentDoc}`
export const UserTaskFieldsFragmentDoc = `
    fragment userTaskFields on UserTask {
  ...task
}
    ${TaskFragmentDoc}`
export const GetOnboardingFlowStateFieldsFragmentDoc = `
    fragment getOnboardingFlowStateFields on OnboardingFlowState {
  userId
  flowType
  status
  step
  toolProcessingData
  metadata
  currentStepType
}
    `
export const GetUserAssessmentResponseDocument = `
    query getUserAssessmentResponse($userId: String, $type: String) {
  getUserAssessmentResponse(type: $type, userId: $userId) {
    ...userAssessmentResponseFields
  }
}
    ${UserAssessmentResponseFieldsFragmentDoc}`
export const useGetUserAssessmentResponseQuery = <
  TData = GetUserAssessmentResponseQuery,
  TError = any
>(
  variables?: GetUserAssessmentResponseQueryVariables,
  options?: UseQueryOptions<GetUserAssessmentResponseQuery, TError, TData>
) =>
  useQuery<GetUserAssessmentResponseQuery, TError, TData>(
    variables === undefined
      ? ['getUserAssessmentResponse']
      : ['getUserAssessmentResponse', variables],
    fetcher<GetUserAssessmentResponseQuery, GetUserAssessmentResponseQueryVariables>(
      GetUserAssessmentResponseDocument,
      variables
    ),
    options
  )
export const GetAssessmentsDocument = `
    query getAssessments($type: String) {
  getAssessments(type: $type) {
    ...assessmentQuestionFields
  }
}
    ${AssessmentQuestionFieldsFragmentDoc}`
export const useGetAssessmentsQuery = <TData = GetAssessmentsQuery, TError = any>(
  variables?: GetAssessmentsQueryVariables,
  options?: UseQueryOptions<GetAssessmentsQuery, TError, TData>
) =>
  useQuery<GetAssessmentsQuery, TError, TData>(
    variables === undefined ? ['getAssessments'] : ['getAssessments', variables],
    fetcher<GetAssessmentsQuery, GetAssessmentsQueryVariables>(GetAssessmentsDocument, variables),
    options
  )
export const SaveAssessmentResponseDocument = `
    mutation saveAssessmentResponse($input: SaveAssessmentResponseInput!) {
  saveAssessmentResponse(input: $input) {
    ...assessmentResponseFields
  }
}
    ${AssessmentResponseFieldsFragmentDoc}`
export const useSaveAssessmentResponseMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    SaveAssessmentResponseMutation,
    TError,
    SaveAssessmentResponseMutationVariables,
    TContext
  >
) =>
  useMutation<
    SaveAssessmentResponseMutation,
    TError,
    SaveAssessmentResponseMutationVariables,
    TContext
  >(
    ['saveAssessmentResponse'],
    (variables?: SaveAssessmentResponseMutationVariables) =>
      fetcher<SaveAssessmentResponseMutation, SaveAssessmentResponseMutationVariables>(
        SaveAssessmentResponseDocument,
        variables
      )(),
    options
  )
export const GetAssessmentResponseByQuestionDocument = `
    query getAssessmentResponseByQuestion($type: String, $question: String, $userId: String) {
  getAssessmentResponseByQuestion(
    type: $type
    question: $question
    userId: $userId
  ) {
    ...getAssessmentResponseByQuestionFields
  }
}
    ${GetAssessmentResponseByQuestionFieldsFragmentDoc}`
export const useGetAssessmentResponseByQuestionQuery = <
  TData = GetAssessmentResponseByQuestionQuery,
  TError = any
>(
  variables?: GetAssessmentResponseByQuestionQueryVariables,
  options?: UseQueryOptions<GetAssessmentResponseByQuestionQuery, TError, TData>
) =>
  useQuery<GetAssessmentResponseByQuestionQuery, TError, TData>(
    variables === undefined
      ? ['getAssessmentResponseByQuestion']
      : ['getAssessmentResponseByQuestion', variables],
    fetcher<GetAssessmentResponseByQuestionQuery, GetAssessmentResponseByQuestionQueryVariables>(
      GetAssessmentResponseByQuestionDocument,
      variables
    ),
    options
  )
export const GetUserRecommendationsDocument = `
    query getUserRecommendations($input: GetUserRecommendationsInput!) {
  getUserRecommendations(input: $input) {
    ...getUserRecommendationsFields
  }
}
    ${GetUserRecommendationsFieldsFragmentDoc}`
export const useGetUserRecommendationsQuery = <TData = GetUserRecommendationsQuery, TError = any>(
  variables: GetUserRecommendationsQueryVariables,
  options?: UseQueryOptions<GetUserRecommendationsQuery, TError, TData>
) =>
  useQuery<GetUserRecommendationsQuery, TError, TData>(
    ['getUserRecommendations', variables],
    fetcher<GetUserRecommendationsQuery, GetUserRecommendationsQueryVariables>(
      GetUserRecommendationsDocument,
      variables
    ),
    options
  )
export const ListAssessmentsDocument = `
    query listAssessments($userId: String, $tenantId: String!) {
  listAssessments(userId: $userId, tenantId: $tenantId) {
    ...listAssessmentsFields
  }
}
    ${ListAssessmentsFieldsFragmentDoc}`
export const useListAssessmentsQuery = <TData = ListAssessmentsQuery, TError = any>(
  variables: ListAssessmentsQueryVariables,
  options?: UseQueryOptions<ListAssessmentsQuery, TError, TData>
) =>
  useQuery<ListAssessmentsQuery, TError, TData>(
    ['listAssessments', variables],
    fetcher<ListAssessmentsQuery, ListAssessmentsQueryVariables>(
      ListAssessmentsDocument,
      variables
    ),
    options
  )
export const GetOptionsDocument = `
    query getOptions($optionTypes: [String!]!) {
  getOptions(optionTypes: $optionTypes) {
    ...optionsFields
  }
}
    ${OptionsFieldsFragmentDoc}`
export const useGetOptionsQuery = <TData = GetOptionsQuery, TError = any>(
  variables: GetOptionsQueryVariables,
  options?: UseQueryOptions<GetOptionsQuery, TError, TData>
) =>
  useQuery<GetOptionsQuery, TError, TData>(
    ['getOptions', variables],
    fetcher<GetOptionsQuery, GetOptionsQueryVariables>(GetOptionsDocument, variables),
    options
  )
export const GetAttestationSummaryDocument = `
    query getAttestationSummary($input: GetAttestationSummaryInput) {
  getAttestationSummary(input: $input) {
    ...getAttestationSummaryFields
  }
}
    ${GetAttestationSummaryFieldsFragmentDoc}`
export const useGetAttestationSummaryQuery = <TData = GetAttestationSummaryQuery, TError = any>(
  variables?: GetAttestationSummaryQueryVariables,
  options?: UseQueryOptions<GetAttestationSummaryQuery, TError, TData>
) =>
  useQuery<GetAttestationSummaryQuery, TError, TData>(
    variables === undefined ? ['getAttestationSummary'] : ['getAttestationSummary', variables],
    fetcher<GetAttestationSummaryQuery, GetAttestationSummaryQueryVariables>(
      GetAttestationSummaryDocument,
      variables
    ),
    options
  )
export const GetBusinessSupportOrganizationDocument = `
    query getBusinessSupportOrganization($input: GetBusinessSupportOrganizationInput!) {
  getBusinessSupportOrganization(input: $input) {
    ...businessSupportOrganizationFields
  }
}
    ${BusinessSupportOrganizationFieldsFragmentDoc}`
export const useGetBusinessSupportOrganizationQuery = <
  TData = GetBusinessSupportOrganizationQuery,
  TError = any
>(
  variables: GetBusinessSupportOrganizationQueryVariables,
  options?: UseQueryOptions<GetBusinessSupportOrganizationQuery, TError, TData>
) =>
  useQuery<GetBusinessSupportOrganizationQuery, TError, TData>(
    ['getBusinessSupportOrganization', variables],
    fetcher<GetBusinessSupportOrganizationQuery, GetBusinessSupportOrganizationQueryVariables>(
      GetBusinessSupportOrganizationDocument,
      variables
    ),
    options
  )
export const SearchIndustryReportDocument = `
    query searchIndustryReport($input: SearchIndustryReportInput!) {
  searchIndustryReport(input: $input) {
    ...searchIndustryReportFields
  }
}
    ${SearchIndustryReportFieldsFragmentDoc}`
export const useSearchIndustryReportQuery = <TData = SearchIndustryReportQuery, TError = any>(
  variables: SearchIndustryReportQueryVariables,
  options?: UseQueryOptions<SearchIndustryReportQuery, TError, TData>
) =>
  useQuery<SearchIndustryReportQuery, TError, TData>(
    ['searchIndustryReport', variables],
    fetcher<SearchIndustryReportQuery, SearchIndustryReportQueryVariables>(
      SearchIndustryReportDocument,
      variables
    ),
    options
  )
export const GetBusinessProfileDocument = `
    query getBusinessProfile($id: ID) {
  getBusinessProfile(id: $id) {
    ...businessProfile
  }
}
    ${BusinessProfileFragmentDoc}`
export const useGetBusinessProfileQuery = <TData = GetBusinessProfileQuery, TError = any>(
  variables?: GetBusinessProfileQueryVariables,
  options?: UseQueryOptions<GetBusinessProfileQuery, TError, TData>
) =>
  useQuery<GetBusinessProfileQuery, TError, TData>(
    variables === undefined ? ['getBusinessProfile'] : ['getBusinessProfile', variables],
    fetcher<GetBusinessProfileQuery, GetBusinessProfileQueryVariables>(
      GetBusinessProfileDocument,
      variables
    ),
    options
  )
export const UpdateBusinessProfileDocument = `
    mutation updateBusinessProfile($input: UpdateBusinessProfileInput!) {
  updateBusinessProfile(input: $input) {
    ...businessProfileFields
  }
}
    ${BusinessProfileFieldsFragmentDoc}`
export const useUpdateBusinessProfileMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateBusinessProfileMutation,
    TError,
    UpdateBusinessProfileMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateBusinessProfileMutation,
    TError,
    UpdateBusinessProfileMutationVariables,
    TContext
  >(
    ['updateBusinessProfile'],
    (variables?: UpdateBusinessProfileMutationVariables) =>
      fetcher<UpdateBusinessProfileMutation, UpdateBusinessProfileMutationVariables>(
        UpdateBusinessProfileDocument,
        variables
      )(),
    options
  )
export const GetBusinessSummaryDocument = `
    query getBusinessSummary($input: GetBusinessSummaryInput!) {
  getBusinessSummary(input: $input) {
    ...businessSummaryResponseFields
  }
}
    ${BusinessSummaryResponseFieldsFragmentDoc}`
export const useGetBusinessSummaryQuery = <TData = GetBusinessSummaryQuery, TError = any>(
  variables: GetBusinessSummaryQueryVariables,
  options?: UseQueryOptions<GetBusinessSummaryQuery, TError, TData>
) =>
  useQuery<GetBusinessSummaryQuery, TError, TData>(
    ['getBusinessSummary', variables],
    fetcher<GetBusinessSummaryQuery, GetBusinessSummaryQueryVariables>(
      GetBusinessSummaryDocument,
      variables
    ),
    options
  )
export const GetUserConnectorsDocument = `
    query getUserConnectors($connectorName: String) {
  getUserConnectors(connectorName: $connectorName) {
    ...cyclrConnectorFields
  }
}
    ${CyclrConnectorFieldsFragmentDoc}`
export const useGetUserConnectorsQuery = <TData = GetUserConnectorsQuery, TError = any>(
  variables?: GetUserConnectorsQueryVariables,
  options?: UseQueryOptions<GetUserConnectorsQuery, TError, TData>
) =>
  useQuery<GetUserConnectorsQuery, TError, TData>(
    variables === undefined ? ['getUserConnectors'] : ['getUserConnectors', variables],
    fetcher<GetUserConnectorsQuery, GetUserConnectorsQueryVariables>(
      GetUserConnectorsDocument,
      variables
    ),
    options
  )
export const GetUserCalendarEventsDocument = `
    query getUserCalendarEvents($calendarName: String, $timeMin: String, $timeMax: String, $tenantId: String) {
  getUserCalendarEvents(
    calendarName: $calendarName
    timeMin: $timeMin
    timeMax: $timeMax
    tenantId: $tenantId
  ) {
    ...calenderEventFields
  }
}
    ${CalenderEventFieldsFragmentDoc}`
export const useGetUserCalendarEventsQuery = <TData = GetUserCalendarEventsQuery, TError = any>(
  variables?: GetUserCalendarEventsQueryVariables,
  options?: UseQueryOptions<GetUserCalendarEventsQuery, TError, TData>
) =>
  useQuery<GetUserCalendarEventsQuery, TError, TData>(
    variables === undefined ? ['getUserCalendarEvents'] : ['getUserCalendarEvents', variables],
    fetcher<GetUserCalendarEventsQuery, GetUserCalendarEventsQueryVariables>(
      GetUserCalendarEventsDocument,
      variables
    ),
    options
  )
export const GetOAuthUrlDocument = `
    query getOAuthUrl($input: GetOAuthUrlInput!) {
  getOAuthUrl(input: $input) {
    ...oAuthUrlFields
  }
}
    ${OAuthUrlFieldsFragmentDoc}`
export const useGetOAuthUrlQuery = <TData = GetOAuthUrlQuery, TError = any>(
  variables: GetOAuthUrlQueryVariables,
  options?: UseQueryOptions<GetOAuthUrlQuery, TError, TData>
) =>
  useQuery<GetOAuthUrlQuery, TError, TData>(
    ['getOAuthUrl', variables],
    fetcher<GetOAuthUrlQuery, GetOAuthUrlQueryVariables>(GetOAuthUrlDocument, variables),
    options
  )
export const GetCalendlyUserDocument = `
    query getCalendlyUser {
  getCalendlyUser {
    ...calendlyUserFields
  }
}
    ${CalendlyUserFieldsFragmentDoc}`
export const useGetCalendlyUserQuery = <TData = GetCalendlyUserQuery, TError = any>(
  variables?: GetCalendlyUserQueryVariables,
  options?: UseQueryOptions<GetCalendlyUserQuery, TError, TData>
) =>
  useQuery<GetCalendlyUserQuery, TError, TData>(
    variables === undefined ? ['getCalendlyUser'] : ['getCalendlyUser', variables],
    fetcher<GetCalendlyUserQuery, GetCalendlyUserQueryVariables>(
      GetCalendlyUserDocument,
      variables
    ),
    options
  )
export const SubscribeCalendlyWebhookDocument = `
    query subscribeCalendlyWebhook($tenantId: String, $email: String) {
  subscribeCalendlyWebhook(tenantId: $tenantId, email: $email) {
    ...userMeetingFields
  }
}
    ${UserMeetingFieldsFragmentDoc}`
export const useSubscribeCalendlyWebhookQuery = <
  TData = SubscribeCalendlyWebhookQuery,
  TError = any
>(
  variables?: SubscribeCalendlyWebhookQueryVariables,
  options?: UseQueryOptions<SubscribeCalendlyWebhookQuery, TError, TData>
) =>
  useQuery<SubscribeCalendlyWebhookQuery, TError, TData>(
    variables === undefined
      ? ['subscribeCalendlyWebhook']
      : ['subscribeCalendlyWebhook', variables],
    fetcher<SubscribeCalendlyWebhookQuery, SubscribeCalendlyWebhookQueryVariables>(
      SubscribeCalendlyWebhookDocument,
      variables
    ),
    options
  )
export const SubscribeGoogleCalendarWebhookDocument = `
    mutation subscribeGoogleCalendarWebhook($tenantId: String) {
  subscribeGoogleCalendarWebhook(tenantId: $tenantId) {
    ...subscribeGoogleCalendarWebhookFields
  }
}
    ${SubscribeGoogleCalendarWebhookFieldsFragmentDoc}`
export const useSubscribeGoogleCalendarWebhookMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    SubscribeGoogleCalendarWebhookMutation,
    TError,
    SubscribeGoogleCalendarWebhookMutationVariables,
    TContext
  >
) =>
  useMutation<
    SubscribeGoogleCalendarWebhookMutation,
    TError,
    SubscribeGoogleCalendarWebhookMutationVariables,
    TContext
  >(
    ['subscribeGoogleCalendarWebhook'],
    (variables?: SubscribeGoogleCalendarWebhookMutationVariables) =>
      fetcher<
        SubscribeGoogleCalendarWebhookMutation,
        SubscribeGoogleCalendarWebhookMutationVariables
      >(SubscribeGoogleCalendarWebhookDocument, variables)(),
    options
  )
export const GetUserMeetingDocument = `
    query getUserMeeting($advisorId: String) {
  getUserMeeting(advisorId: $advisorId) {
    ...userMeetingFields
  }
}
    ${UserMeetingFieldsFragmentDoc}`
export const useGetUserMeetingQuery = <TData = GetUserMeetingQuery, TError = any>(
  variables?: GetUserMeetingQueryVariables,
  options?: UseQueryOptions<GetUserMeetingQuery, TError, TData>
) =>
  useQuery<GetUserMeetingQuery, TError, TData>(
    variables === undefined ? ['getUserMeeting'] : ['getUserMeeting', variables],
    fetcher<GetUserMeetingQuery, GetUserMeetingQueryVariables>(GetUserMeetingDocument, variables),
    options
  )
export const DeleteCalendarEventDocument = `
    mutation deleteCalendarEvent($input: DeleteCalendarEventInput!) {
  deleteCalendarEvent(input: $input) {
    ...deleteCalendarFields
  }
}
    ${DeleteCalendarFieldsFragmentDoc}`
export const useDeleteCalendarEventMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteCalendarEventMutation,
    TError,
    DeleteCalendarEventMutationVariables,
    TContext
  >
) =>
  useMutation<DeleteCalendarEventMutation, TError, DeleteCalendarEventMutationVariables, TContext>(
    ['deleteCalendarEvent'],
    (variables?: DeleteCalendarEventMutationVariables) =>
      fetcher<DeleteCalendarEventMutation, DeleteCalendarEventMutationVariables>(
        DeleteCalendarEventDocument,
        variables
      )(),
    options
  )
export const DeleteGoogleCalendarEventDocument = `
    query deleteGoogleCalendarEvent($input: deleteGoogleCalendarEventInput) {
  deleteGoogleCalendarEvent(input: $input) {
    ...deleteGoogleCalendarEventFields
  }
}
    ${DeleteGoogleCalendarEventFieldsFragmentDoc}`
export const useDeleteGoogleCalendarEventQuery = <
  TData = DeleteGoogleCalendarEventQuery,
  TError = any
>(
  variables?: DeleteGoogleCalendarEventQueryVariables,
  options?: UseQueryOptions<DeleteGoogleCalendarEventQuery, TError, TData>
) =>
  useQuery<DeleteGoogleCalendarEventQuery, TError, TData>(
    variables === undefined
      ? ['deleteGoogleCalendarEvent']
      : ['deleteGoogleCalendarEvent', variables],
    fetcher<DeleteGoogleCalendarEventQuery, DeleteGoogleCalendarEventQueryVariables>(
      DeleteGoogleCalendarEventDocument,
      variables
    ),
    options
  )
export const UpdateGoogleCalendarEventDocument = `
    query updateGoogleCalendarEvent($input: updateGoogleCalendarEventInput) {
  updateGoogleCalendarEvent(input: $input) {
    ...updateGoogleCalendarEventFields
  }
}
    ${UpdateGoogleCalendarEventFieldsFragmentDoc}`
export const useUpdateGoogleCalendarEventQuery = <
  TData = UpdateGoogleCalendarEventQuery,
  TError = any
>(
  variables?: UpdateGoogleCalendarEventQueryVariables,
  options?: UseQueryOptions<UpdateGoogleCalendarEventQuery, TError, TData>
) =>
  useQuery<UpdateGoogleCalendarEventQuery, TError, TData>(
    variables === undefined
      ? ['updateGoogleCalendarEvent']
      : ['updateGoogleCalendarEvent', variables],
    fetcher<UpdateGoogleCalendarEventQuery, UpdateGoogleCalendarEventQueryVariables>(
      UpdateGoogleCalendarEventDocument,
      variables
    ),
    options
  )
export const CancelOutlookEventDocument = `
    query cancelOutlookEvent($input: cancelOutlookEventInput) {
  cancelOutlookEvent(input: $input) {
    ...cancelOutlookEventFields
  }
}
    ${CancelOutlookEventFieldsFragmentDoc}`
export const useCancelOutlookEventQuery = <TData = CancelOutlookEventQuery, TError = any>(
  variables?: CancelOutlookEventQueryVariables,
  options?: UseQueryOptions<CancelOutlookEventQuery, TError, TData>
) =>
  useQuery<CancelOutlookEventQuery, TError, TData>(
    variables === undefined ? ['cancelOutlookEvent'] : ['cancelOutlookEvent', variables],
    fetcher<CancelOutlookEventQuery, CancelOutlookEventQueryVariables>(
      CancelOutlookEventDocument,
      variables
    ),
    options
  )
export const GetMeetingTypesDocument = `
    query getMeetingTypes($tenantId: String!) {
  getMeetingTypes(tenantId: $tenantId) {
    ...getMeetingTypesFields
  }
}
    ${GetMeetingTypesFieldsFragmentDoc}`
export const useGetMeetingTypesQuery = <TData = GetMeetingTypesQuery, TError = any>(
  variables: GetMeetingTypesQueryVariables,
  options?: UseQueryOptions<GetMeetingTypesQuery, TError, TData>
) =>
  useQuery<GetMeetingTypesQuery, TError, TData>(
    ['getMeetingTypes', variables],
    fetcher<GetMeetingTypesQuery, GetMeetingTypesQueryVariables>(
      GetMeetingTypesDocument,
      variables
    ),
    options
  )
export const SetUserAvailabilityDocument = `
    mutation setUserAvailability($input: SetUserAvailabilityInput) {
  setUserAvailability(input: $input) {
    ...setUserAvailabilityFields
  }
}
    ${SetUserAvailabilityFieldsFragmentDoc}`
export const useSetUserAvailabilityMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    SetUserAvailabilityMutation,
    TError,
    SetUserAvailabilityMutationVariables,
    TContext
  >
) =>
  useMutation<SetUserAvailabilityMutation, TError, SetUserAvailabilityMutationVariables, TContext>(
    ['setUserAvailability'],
    (variables?: SetUserAvailabilityMutationVariables) =>
      fetcher<SetUserAvailabilityMutation, SetUserAvailabilityMutationVariables>(
        SetUserAvailabilityDocument,
        variables
      )(),
    options
  )
export const GetUserAvailabilityDocument = `
    query getUserAvailability($input: GetUserAvailabilityInput!) {
  getUserAvailability(input: $input) {
    ...getUserAvailabilityFields
  }
}
    ${GetUserAvailabilityFieldsFragmentDoc}`
export const useGetUserAvailabilityQuery = <TData = GetUserAvailabilityQuery, TError = any>(
  variables: GetUserAvailabilityQueryVariables,
  options?: UseQueryOptions<GetUserAvailabilityQuery, TError, TData>
) =>
  useQuery<GetUserAvailabilityQuery, TError, TData>(
    ['getUserAvailability', variables],
    fetcher<GetUserAvailabilityQuery, GetUserAvailabilityQueryVariables>(
      GetUserAvailabilityDocument,
      variables
    ),
    options
  )
export const SetUserMeetingDocument = `
    mutation setUserMeeting($input: SetUserMeetingInput!) {
  setUserMeeting(input: $input) {
    ...setUserMeetingResponseFields
  }
}
    ${SetUserMeetingResponseFieldsFragmentDoc}`
export const useSetUserMeetingMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    SetUserMeetingMutation,
    TError,
    SetUserMeetingMutationVariables,
    TContext
  >
) =>
  useMutation<SetUserMeetingMutation, TError, SetUserMeetingMutationVariables, TContext>(
    ['setUserMeeting'],
    (variables?: SetUserMeetingMutationVariables) =>
      fetcher<SetUserMeetingMutation, SetUserMeetingMutationVariables>(
        SetUserMeetingDocument,
        variables
      )(),
    options
  )
export const UpdateUserCalendarEventDocument = `
    mutation updateUserCalendarEvent($input: UpdateUserCalendarEventInput!) {
  updateUserCalendarEvent(input: $input) {
    ...UpdateUserCalendarFields
  }
}
    ${UpdateUserCalendarFieldsFragmentDoc}`
export const useUpdateUserCalendarEventMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateUserCalendarEventMutation,
    TError,
    UpdateUserCalendarEventMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateUserCalendarEventMutation,
    TError,
    UpdateUserCalendarEventMutationVariables,
    TContext
  >(
    ['updateUserCalendarEvent'],
    (variables?: UpdateUserCalendarEventMutationVariables) =>
      fetcher<UpdateUserCalendarEventMutation, UpdateUserCalendarEventMutationVariables>(
        UpdateUserCalendarEventDocument,
        variables
      )(),
    options
  )
export const CancelUserCalendarEventDocument = `
    mutation cancelUserCalendarEvent($input: CancelUserCalendarEventInput!) {
  cancelUserCalendarEvent(input: $input) {
    ...CancelUserCalendarFields
  }
}
    ${CancelUserCalendarFieldsFragmentDoc}`
export const useCancelUserCalendarEventMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    CancelUserCalendarEventMutation,
    TError,
    CancelUserCalendarEventMutationVariables,
    TContext
  >
) =>
  useMutation<
    CancelUserCalendarEventMutation,
    TError,
    CancelUserCalendarEventMutationVariables,
    TContext
  >(
    ['cancelUserCalendarEvent'],
    (variables?: CancelUserCalendarEventMutationVariables) =>
      fetcher<CancelUserCalendarEventMutation, CancelUserCalendarEventMutationVariables>(
        CancelUserCalendarEventDocument,
        variables
      )(),
    options
  )
export const GetUserAvailabilityHoursDocument = `
    query getUserAvailabilityHours($userId: String) {
  getUserAvailabilityHours(userId: $userId) {
    ...getUserAvailabilityHoursFields
  }
}
    ${GetUserAvailabilityHoursFieldsFragmentDoc}`
export const useGetUserAvailabilityHoursQuery = <
  TData = GetUserAvailabilityHoursQuery,
  TError = any
>(
  variables?: GetUserAvailabilityHoursQueryVariables,
  options?: UseQueryOptions<GetUserAvailabilityHoursQuery, TError, TData>
) =>
  useQuery<GetUserAvailabilityHoursQuery, TError, TData>(
    variables === undefined
      ? ['getUserAvailabilityHours']
      : ['getUserAvailabilityHours', variables],
    fetcher<GetUserAvailabilityHoursQuery, GetUserAvailabilityHoursQueryVariables>(
      GetUserAvailabilityHoursDocument,
      variables
    ),
    options
  )
export const UpdateUserAvailabilityDocument = `
    mutation updateUserAvailability($input: UpdateUserAvailabilityInput) {
  updateUserAvailability(input: $input) {
    ...updateUserAvailabilityFields
  }
}
    ${UpdateUserAvailabilityFieldsFragmentDoc}`
export const useUpdateUserAvailabilityMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateUserAvailabilityMutation,
    TError,
    UpdateUserAvailabilityMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateUserAvailabilityMutation,
    TError,
    UpdateUserAvailabilityMutationVariables,
    TContext
  >(
    ['updateUserAvailability'],
    (variables?: UpdateUserAvailabilityMutationVariables) =>
      fetcher<UpdateUserAvailabilityMutation, UpdateUserAvailabilityMutationVariables>(
        UpdateUserAvailabilityDocument,
        variables
      )(),
    options
  )
export const GetUserMeetingByIdDocument = `
    query getUserMeetingById($input: GetUserMeetingByIdInput!) {
  getUserMeetingById(input: $input) {
    ...getUserMeetingByIdFields
  }
}
    ${GetUserMeetingByIdFieldsFragmentDoc}`
export const useGetUserMeetingByIdQuery = <TData = GetUserMeetingByIdQuery, TError = any>(
  variables: GetUserMeetingByIdQueryVariables,
  options?: UseQueryOptions<GetUserMeetingByIdQuery, TError, TData>
) =>
  useQuery<GetUserMeetingByIdQuery, TError, TData>(
    ['getUserMeetingById', variables],
    fetcher<GetUserMeetingByIdQuery, GetUserMeetingByIdQueryVariables>(
      GetUserMeetingByIdDocument,
      variables
    ),
    options
  )
export const GetAccountingChartsDocument = `
    query getAccountingCharts($input: QueryAccountingReportInput!) {
  getAccountingCharts(input: $input) {
    ...AccountingChartFragment
  }
}
    ${AccountingChartFragmentFragmentDoc}`
export const useGetAccountingChartsQuery = <TData = GetAccountingChartsQuery, TError = any>(
  variables: GetAccountingChartsQueryVariables,
  options?: UseQueryOptions<GetAccountingChartsQuery, TError, TData>
) =>
  useQuery<GetAccountingChartsQuery, TError, TData>(
    ['getAccountingCharts', variables],
    fetcher<GetAccountingChartsQuery, GetAccountingChartsQueryVariables>(
      GetAccountingChartsDocument,
      variables
    ),
    options
  )
export const CreateChatSessionDocument = `
    mutation createChatSession($input: CreateChatSessionInput!) {
  createChatSession(input: $input) {
    ...createChatSessionFields
  }
}
    ${CreateChatSessionFieldsFragmentDoc}`
export const useCreateChatSessionMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    CreateChatSessionMutation,
    TError,
    CreateChatSessionMutationVariables,
    TContext
  >
) =>
  useMutation<CreateChatSessionMutation, TError, CreateChatSessionMutationVariables, TContext>(
    ['createChatSession'],
    (variables?: CreateChatSessionMutationVariables) =>
      fetcher<CreateChatSessionMutation, CreateChatSessionMutationVariables>(
        CreateChatSessionDocument,
        variables
      )(),
    options
  )
export const SearchHowToDoTaskDocument = `
    mutation searchHowToDoTask($input: SearchHowToDoTaskInput!) {
  searchHowToDoTask(input: $input) {
    ...userTaskHowToResponseFields
  }
}
    ${UserTaskHowToResponseFieldsFragmentDoc}`
export const useSearchHowToDoTaskMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    SearchHowToDoTaskMutation,
    TError,
    SearchHowToDoTaskMutationVariables,
    TContext
  >
) =>
  useMutation<SearchHowToDoTaskMutation, TError, SearchHowToDoTaskMutationVariables, TContext>(
    ['searchHowToDoTask'],
    (variables?: SearchHowToDoTaskMutationVariables) =>
      fetcher<SearchHowToDoTaskMutation, SearchHowToDoTaskMutationVariables>(
        SearchHowToDoTaskDocument,
        variables
      )(),
    options
  )
export const SendMessageToBotDocument = `
    mutation sendMessageToBot($input: SendMessageToBotInput!) {
  sendMessageToBot(input: $input) {
    ...sendMessageToBotFields
  }
}
    ${SendMessageToBotFieldsFragmentDoc}`
export const useSendMessageToBotMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    SendMessageToBotMutation,
    TError,
    SendMessageToBotMutationVariables,
    TContext
  >
) =>
  useMutation<SendMessageToBotMutation, TError, SendMessageToBotMutationVariables, TContext>(
    ['sendMessageToBot'],
    (variables?: SendMessageToBotMutationVariables) =>
      fetcher<SendMessageToBotMutation, SendMessageToBotMutationVariables>(
        SendMessageToBotDocument,
        variables
      )(),
    options
  )
export const GetChatSessionsDocument = `
    query getChatSessions {
  getChatSessions {
    ...getChatSessionsFields
  }
}
    ${GetChatSessionsFieldsFragmentDoc}`
export const useGetChatSessionsQuery = <TData = GetChatSessionsQuery, TError = any>(
  variables?: GetChatSessionsQueryVariables,
  options?: UseQueryOptions<GetChatSessionsQuery, TError, TData>
) =>
  useQuery<GetChatSessionsQuery, TError, TData>(
    variables === undefined ? ['getChatSessions'] : ['getChatSessions', variables],
    fetcher<GetChatSessionsQuery, GetChatSessionsQueryVariables>(
      GetChatSessionsDocument,
      variables
    ),
    options
  )
export const CreateFlowChatSessionDocument = `
    mutation createFlowChatSession($input: CreateFlowChatSessionInput!) {
  createFlowChatSession(input: $input) {
    ...getChatSessionsFields
  }
}
    ${GetChatSessionsFieldsFragmentDoc}`
export const useCreateFlowChatSessionMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    CreateFlowChatSessionMutation,
    TError,
    CreateFlowChatSessionMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateFlowChatSessionMutation,
    TError,
    CreateFlowChatSessionMutationVariables,
    TContext
  >(
    ['createFlowChatSession'],
    (variables?: CreateFlowChatSessionMutationVariables) =>
      fetcher<CreateFlowChatSessionMutation, CreateFlowChatSessionMutationVariables>(
        CreateFlowChatSessionDocument,
        variables
      )(),
    options
  )
export const SummarySessionTitleDocument = `
    mutation summarySessionTitle($id: String!) {
  summarySessionTitle(id: $id) {
    ...getChatSessionsFields
  }
}
    ${GetChatSessionsFieldsFragmentDoc}`
export const useSummarySessionTitleMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    SummarySessionTitleMutation,
    TError,
    SummarySessionTitleMutationVariables,
    TContext
  >
) =>
  useMutation<SummarySessionTitleMutation, TError, SummarySessionTitleMutationVariables, TContext>(
    ['summarySessionTitle'],
    (variables?: SummarySessionTitleMutationVariables) =>
      fetcher<SummarySessionTitleMutation, SummarySessionTitleMutationVariables>(
        SummarySessionTitleDocument,
        variables
      )(),
    options
  )
export const GetAdvisorDashboardDocument = `
    query getAdvisorDashboard($filterType: String, $tenantId: String) {
  getAdvisorDashboard(filterType: $filterType, tenantId: $tenantId) {
    ...AdvisorDashboardFields
  }
}
    ${AdvisorDashboardFieldsFragmentDoc}`
export const useGetAdvisorDashboardQuery = <TData = GetAdvisorDashboardQuery, TError = any>(
  variables?: GetAdvisorDashboardQueryVariables,
  options?: UseQueryOptions<GetAdvisorDashboardQuery, TError, TData>
) =>
  useQuery<GetAdvisorDashboardQuery, TError, TData>(
    variables === undefined ? ['getAdvisorDashboard'] : ['getAdvisorDashboard', variables],
    fetcher<GetAdvisorDashboardQuery, GetAdvisorDashboardQueryVariables>(
      GetAdvisorDashboardDocument,
      variables
    ),
    options
  )
export const GetTopActiveClientsDocument = `
    query getTopActiveClients($tenantId: String) {
  getTopActiveClients(tenantId: $tenantId) {
    ...TopActiveClientsFields
  }
}
    ${TopActiveClientsFieldsFragmentDoc}`
export const useGetTopActiveClientsQuery = <TData = GetTopActiveClientsQuery, TError = any>(
  variables?: GetTopActiveClientsQueryVariables,
  options?: UseQueryOptions<GetTopActiveClientsQuery, TError, TData>
) =>
  useQuery<GetTopActiveClientsQuery, TError, TData>(
    variables === undefined ? ['getTopActiveClients'] : ['getTopActiveClients', variables],
    fetcher<GetTopActiveClientsQuery, GetTopActiveClientsQueryVariables>(
      GetTopActiveClientsDocument,
      variables
    ),
    options
  )
export const GetClientsDocument = `
    query getClients($input: GetClientsInput) {
  getClients(input: $input) {
    ...ClientsFields
  }
}
    ${ClientsFieldsFragmentDoc}`
export const useGetClientsQuery = <TData = GetClientsQuery, TError = any>(
  variables?: GetClientsQueryVariables,
  options?: UseQueryOptions<GetClientsQuery, TError, TData>
) =>
  useQuery<GetClientsQuery, TError, TData>(
    variables === undefined ? ['getClients'] : ['getClients', variables],
    fetcher<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, variables),
    options
  )
export const GetOwnerActivityDetailsDocument = `
    query getOwnerActivityDetails($ownerId: String, $tenantId: String!) {
  getOwnerActivityDetails(ownerId: $ownerId, tenantId: $tenantId) {
    ...ownerActivityDetailsFields
  }
}
    ${OwnerActivityDetailsFieldsFragmentDoc}`
export const useGetOwnerActivityDetailsQuery = <TData = GetOwnerActivityDetailsQuery, TError = any>(
  variables: GetOwnerActivityDetailsQueryVariables,
  options?: UseQueryOptions<GetOwnerActivityDetailsQuery, TError, TData>
) =>
  useQuery<GetOwnerActivityDetailsQuery, TError, TData>(
    ['getOwnerActivityDetails', variables],
    fetcher<GetOwnerActivityDetailsQuery, GetOwnerActivityDetailsQueryVariables>(
      GetOwnerActivityDetailsDocument,
      variables
    ),
    options
  )
export const GetActivityLogsDocument = `
    query getActivityLogs($userId: String, $tenantId: String!) {
  getActivityLogs(userId: $userId, tenantId: $tenantId) {
    ...activityLogFields
  }
}
    ${ActivityLogFieldsFragmentDoc}`
export const useGetActivityLogsQuery = <TData = GetActivityLogsQuery, TError = any>(
  variables: GetActivityLogsQueryVariables,
  options?: UseQueryOptions<GetActivityLogsQuery, TError, TData>
) =>
  useQuery<GetActivityLogsQuery, TError, TData>(
    ['getActivityLogs', variables],
    fetcher<GetActivityLogsQuery, GetActivityLogsQueryVariables>(
      GetActivityLogsDocument,
      variables
    ),
    options
  )
export const GetUserIntegrationsDocument = `
    query getUserIntegrations($userId: String, $tenantId: String!) {
  getUserIntegrations(userId: $userId, tenantId: $tenantId) {
    ...userIntegrationFields
  }
}
    ${UserIntegrationFieldsFragmentDoc}`
export const useGetUserIntegrationsQuery = <TData = GetUserIntegrationsQuery, TError = any>(
  variables: GetUserIntegrationsQueryVariables,
  options?: UseQueryOptions<GetUserIntegrationsQuery, TError, TData>
) =>
  useQuery<GetUserIntegrationsQuery, TError, TData>(
    ['getUserIntegrations', variables],
    fetcher<GetUserIntegrationsQuery, GetUserIntegrationsQueryVariables>(
      GetUserIntegrationsDocument,
      variables
    ),
    options
  )
export const GetTackleMeetingsDocument = `
    query getTackleMeetings($input: GetTackleMeetingsInput) {
  getTackleMeetings(input: $input) {
    ...tackleMeetingsFields
  }
}
    ${TackleMeetingsFieldsFragmentDoc}`
export const useGetTackleMeetingsQuery = <TData = GetTackleMeetingsQuery, TError = any>(
  variables?: GetTackleMeetingsQueryVariables,
  options?: UseQueryOptions<GetTackleMeetingsQuery, TError, TData>
) =>
  useQuery<GetTackleMeetingsQuery, TError, TData>(
    variables === undefined ? ['getTackleMeetings'] : ['getTackleMeetings', variables],
    fetcher<GetTackleMeetingsQuery, GetTackleMeetingsQueryVariables>(
      GetTackleMeetingsDocument,
      variables
    ),
    options
  )
export const DeauthenticateConnectorDocument = `
    mutation deauthenticateConnector($input: DeauthenticateConnectorInput) {
  deauthenticateConnector(input: $input) {
    ...deauthenticateConnectorFields
  }
}
    ${DeauthenticateConnectorFieldsFragmentDoc}`
export const useDeauthenticateConnectorMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    DeauthenticateConnectorMutation,
    TError,
    DeauthenticateConnectorMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeauthenticateConnectorMutation,
    TError,
    DeauthenticateConnectorMutationVariables,
    TContext
  >(
    ['deauthenticateConnector'],
    (variables?: DeauthenticateConnectorMutationVariables) =>
      fetcher<DeauthenticateConnectorMutation, DeauthenticateConnectorMutationVariables>(
        DeauthenticateConnectorDocument,
        variables
      )(),
    options
  )
export const GetAllToolsDocument = `
    query getAllTools($tenantId: String) {
  getAllTools(tenantId: $tenantId) {
    ...getAllToolsFields
  }
}
    ${GetAllToolsFieldsFragmentDoc}`
export const useGetAllToolsQuery = <TData = GetAllToolsQuery, TError = any>(
  variables?: GetAllToolsQueryVariables,
  options?: UseQueryOptions<GetAllToolsQuery, TError, TData>
) =>
  useQuery<GetAllToolsQuery, TError, TData>(
    variables === undefined ? ['getAllTools'] : ['getAllTools', variables],
    fetcher<GetAllToolsQuery, GetAllToolsQueryVariables>(GetAllToolsDocument, variables),
    options
  )
export const UpdateOwnerDocument = `
    mutation updateOwner($input: UpdateOwnerInput) {
  updateOwner(input: $input) {
    ...updateOwnerFields
  }
}
    ${UpdateOwnerFieldsFragmentDoc}`
export const useUpdateOwnerMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<UpdateOwnerMutation, TError, UpdateOwnerMutationVariables, TContext>
) =>
  useMutation<UpdateOwnerMutation, TError, UpdateOwnerMutationVariables, TContext>(
    ['updateOwner'],
    (variables?: UpdateOwnerMutationVariables) =>
      fetcher<UpdateOwnerMutation, UpdateOwnerMutationVariables>(UpdateOwnerDocument, variables)(),
    options
  )
export const GetUserToolsDocument = `
    query getUserTools($userId: String, $tenantId: String!) {
  getUserTools(userId: $userId, tenantId: $tenantId) {
    ...getUserToolsFields
  }
}
    ${GetUserToolsFieldsFragmentDoc}`
export const useGetUserToolsQuery = <TData = GetUserToolsQuery, TError = any>(
  variables: GetUserToolsQueryVariables,
  options?: UseQueryOptions<GetUserToolsQuery, TError, TData>
) =>
  useQuery<GetUserToolsQuery, TError, TData>(
    ['getUserTools', variables],
    fetcher<GetUserToolsQuery, GetUserToolsQueryVariables>(GetUserToolsDocument, variables),
    options
  )
export const GetAttestationReportsDocument = `
    query getAttestationReports($input: GetAttestationReportsInput!) {
  getAttestationReports(input: $input) {
    ...getAttestationReportsFields
  }
}
    ${GetAttestationReportsFieldsFragmentDoc}`
export const useGetAttestationReportsQuery = <TData = GetAttestationReportsQuery, TError = any>(
  variables: GetAttestationReportsQueryVariables,
  options?: UseQueryOptions<GetAttestationReportsQuery, TError, TData>
) =>
  useQuery<GetAttestationReportsQuery, TError, TData>(
    ['getAttestationReports', variables],
    fetcher<GetAttestationReportsQuery, GetAttestationReportsQueryVariables>(
      GetAttestationReportsDocument,
      variables
    ),
    options
  )
export const ToolConnectionSuccessDocument = `
    mutation toolConnectionSuccess($input: ToolConnectionSuccessInput) {
  toolConnectionSuccess(input: $input) {
    ...updateOwnerFields
  }
}
    ${UpdateOwnerFieldsFragmentDoc}`
export const useToolConnectionSuccessMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    ToolConnectionSuccessMutation,
    TError,
    ToolConnectionSuccessMutationVariables,
    TContext
  >
) =>
  useMutation<
    ToolConnectionSuccessMutation,
    TError,
    ToolConnectionSuccessMutationVariables,
    TContext
  >(
    ['toolConnectionSuccess'],
    (variables?: ToolConnectionSuccessMutationVariables) =>
      fetcher<ToolConnectionSuccessMutation, ToolConnectionSuccessMutationVariables>(
        ToolConnectionSuccessDocument,
        variables
      )(),
    options
  )
export const BookmarkToolDocument = `
    mutation bookmarkTool($input: BookmarkToolInput!) {
  bookmarkTool(input: $input) {
    ...bookmarkToolFields
  }
}
    ${BookmarkToolFieldsFragmentDoc}`
export const useBookmarkToolMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    BookmarkToolMutation,
    TError,
    BookmarkToolMutationVariables,
    TContext
  >
) =>
  useMutation<BookmarkToolMutation, TError, BookmarkToolMutationVariables, TContext>(
    ['bookmarkTool'],
    (variables?: BookmarkToolMutationVariables) =>
      fetcher<BookmarkToolMutation, BookmarkToolMutationVariables>(
        BookmarkToolDocument,
        variables
      )(),
    options
  )
export const ProcessToolBusinessDataDocument = `
    mutation processToolBusinessData($input: ProcessToolBusinessDataInput!) {
  processToolBusinessData(input: $input)
}
    `
export const useProcessToolBusinessDataMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    ProcessToolBusinessDataMutation,
    TError,
    ProcessToolBusinessDataMutationVariables,
    TContext
  >
) =>
  useMutation<
    ProcessToolBusinessDataMutation,
    TError,
    ProcessToolBusinessDataMutationVariables,
    TContext
  >(
    ['processToolBusinessData'],
    (variables?: ProcessToolBusinessDataMutationVariables) =>
      fetcher<ProcessToolBusinessDataMutation, ProcessToolBusinessDataMutationVariables>(
        ProcessToolBusinessDataDocument,
        variables
      )(),
    options
  )
export const GetGoalDetailsDocument = `
    query getGoalDetails($id: String) {
  getGoalDetails(id: $id) {
    ...goalDetailsFields
  }
}
    ${GoalDetailsFieldsFragmentDoc}`
export const useGetGoalDetailsQuery = <TData = GetGoalDetailsQuery, TError = any>(
  variables?: GetGoalDetailsQueryVariables,
  options?: UseQueryOptions<GetGoalDetailsQuery, TError, TData>
) =>
  useQuery<GetGoalDetailsQuery, TError, TData>(
    variables === undefined ? ['getGoalDetails'] : ['getGoalDetails', variables],
    fetcher<GetGoalDetailsQuery, GetGoalDetailsQueryVariables>(GetGoalDetailsDocument, variables),
    options
  )
export const GetUserGoalsDocument = `
    query getUserGoals($userId: String, $tenantId: String) {
  getUserGoals(userId: $userId, tenantId: $tenantId) {
    ...goalDetailsFields
  }
}
    ${GoalDetailsFieldsFragmentDoc}`
export const useGetUserGoalsQuery = <TData = GetUserGoalsQuery, TError = any>(
  variables?: GetUserGoalsQueryVariables,
  options?: UseQueryOptions<GetUserGoalsQuery, TError, TData>
) =>
  useQuery<GetUserGoalsQuery, TError, TData>(
    variables === undefined ? ['getUserGoals'] : ['getUserGoals', variables],
    fetcher<GetUserGoalsQuery, GetUserGoalsQueryVariables>(GetUserGoalsDocument, variables),
    options
  )
export const GetPendingJobsDocument = `
    query getPendingJobs {
  getPendingJobs {
    ...getPendingJobsFields
  }
}
    ${GetPendingJobsFieldsFragmentDoc}`
export const useGetPendingJobsQuery = <TData = GetPendingJobsQuery, TError = any>(
  variables?: GetPendingJobsQueryVariables,
  options?: UseQueryOptions<GetPendingJobsQuery, TError, TData>
) =>
  useQuery<GetPendingJobsQuery, TError, TData>(
    variables === undefined ? ['getPendingJobs'] : ['getPendingJobs', variables],
    fetcher<GetPendingJobsQuery, GetPendingJobsQueryVariables>(GetPendingJobsDocument, variables),
    options
  )
export const GetChartDataDocument = `
    query getChartData($input: GetChartDataInput!) {
  getChartData(input: $input) {
    ...chartDataFields
  }
}
    ${ChartDataFieldsFragmentDoc}`
export const useGetChartDataQuery = <TData = GetChartDataQuery, TError = any>(
  variables: GetChartDataQueryVariables,
  options?: UseQueryOptions<GetChartDataQuery, TError, TData>
) =>
  useQuery<GetChartDataQuery, TError, TData>(
    ['getChartData', variables],
    fetcher<GetChartDataQuery, GetChartDataQueryVariables>(GetChartDataDocument, variables),
    options
  )
export const GetOwnerBusinessDataDocument = `
    query getOwnerBusinessData($input: getOwnerBusinessDataInput) {
  getOwnerBusinessData(input: $input) {
    ...ownerBusinessDataFields
  }
}
    ${OwnerBusinessDataFieldsFragmentDoc}`
export const useGetOwnerBusinessDataQuery = <TData = GetOwnerBusinessDataQuery, TError = any>(
  variables?: GetOwnerBusinessDataQueryVariables,
  options?: UseQueryOptions<GetOwnerBusinessDataQuery, TError, TData>
) =>
  useQuery<GetOwnerBusinessDataQuery, TError, TData>(
    variables === undefined ? ['getOwnerBusinessData'] : ['getOwnerBusinessData', variables],
    fetcher<GetOwnerBusinessDataQuery, GetOwnerBusinessDataQueryVariables>(
      GetOwnerBusinessDataDocument,
      variables
    ),
    options
  )
export const GetPlayDocument = `
    query getPlay($id: String) {
  getPlay(id: $id) {
    ...getPlayFields
  }
}
    ${GetPlayFieldsFragmentDoc}`
export const useGetPlayQuery = <TData = GetPlayQuery, TError = any>(
  variables?: GetPlayQueryVariables,
  options?: UseQueryOptions<GetPlayQuery, TError, TData>
) =>
  useQuery<GetPlayQuery, TError, TData>(
    variables === undefined ? ['getPlay'] : ['getPlay', variables],
    fetcher<GetPlayQuery, GetPlayQueryVariables>(GetPlayDocument, variables),
    options
  )
export const GetPlaysDocument = `
    query getPlays($input: GetPlaysInput) {
  getPlays(input: $input) {
    ...getPlaysFields
  }
}
    ${GetPlaysFieldsFragmentDoc}`
export const useGetPlaysQuery = <TData = GetPlaysQuery, TError = any>(
  variables?: GetPlaysQueryVariables,
  options?: UseQueryOptions<GetPlaysQuery, TError, TData>
) =>
  useQuery<GetPlaysQuery, TError, TData>(
    variables === undefined ? ['getPlays'] : ['getPlays', variables],
    fetcher<GetPlaysQuery, GetPlaysQueryVariables>(GetPlaysDocument, variables),
    options
  )
export const GetPlayResponseDocument = `
    query getPlayResponse($input: GetPlayResponseInput!) {
  getPlayResponse(input: $input) {
    ...playResponseFields
  }
}
    ${PlayResponseFieldsFragmentDoc}`
export const useGetPlayResponseQuery = <TData = GetPlayResponseQuery, TError = any>(
  variables: GetPlayResponseQueryVariables,
  options?: UseQueryOptions<GetPlayResponseQuery, TError, TData>
) =>
  useQuery<GetPlayResponseQuery, TError, TData>(
    ['getPlayResponse', variables],
    fetcher<GetPlayResponseQuery, GetPlayResponseQueryVariables>(
      GetPlayResponseDocument,
      variables
    ),
    options
  )
export const SavePlayResponseDocument = `
    mutation savePlayResponse($input: SavePlayResponseInput!) {
  savePlayResponse(input: $input) {
    ...playResponseFields
  }
}
    ${PlayResponseFieldsFragmentDoc}`
export const useSavePlayResponseMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    SavePlayResponseMutation,
    TError,
    SavePlayResponseMutationVariables,
    TContext
  >
) =>
  useMutation<SavePlayResponseMutation, TError, SavePlayResponseMutationVariables, TContext>(
    ['savePlayResponse'],
    (variables?: SavePlayResponseMutationVariables) =>
      fetcher<SavePlayResponseMutation, SavePlayResponseMutationVariables>(
        SavePlayResponseDocument,
        variables
      )(),
    options
  )
export const AuthorizeConnectorDocument = `
    mutation authorizeConnector($authValue: String!) {
  authorizeConnector(authValue: $authValue) {
    ...authorizeConnectorFields
  }
}
    ${AuthorizeConnectorFieldsFragmentDoc}`
export const useAuthorizeConnectorMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    AuthorizeConnectorMutation,
    TError,
    AuthorizeConnectorMutationVariables,
    TContext
  >
) =>
  useMutation<AuthorizeConnectorMutation, TError, AuthorizeConnectorMutationVariables, TContext>(
    ['authorizeConnector'],
    (variables?: AuthorizeConnectorMutationVariables) =>
      fetcher<AuthorizeConnectorMutation, AuthorizeConnectorMutationVariables>(
        AuthorizeConnectorDocument,
        variables
      )(),
    options
  )
export const GetDashboardListingDocument = `
    query getDashboardListing($tenantId: String!, $category: String) {
  getDashboardListing(tenantId: $tenantId, category: $category) {
    ...dashboardListingFields
  }
}
    ${DashboardListingFieldsFragmentDoc}`
export const useGetDashboardListingQuery = <TData = GetDashboardListingQuery, TError = any>(
  variables: GetDashboardListingQueryVariables,
  options?: UseQueryOptions<GetDashboardListingQuery, TError, TData>
) =>
  useQuery<GetDashboardListingQuery, TError, TData>(
    ['getDashboardListing', variables],
    fetcher<GetDashboardListingQuery, GetDashboardListingQueryVariables>(
      GetDashboardListingDocument,
      variables
    ),
    options
  )
export const GetAssessmentPlayListingDocument = `
    query getAssessmentPlayListing($tenantId: String!) {
  getAssessmentPlayListing(tenantId: $tenantId) {
    ...assessmentPlayListingFields
  }
}
    ${AssessmentPlayListingFieldsFragmentDoc}`
export const useGetAssessmentPlayListingQuery = <
  TData = GetAssessmentPlayListingQuery,
  TError = any
>(
  variables: GetAssessmentPlayListingQueryVariables,
  options?: UseQueryOptions<GetAssessmentPlayListingQuery, TError, TData>
) =>
  useQuery<GetAssessmentPlayListingQuery, TError, TData>(
    ['getAssessmentPlayListing', variables],
    fetcher<GetAssessmentPlayListingQuery, GetAssessmentPlayListingQueryVariables>(
      GetAssessmentPlayListingDocument,
      variables
    ),
    options
  )
export const OnTaskProgressUpdatedDocument = `
    subscription OnTaskProgressUpdated($id: ID!) {
  onTaskProgressUpdated(id: $id) {
    id
    status
    payload
  }
}
    `
export const GetToolsDocument = `
    query getTools {
  getTools {
    ...getToolsFields
  }
}
    ${GetToolsFieldsFragmentDoc}`
export const useGetToolsQuery = <TData = GetToolsQuery, TError = any>(
  variables?: GetToolsQueryVariables,
  options?: UseQueryOptions<GetToolsQuery, TError, TData>
) =>
  useQuery<GetToolsQuery, TError, TData>(
    variables === undefined ? ['getTools'] : ['getTools', variables],
    fetcher<GetToolsQuery, GetToolsQueryVariables>(GetToolsDocument, variables),
    options
  )
export const GetToolsByTypeDocument = `
    query getToolsByType($input: GetToolsByTypeInput!) {
  getToolsByType(input: $input) {
    ...toolFields
  }
}
    ${ToolFieldsFragmentDoc}`
export const useGetToolsByTypeQuery = <TData = GetToolsByTypeQuery, TError = any>(
  variables: GetToolsByTypeQueryVariables,
  options?: UseQueryOptions<GetToolsByTypeQuery, TError, TData>
) =>
  useQuery<GetToolsByTypeQuery, TError, TData>(
    ['getToolsByType', variables],
    fetcher<GetToolsByTypeQuery, GetToolsByTypeQueryVariables>(GetToolsByTypeDocument, variables),
    options
  )
export const GetAllToolCategoriesDocument = `
    query getAllToolCategories {
  getAllToolCategories {
    ...toolCategoriesFields
  }
}
    ${ToolCategoriesFieldsFragmentDoc}`
export const useGetAllToolCategoriesQuery = <TData = GetAllToolCategoriesQuery, TError = any>(
  variables?: GetAllToolCategoriesQueryVariables,
  options?: UseQueryOptions<GetAllToolCategoriesQuery, TError, TData>
) =>
  useQuery<GetAllToolCategoriesQuery, TError, TData>(
    variables === undefined ? ['getAllToolCategories'] : ['getAllToolCategories', variables],
    fetcher<GetAllToolCategoriesQuery, GetAllToolCategoriesQueryVariables>(
      GetAllToolCategoriesDocument,
      variables
    ),
    options
  )
export const SearchToolsDocument = `
    query searchTools($input: SearchToolsInput!) {
  searchTools(input: $input) {
    ...searchToolsFields
  }
}
    ${SearchToolsFieldsFragmentDoc}`
export const useSearchToolsQuery = <TData = SearchToolsQuery, TError = any>(
  variables: SearchToolsQueryVariables,
  options?: UseQueryOptions<SearchToolsQuery, TError, TData>
) =>
  useQuery<SearchToolsQuery, TError, TData>(
    ['searchTools', variables],
    fetcher<SearchToolsQuery, SearchToolsQueryVariables>(SearchToolsDocument, variables),
    options
  )
export const GetSignedUrlDocument = `
    query getSignedUrl($contentType: String, $fileName: String) {
  getSignedUrl(contentType: $contentType, fileName: $fileName) {
    ...goalSignedUrlFields
  }
}
    ${GoalSignedUrlFieldsFragmentDoc}`
export const useGetSignedUrlQuery = <TData = GetSignedUrlQuery, TError = any>(
  variables?: GetSignedUrlQueryVariables,
  options?: UseQueryOptions<GetSignedUrlQuery, TError, TData>
) =>
  useQuery<GetSignedUrlQuery, TError, TData>(
    variables === undefined ? ['getSignedUrl'] : ['getSignedUrl', variables],
    fetcher<GetSignedUrlQuery, GetSignedUrlQueryVariables>(GetSignedUrlDocument, variables),
    options
  )
export const GetUserFilesDocument = `
    query getUserFiles($userId: String, $tenantId: String!) {
  getUserFiles(userId: $userId, tenantId: $tenantId) {
    ...getUserFilesFileds
  }
}
    ${GetUserFilesFiledsFragmentDoc}`
export const useGetUserFilesQuery = <TData = GetUserFilesQuery, TError = any>(
  variables: GetUserFilesQueryVariables,
  options?: UseQueryOptions<GetUserFilesQuery, TError, TData>
) =>
  useQuery<GetUserFilesQuery, TError, TData>(
    ['getUserFiles', variables],
    fetcher<GetUserFilesQuery, GetUserFilesQueryVariables>(GetUserFilesDocument, variables),
    options
  )
export const GetFileDocument = `
    query getFile($id: String) {
  getFile(id: $id) {
    ...getUserFilesFileds
  }
}
    ${GetUserFilesFiledsFragmentDoc}`
export const useGetFileQuery = <TData = GetFileQuery, TError = any>(
  variables?: GetFileQueryVariables,
  options?: UseQueryOptions<GetFileQuery, TError, TData>
) =>
  useQuery<GetFileQuery, TError, TData>(
    variables === undefined ? ['getFile'] : ['getFile', variables],
    fetcher<GetFileQuery, GetFileQueryVariables>(GetFileDocument, variables),
    options
  )
export const SaveUserFileDocument = `
    mutation saveUserFile($input: SaveUserFileInput!) {
  saveUserFile(input: $input) {
    ...saveUserFileFields
  }
}
    ${SaveUserFileFieldsFragmentDoc}`
export const useSaveUserFileMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    SaveUserFileMutation,
    TError,
    SaveUserFileMutationVariables,
    TContext
  >
) =>
  useMutation<SaveUserFileMutation, TError, SaveUserFileMutationVariables, TContext>(
    ['saveUserFile'],
    (variables?: SaveUserFileMutationVariables) =>
      fetcher<SaveUserFileMutation, SaveUserFileMutationVariables>(
        SaveUserFileDocument,
        variables
      )(),
    options
  )
export const GetBusinessesDocument = `
    query getBusinesses($getBusinessesInput: GetBusinessesInput!) {
  getBusinesses(getBusinessesInput: $getBusinessesInput) {
    ...BusinessesFields
  }
}
    ${BusinessesFieldsFragmentDoc}`
export const useGetBusinessesQuery = <TData = GetBusinessesQuery, TError = any>(
  variables: GetBusinessesQueryVariables,
  options?: UseQueryOptions<GetBusinessesQuery, TError, TData>
) =>
  useQuery<GetBusinessesQuery, TError, TData>(
    ['getBusinesses', variables],
    fetcher<GetBusinessesQuery, GetBusinessesQueryVariables>(GetBusinessesDocument, variables),
    options
  )
export const ListCognitoUsersDocument = `
    mutation listCognitoUsers($input: ListCognitoUsersInput!) {
  listCognitoUsers(input: $input) {
    ...cognitoUserFields
  }
}
    ${CognitoUserFieldsFragmentDoc}`
export const useListCognitoUsersMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    ListCognitoUsersMutation,
    TError,
    ListCognitoUsersMutationVariables,
    TContext
  >
) =>
  useMutation<ListCognitoUsersMutation, TError, ListCognitoUsersMutationVariables, TContext>(
    ['listCognitoUsers'],
    (variables?: ListCognitoUsersMutationVariables) =>
      fetcher<ListCognitoUsersMutation, ListCognitoUsersMutationVariables>(
        ListCognitoUsersDocument,
        variables
      )(),
    options
  )
export const AddUserToGroupDocument = `
    mutation addUserToGroup($input: AddUserToGroupInput!) {
  addUserToGroup(input: $input)
}
    `
export const useAddUserToGroupMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    AddUserToGroupMutation,
    TError,
    AddUserToGroupMutationVariables,
    TContext
  >
) =>
  useMutation<AddUserToGroupMutation, TError, AddUserToGroupMutationVariables, TContext>(
    ['addUserToGroup'],
    (variables?: AddUserToGroupMutationVariables) =>
      fetcher<AddUserToGroupMutation, AddUserToGroupMutationVariables>(
        AddUserToGroupDocument,
        variables
      )(),
    options
  )
export const UpdateUserPasswordDocument = `
    mutation updateUserPassword($input: UpdateUserPasswordInput!) {
  updateUserPassword(input: $input)
}
    `
export const useUpdateUserPasswordMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateUserPasswordMutation,
    TError,
    UpdateUserPasswordMutationVariables,
    TContext
  >
) =>
  useMutation<UpdateUserPasswordMutation, TError, UpdateUserPasswordMutationVariables, TContext>(
    ['updateUserPassword'],
    (variables?: UpdateUserPasswordMutationVariables) =>
      fetcher<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(
        UpdateUserPasswordDocument,
        variables
      )(),
    options
  )
export const GetAdvisorsDocument = `
    query getAdvisors($getAdvisorsInput: GetAdvisorsInput) {
  getAdvisors(getAdvisorsInput: $getAdvisorsInput) {
    ...AdvisorsFields
  }
}
    ${AdvisorsFieldsFragmentDoc}`
export const useGetAdvisorsQuery = <TData = GetAdvisorsQuery, TError = any>(
  variables?: GetAdvisorsQueryVariables,
  options?: UseQueryOptions<GetAdvisorsQuery, TError, TData>
) =>
  useQuery<GetAdvisorsQuery, TError, TData>(
    variables === undefined ? ['getAdvisors'] : ['getAdvisors', variables],
    fetcher<GetAdvisorsQuery, GetAdvisorsQueryVariables>(GetAdvisorsDocument, variables),
    options
  )
export const LogActivityDocument = `
    mutation logActivity($activityLogInput: ActivityLogInput!) {
  logActivity(activityLogInput: $activityLogInput) {
    ...logActivityFields
  }
}
    ${LogActivityFieldsFragmentDoc}`
export const useLogActivityMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<LogActivityMutation, TError, LogActivityMutationVariables, TContext>
) =>
  useMutation<LogActivityMutation, TError, LogActivityMutationVariables, TContext>(
    ['logActivity'],
    (variables?: LogActivityMutationVariables) =>
      fetcher<LogActivityMutation, LogActivityMutationVariables>(LogActivityDocument, variables)(),
    options
  )
export const GetUserNotificationsDocument = `
    query getUserNotifications($input: GetUserNotificationsInput) {
  getUserNotifications(input: $input) {
    ...UserNotificationsFields
  }
}
    ${UserNotificationsFieldsFragmentDoc}`
export const useGetUserNotificationsQuery = <TData = GetUserNotificationsQuery, TError = any>(
  variables?: GetUserNotificationsQueryVariables,
  options?: UseQueryOptions<GetUserNotificationsQuery, TError, TData>
) =>
  useQuery<GetUserNotificationsQuery, TError, TData>(
    variables === undefined ? ['getUserNotifications'] : ['getUserNotifications', variables],
    fetcher<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>(
      GetUserNotificationsDocument,
      variables
    ),
    options
  )
export const GetClientsSummaryDocument = `
    query getClientsSummary($tenantId: String) {
  getClientsSummary(tenantId: $tenantId) {
    ...GetClientsSummaryFields
  }
}
    ${GetClientsSummaryFieldsFragmentDoc}`
export const useGetClientsSummaryQuery = <TData = GetClientsSummaryQuery, TError = any>(
  variables?: GetClientsSummaryQueryVariables,
  options?: UseQueryOptions<GetClientsSummaryQuery, TError, TData>
) =>
  useQuery<GetClientsSummaryQuery, TError, TData>(
    variables === undefined ? ['getClientsSummary'] : ['getClientsSummary', variables],
    fetcher<GetClientsSummaryQuery, GetClientsSummaryQueryVariables>(
      GetClientsSummaryDocument,
      variables
    ),
    options
  )
export const UpdateUserNotificationsDocument = `
    mutation updateUserNotifications($input: UpdateUserNotificationsInput) {
  updateUserNotifications(input: $input) {
    ...UserNotificationMutateFields
  }
}
    ${UserNotificationMutateFieldsFragmentDoc}`
export const useUpdateUserNotificationsMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateUserNotificationsMutation,
    TError,
    UpdateUserNotificationsMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateUserNotificationsMutation,
    TError,
    UpdateUserNotificationsMutationVariables,
    TContext
  >(
    ['updateUserNotifications'],
    (variables?: UpdateUserNotificationsMutationVariables) =>
      fetcher<UpdateUserNotificationsMutation, UpdateUserNotificationsMutationVariables>(
        UpdateUserNotificationsDocument,
        variables
      )(),
    options
  )
export const DeleteUserNotificationsDocument = `
    mutation deleteUserNotifications($input: DeleteUserNotificationsInput) {
  deleteUserNotifications(input: $input) {
    ...UserNotificationMutateFields
  }
}
    ${UserNotificationMutateFieldsFragmentDoc}`
export const useDeleteUserNotificationsMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteUserNotificationsMutation,
    TError,
    DeleteUserNotificationsMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteUserNotificationsMutation,
    TError,
    DeleteUserNotificationsMutationVariables,
    TContext
  >(
    ['deleteUserNotifications'],
    (variables?: DeleteUserNotificationsMutationVariables) =>
      fetcher<DeleteUserNotificationsMutation, DeleteUserNotificationsMutationVariables>(
        DeleteUserNotificationsDocument,
        variables
      )(),
    options
  )
export const ResendUserInviteDocument = `
    mutation resendUserInvite($input: ResendUserInviteInput!) {
  resendUserInvite(input: $input)
}
    `
export const useResendUserInviteMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    ResendUserInviteMutation,
    TError,
    ResendUserInviteMutationVariables,
    TContext
  >
) =>
  useMutation<ResendUserInviteMutation, TError, ResendUserInviteMutationVariables, TContext>(
    ['resendUserInvite'],
    (variables?: ResendUserInviteMutationVariables) =>
      fetcher<ResendUserInviteMutation, ResendUserInviteMutationVariables>(
        ResendUserInviteDocument,
        variables
      )(),
    options
  )
export const SaveAttestationDocument = `
    mutation saveAttestation($input: AttestationInput!) {
  saveAttestation(input: $input) {
    ...saveAttestationFields
  }
}
    ${SaveAttestationFieldsFragmentDoc}`
export const useSaveAttestationMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    SaveAttestationMutation,
    TError,
    SaveAttestationMutationVariables,
    TContext
  >
) =>
  useMutation<SaveAttestationMutation, TError, SaveAttestationMutationVariables, TContext>(
    ['saveAttestation'],
    (variables?: SaveAttestationMutationVariables) =>
      fetcher<SaveAttestationMutation, SaveAttestationMutationVariables>(
        SaveAttestationDocument,
        variables
      )(),
    options
  )
export const MakeAnnouncementDocument = `
    mutation makeAnnouncement($input: MakeAnnouncementInput!) {
  makeAnnouncement(input: $input)
}
    `
export const useMakeAnnouncementMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    MakeAnnouncementMutation,
    TError,
    MakeAnnouncementMutationVariables,
    TContext
  >
) =>
  useMutation<MakeAnnouncementMutation, TError, MakeAnnouncementMutationVariables, TContext>(
    ['makeAnnouncement'],
    (variables?: MakeAnnouncementMutationVariables) =>
      fetcher<MakeAnnouncementMutation, MakeAnnouncementMutationVariables>(
        MakeAnnouncementDocument,
        variables
      )(),
    options
  )
export const GetBusinessIndustryDetailsDocument = `
    query getBusinessIndustryDetails($input: GetBusinessIndustryDetailsInput!) {
  getBusinessIndustryDetails(input: $input) {
    ...businessIndustryChangedFields
  }
}
    ${BusinessIndustryChangedFieldsFragmentDoc}`
export const useGetBusinessIndustryDetailsQuery = <
  TData = GetBusinessIndustryDetailsQuery,
  TError = any
>(
  variables: GetBusinessIndustryDetailsQueryVariables,
  options?: UseQueryOptions<GetBusinessIndustryDetailsQuery, TError, TData>
) =>
  useQuery<GetBusinessIndustryDetailsQuery, TError, TData>(
    ['getBusinessIndustryDetails', variables],
    fetcher<GetBusinessIndustryDetailsQuery, GetBusinessIndustryDetailsQueryVariables>(
      GetBusinessIndustryDetailsDocument,
      variables
    ),
    options
  )
export const GetMyProfileDocument = `
    query getMyProfile($userId: String, $tenantId: String) {
  getMyProfile(userId: $userId, tenantId: $tenantId) {
    ...userProfileFields
  }
}
    ${UserProfileFieldsFragmentDoc}`
export const useGetMyProfileQuery = <TData = GetMyProfileQuery, TError = any>(
  variables?: GetMyProfileQueryVariables,
  options?: UseQueryOptions<GetMyProfileQuery, TError, TData>
) =>
  useQuery<GetMyProfileQuery, TError, TData>(
    variables === undefined ? ['getMyProfile'] : ['getMyProfile', variables],
    fetcher<GetMyProfileQuery, GetMyProfileQueryVariables>(GetMyProfileDocument, variables),
    options
  )
export const GetBoAdvisorsDocument = `
    query getBoAdvisors($userId: String, $tenantId: String) {
  getBoAdvisors(tenantId: $tenantId, userId: $userId) {
    ...BoAdvisorsFields
  }
}
    ${BoAdvisorsFieldsFragmentDoc}`
export const useGetBoAdvisorsQuery = <TData = GetBoAdvisorsQuery, TError = any>(
  variables?: GetBoAdvisorsQueryVariables,
  options?: UseQueryOptions<GetBoAdvisorsQuery, TError, TData>
) =>
  useQuery<GetBoAdvisorsQuery, TError, TData>(
    variables === undefined ? ['getBoAdvisors'] : ['getBoAdvisors', variables],
    fetcher<GetBoAdvisorsQuery, GetBoAdvisorsQueryVariables>(GetBoAdvisorsDocument, variables),
    options
  )
export const UpdateMyProfileDocument = `
    mutation updateMyProfile($input: UpdateMyProfileInput!) {
  updateMyProfile(input: $input) {
    ...userProfileFields
  }
}
    ${UserProfileFieldsFragmentDoc}`
export const useUpdateMyProfileMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateMyProfileMutation,
    TError,
    UpdateMyProfileMutationVariables,
    TContext
  >
) =>
  useMutation<UpdateMyProfileMutation, TError, UpdateMyProfileMutationVariables, TContext>(
    ['updateMyProfile'],
    (variables?: UpdateMyProfileMutationVariables) =>
      fetcher<UpdateMyProfileMutation, UpdateMyProfileMutationVariables>(
        UpdateMyProfileDocument,
        variables
      )(),
    options
  )
export const CreateUserDocument = `
    mutation createUser($input: CreateUserInput!) {
  createUser(input: $input) {
    ...userProfileFields
  }
}
    ${UserProfileFieldsFragmentDoc}`
export const useCreateUserMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<CreateUserMutation, TError, CreateUserMutationVariables, TContext>
) =>
  useMutation<CreateUserMutation, TError, CreateUserMutationVariables, TContext>(
    ['createUser'],
    (variables?: CreateUserMutationVariables) =>
      fetcher<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, variables)(),
    options
  )
export const GetAdvisorProfileDocument = `
    query getAdvisorProfile($input: GetAdvisorProfileInput!) {
  getAdvisorProfile(input: $input) {
    ...advisorProfileFields
  }
}
    ${AdvisorProfileFieldsFragmentDoc}`
export const useGetAdvisorProfileQuery = <TData = GetAdvisorProfileQuery, TError = any>(
  variables: GetAdvisorProfileQueryVariables,
  options?: UseQueryOptions<GetAdvisorProfileQuery, TError, TData>
) =>
  useQuery<GetAdvisorProfileQuery, TError, TData>(
    ['getAdvisorProfile', variables],
    fetcher<GetAdvisorProfileQuery, GetAdvisorProfileQueryVariables>(
      GetAdvisorProfileDocument,
      variables
    ),
    options
  )
export const GetBusinessGoalsDocument = `
    query getBusinessGoals($input: GetBusinessGoalsInput!) {
  getBusinessGoals(input: $input) {
    ...goalsFields
  }
}
    ${GoalsFieldsFragmentDoc}`
export const useGetBusinessGoalsQuery = <TData = GetBusinessGoalsQuery, TError = any>(
  variables: GetBusinessGoalsQueryVariables,
  options?: UseQueryOptions<GetBusinessGoalsQuery, TError, TData>
) =>
  useQuery<GetBusinessGoalsQuery, TError, TData>(
    ['getBusinessGoals', variables],
    fetcher<GetBusinessGoalsQuery, GetBusinessGoalsQueryVariables>(
      GetBusinessGoalsDocument,
      variables
    ),
    options
  )
export const GetBusinessGoalDocument = `
    query getBusinessGoal($input: GetBusinessGoalInput!) {
  getBusinessGoal(input: $input) {
    ...goalFields
  }
}
    ${GoalFieldsFragmentDoc}`
export const useGetBusinessGoalQuery = <TData = GetBusinessGoalQuery, TError = any>(
  variables: GetBusinessGoalQueryVariables,
  options?: UseQueryOptions<GetBusinessGoalQuery, TError, TData>
) =>
  useQuery<GetBusinessGoalQuery, TError, TData>(
    ['getBusinessGoal', variables],
    fetcher<GetBusinessGoalQuery, GetBusinessGoalQueryVariables>(
      GetBusinessGoalDocument,
      variables
    ),
    options
  )
export const GetUserTaskDocument = `
    query getUserTask($input: GetUserTaskInput!) {
  getUserTask(input: $input) {
    ...userTaskFields
  }
}
    ${UserTaskFieldsFragmentDoc}`
export const useGetUserTaskQuery = <TData = GetUserTaskQuery, TError = any>(
  variables: GetUserTaskQueryVariables,
  options?: UseQueryOptions<GetUserTaskQuery, TError, TData>
) =>
  useQuery<GetUserTaskQuery, TError, TData>(
    ['getUserTask', variables],
    fetcher<GetUserTaskQuery, GetUserTaskQueryVariables>(GetUserTaskDocument, variables),
    options
  )
export const GetUserTasksDocument = `
    query getUserTasks($input: GetUserTasksInput!) {
  getUserTasks(input: $input) {
    ...userTaskFields
  }
}
    ${UserTaskFieldsFragmentDoc}`
export const useGetUserTasksQuery = <TData = GetUserTasksQuery, TError = any>(
  variables: GetUserTasksQueryVariables,
  options?: UseQueryOptions<GetUserTasksQuery, TError, TData>
) =>
  useQuery<GetUserTasksQuery, TError, TData>(
    ['getUserTasks', variables],
    fetcher<GetUserTasksQuery, GetUserTasksQueryVariables>(GetUserTasksDocument, variables),
    options
  )
export const UpdateBusinessGoalDocument = `
    mutation updateBusinessGoal($input: UpdateBusinessGoalInput!) {
  updateBusinessGoal(input: $input)
}
    `
export const useUpdateBusinessGoalMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateBusinessGoalMutation,
    TError,
    UpdateBusinessGoalMutationVariables,
    TContext
  >
) =>
  useMutation<UpdateBusinessGoalMutation, TError, UpdateBusinessGoalMutationVariables, TContext>(
    ['updateBusinessGoal'],
    (variables?: UpdateBusinessGoalMutationVariables) =>
      fetcher<UpdateBusinessGoalMutation, UpdateBusinessGoalMutationVariables>(
        UpdateBusinessGoalDocument,
        variables
      )(),
    options
  )
export const CreateUserTaskDocument = `
    mutation createUserTask($input: CreateUserTaskInput!) {
  createUserTask(input: $input) {
    ...userTaskFields
  }
}
    ${UserTaskFieldsFragmentDoc}`
export const useCreateUserTaskMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    CreateUserTaskMutation,
    TError,
    CreateUserTaskMutationVariables,
    TContext
  >
) =>
  useMutation<CreateUserTaskMutation, TError, CreateUserTaskMutationVariables, TContext>(
    ['createUserTask'],
    (variables?: CreateUserTaskMutationVariables) =>
      fetcher<CreateUserTaskMutation, CreateUserTaskMutationVariables>(
        CreateUserTaskDocument,
        variables
      )(),
    options
  )
export const DeleteUserTaskDocument = `
    mutation deleteUserTask($input: DeleteUserTaskInput!) {
  deleteUserTask(input: $input)
}
    `
export const useDeleteUserTaskMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteUserTaskMutation,
    TError,
    DeleteUserTaskMutationVariables,
    TContext
  >
) =>
  useMutation<DeleteUserTaskMutation, TError, DeleteUserTaskMutationVariables, TContext>(
    ['deleteUserTask'],
    (variables?: DeleteUserTaskMutationVariables) =>
      fetcher<DeleteUserTaskMutation, DeleteUserTaskMutationVariables>(
        DeleteUserTaskDocument,
        variables
      )(),
    options
  )
export const UpdateUserTaskDocument = `
    mutation updateUserTask($input: UpdateUserTaskInput!) {
  updateUserTask(input: $input) {
    ...userTaskFields
  }
}
    ${UserTaskFieldsFragmentDoc}`
export const useUpdateUserTaskMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateUserTaskMutation,
    TError,
    UpdateUserTaskMutationVariables,
    TContext
  >
) =>
  useMutation<UpdateUserTaskMutation, TError, UpdateUserTaskMutationVariables, TContext>(
    ['updateUserTask'],
    (variables?: UpdateUserTaskMutationVariables) =>
      fetcher<UpdateUserTaskMutation, UpdateUserTaskMutationVariables>(
        UpdateUserTaskDocument,
        variables
      )(),
    options
  )
export const GetOnboardingFlowStateDocument = `
    query getOnboardingFlowState($input: GetOnboardingFlowStateInput!) {
  getOnboardingFlowState(input: $input) {
    ...getOnboardingFlowStateFields
  }
}
    ${GetOnboardingFlowStateFieldsFragmentDoc}`
export const useGetOnboardingFlowStateQuery = <TData = GetOnboardingFlowStateQuery, TError = any>(
  variables: GetOnboardingFlowStateQueryVariables,
  options?: UseQueryOptions<GetOnboardingFlowStateQuery, TError, TData>
) =>
  useQuery<GetOnboardingFlowStateQuery, TError, TData>(
    ['getOnboardingFlowState', variables],
    fetcher<GetOnboardingFlowStateQuery, GetOnboardingFlowStateQueryVariables>(
      GetOnboardingFlowStateDocument,
      variables
    ),
    options
  )
